import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import ChatBot from '../../../assets/images/icons/chat-bot-avatar.svg';
import WidgetHelper from "./Helper";
import { FunctionComponent } from "react";
import { ChatInterfaceType } from "Redux/Reducers/aiChatbot.reducer";
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
interface Props {
    chatInterface?: ChatInterfaceType;
}
const Header: FunctionComponent<Props> = (props) => {
    return (
        <Box
            pt={(props.chatInterface?.chatWindow.padding?.top) +
                'px'}
            bgcolor={WidgetHelper.rgbToHex(props.chatInterface?.bubbleButtonColor)}
            pl={(props.chatInterface?.chatWindow.padding?.left) +
                'px'}
            pr={(props.chatInterface?.chatWindow.padding?.right) +
                'px'}
            pb={(props.chatInterface?.chatWindow.padding?.bottom) +
                'px'}
        >
            <Stack
                className="widget-title"
                alignItems={'center'}
                direction={'row'}
                // justifyContent={'space-between'}
                style={WidgetHelper.getCustomStyle('headerFontStyle', props.chatInterface)}
            >
                {props.chatInterface?.showProfilePic &&
                    (props.chatInterface?.profilePic?.keyInS3 ? (
                        <Box className="chatbot-avatar" mr={1}>
                            <img
                                alt=""
                                src={
                                    IMAGE_URL + '/' + props.chatInterface?.profilePic?.keyInS3
                                }
                            />
                        </Box>
                    ) : (
                        <Box className="chatbot-avatar" mr={1}>
                            <img alt="" src={ChatBot} />
                        </Box>
                    ))}

                {/* props.chatInterface?.profilePic?.keyInS3 ? (
              
            ) : (
              
            )} */}
                {/* </Box> */}
                <Box
                    sx={{
                        color: WidgetHelper.rgbToHex(props.chatInterface?.displayNameColor),
                    }}
                >
                    {props.chatInterface?.displayName}
                </Box>
            </Stack>
        </Box>
    );
};

export default Header;