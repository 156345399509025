import '../ChatWidget/style.css';

import {
  Avatar,
  ClickAwayListener,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  Popper,
} from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SendIcon from '@material-ui/icons/Send';
import { Box, Stack } from '@mui/material';
import { Modes } from 'Pages/AIChatbot/EmbedCodePopUp';
import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ChatInterfaceType, Color } from 'Redux/Reducers/aiChatbot.reducer';
import { theme } from 'theme/theme';
import moment from 'moment';
import ChatBot from '../../assets/images/icons/chat-bot-avatar.svg';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
interface Props {
  messages?: Message[];
  headerComponent?: ReactNode;
  ref?: Ref<any>;
  widgetStyle: string;
  disableSendButton: boolean;
  loading: boolean;
  error: string;
  onUssrMessage?: (
    text: string,
    sender: 'user' | 'assistant',
    id: string,
  ) => void;
  onToggle?: (isOpen: boolean) => void;
  onRefresh?: () => void;
  chatInterface?: ChatInterfaceType;
  for?: string;
}
interface FeedBack {
  _id?: string;
  type: string;
  from?: string;
  response?: any;
  comment?: string;
}
export interface Message {
  timetamp: string;
  _id?: string;
  id: string;
  text: string;
  prompt?: string;
  sender: 'user' | 'assistant';
  feedback?: FeedBack;
}
export interface WidgetActions {
  addMessage(text: string, sender: string, id: string): void;
  updateMessageById(id: string, newText: string): void;
}

const rgbToHex = (color?: Color) => {
  if (color?.r && color?.g && color?.b) {
    let { r, g, b } = color;
    // Convert each RGB component to hexadecimal
    var redHex = r.toString(16).padStart(2, '0');
    var greenHex = g.toString(16).padStart(2, '0');
    var blueHex = b.toString(16).padStart(2, '0');

    // Concatenate the hex values
    var hexColor = '#' + redHex + greenHex + blueHex;

    return hexColor.toUpperCase(); // Convert to uppercase for consistency
  }
};

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Widget: ForwardRefRenderFunction<WidgetActions, Props> = (props, ref) => {
  const styles = {
    chatAvatar: {
      // backgroundColor: '#5141e7',
      backgroundColor: rgbToHex(props.chatInterface?.bubbleButtonColor),
      color: '#ffffff',
      width: theme.spacing(7.5),
      height: theme.spacing(7.5),
      fontSize: '16px',
      cursor: 'pointer',
    },
  };

  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');

  const [openChatWidget, setOpenChatWidget] = useState(false);
  const anchorRefChatWidget = useRef(null);
  const chatScroll = useRef<HTMLElement>(null);
  const [errorMessageIndex, setErrorMessageIndex] = useState<number>(0);
  useEffect(() => {
    if (props.messages) {
      setMessages([...props.messages]);
    }
  }, [props.messages]);

  useEffect(() => {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
    ${props.chatInterface?.customCss}  
    `;
    document.head.appendChild(style);

    // Cleanup function to remove the style tag when the component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  useEffect(() => {
    const index = messages?.findLastIndex((m) => m.sender === "user");
    setErrorMessageIndex(index);
    console.log("index---------",index)
  }, [messages, props.error]);
  const scrollToBottom = () => {
    if (chatScroll?.current) {
      chatScroll.current.scrollTo({
        top: chatScroll.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const addMessage = (
    text: string,
    sender: 'user' | 'assistant',
    id: string = '',
  ) => {
    setMessages((prevMessages) => [...prevMessages, { id: id, text, sender, timetamp: new Date().toString() }]);
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  };

  const updateMessageById = (id: string, newText: string) => {
    //   console.log('ALL MESSAGES', messages);
    //  console.log(' MESSAGE ID', id);
    setMessages((prevMessages) => {
      const existingMessageIndex = prevMessages.findIndex((m) => m.id === id);
      //     console.log('I N D E X', index);

      scrollToBottom();

      if (existingMessageIndex > -1) {
        //  console.log('Updating Message', prevMessages[index]);
        prevMessages[existingMessageIndex].text = newText.replace(
          /(^")|("$)/g,
          '',
        );
        return [...prevMessages];
      } else {
        //  console.log('Adding Message.. ');
        return [
          ...prevMessages,
          {
            id: id,
            text: newText,
            sender: 'assistant',
            timetamp: new Date().toString()
          },
        ];
      }
    });
  };
  useImperativeHandle(ref, () => ({
    addMessage,
    updateMessageById,
  }));
  const handleUserMessage = () => {
    if (newMessage?.trim().length) {
      const id = '_' + Math.random().toString(36).substr(2, 9);
      addMessage(newMessage, 'user', id);
      if (props.onUssrMessage) {
        props.onUssrMessage(newMessage, 'user', id);
      }
      setNewMessage('');
    }
  };

  const handleChatWidgetToggle = () => {
    if (props.onToggle) {
      props.onToggle(openChatWidget);
    }
    setOpenChatWidget((prevOpen) => !prevOpen);
  };

  const ChatWidgetHandleClose = (event: any) => {
    setOpenChatWidget(false);
  };

  const header = () => {
    return (
      <Box
        pt={(props.chatInterface?.chatWindow.padding?.top) +
          'px'}
        bgcolor={rgbToHex(props.chatInterface?.bubbleButtonColor)}
        pl={(props.chatInterface?.chatWindow.padding?.left) +
          'px'}
        pr={(props.chatInterface?.chatWindow.padding?.right) +
          'px'}
        pb={(props.chatInterface?.chatWindow.padding?.bottom) +
          'px'}
      >
        <Stack
          className="widget-title"
          alignItems={'center'}
          direction={'row'}
          // justifyContent={'space-between'}
          style={getCustomStyle('headerFontStyle', props.chatInterface)}
        >
          {props.chatInterface?.showProfilePic &&
            (props.chatInterface?.profilePic?.keyInS3 ? (
              <Box className="chatbot-avatar" mr={1}>
                <img
                  alt=""
                  src={
                    IMAGE_URL + '/' + props.chatInterface?.profilePic?.keyInS3
                  }
                />
              </Box>
            ) : (
              <Box className="chatbot-avatar" mr={1}>
                <img alt="" src={ChatBot} />
              </Box>
            ))}

          {/* props.chatInterface?.profilePic?.keyInS3 ? (
              
            ) : (
              
            )} */}
          {/* </Box> */}
          <Box
            sx={{
              color: rgbToHex(props.chatInterface?.displayNameColor),
            }}
          >
            {props.chatInterface?.displayName}
          </Box>
        </Stack>
      </Box>
    );
  };

  const renderChatWindow = () => {
    return (
      <>
        <Box className="chat-window"
          sx={getCustomStyle('chatWindow', props.chatInterface)}
        >
          {/* @ts-ignore */}
          <Stack
            spacing={
              getCustomStyle('agentMessage', props.chatInterface)?.spacing +
              'px'
            }
            className="chat-history"
            pb={
              getCustomStyle('agentMessage', props.chatInterface)?.spacing +
              'px'
            }
            ref={chatScroll}
          >
            {messages.map((message, ind) => (
              <Box
                className={`${message.sender === 'user' ? 'client' : 'bot'
                  } chat-bubble`}
                key={'mes_id_' + ind}
              >
                <Stack sx={{ width: '100%' }} alignItems={
                  message.sender === 'user'
                    ? 'end'
                    : 'start'
                } >
                  <Stack sx={{ width: '100%' }} alignItems={'center'} justifyContent={
                    message.sender === 'user'
                      ? 'end'
                      : 'start'
                  } direction={'row'} spacing={0.5}>
                    {message.sender === 'user' && props.error && ind === errorMessageIndex &&
                      <ErrorOutlineIcon style={{ color: '#ff0000' }} />
                    }
                    <Box
                      className="message"
                      style={
                        message.sender === 'user'
                          ? getCustomStyle('userMessage', props.chatInterface)
                          : getCustomStyle('agentMessage', props.chatInterface)
                      }
                    >
                      {message.text}

                    </Box>
                  </Stack>
                  <Box className="message-date-time" 
                  fontSize={`${props.chatInterface?.agentMessage?.timeFont?.size}px`}
                  mt={1} 
                  color={props.error && message.sender === 'user' && ind === errorMessageIndex ? rgbToHex(props.chatInterface?.errorMessageTextColor) :  rgbToHex(props.chatInterface?.timeStampTextColor)}>
                    {props.error && message.sender === 'user' && ind === errorMessageIndex  ? (
                      <span>{props.error}</span>
                    ) : (
                      (() => {
                        const messageDate = moment(message?.timetamp);
                        const currentDate = moment();

                        if (messageDate.isSame(currentDate, 'day')) {
                          return messageDate.format('h:mm A');
                        } else if (messageDate.isSame(currentDate.subtract(1, 'day'), 'day')) {
                          return `Yesterday, ${messageDate.format('h:mm A')}`;
                        } else {
                          return messageDate.format('Do MMM h:mm A');
                        }
                      })()
                    )}
                  </Box>

                </Stack>

              </Box>
            ))}

            {/* message loader */}

            {props.loading ? <Box className={'bot chat-bubble'} >


              <Box
                className="message"
                style={getCustomStyle('agentMessage', props.chatInterface)}
              >
                <Box className="typing" >
                  <Box className="typing__dot" bgcolor={rgbToHex(props.chatInterface?.botMessageTextColor)}></Box>
                  <Box className="typing__dot" bgcolor={rgbToHex(props.chatInterface?.botMessageTextColor)}></Box>
                  <Box className="typing__dot" bgcolor={rgbToHex(props.chatInterface?.botMessageTextColor)}></Box>
                </Box>
              </Box>

            </Box> : <></>}
          </Stack>
        </Box>

        <Stack
          className="chat-input"
          sx={getCustomStyle('chatInputContainer', props.chatInterface)}
          spacing={1}
          alignItems={'end'}
        >

          <FormControl
            component={'form'}
            onSubmit={(e) => {
              e.preventDefault();
              handleUserMessage();
            }}
            fullWidth
          >

            <InputBase
              id="standard-adornment-password"
              type="text"
              placeholder="Type a message..."
              value={newMessage}
              autoComplete="off"
              onChange={(e) => setNewMessage(e.target.value)}
              style={getCustomStyle('chatInput', props.chatInterface)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      color: rgbToHex(props.chatInterface?.chatInputTextColor),
                      opacity: props.disableSendButton ? "0.4" : "1"
                    }}
                    disabled={props.disableSendButton}
                    aria-label="send"
                    size="small"
                    type="submit"
                  >
                    <SendIcon className="send-icon" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Box title='Start new chat'
            display={'flex'}
            alignItems={'center'}
            style={{
              color: rgbToHex(props.chatInterface?.chatInputTextColor),
              fontSize: '12px',
              cursor: 'pointer',
            }}
            onClick={() => { props.onRefresh && props.onRefresh(); }}
          >
            <ExitToAppIcon style={{ fontSize: '16px' }} /> <Box ml={0.5}>Leave the chat</Box>
          </Box>
        </Stack>
      </>
    );
  };
  const renderInlineChatWidget = () => {
    return (
      <Box className="ai-chat-widget">
        <Box
          className="chat-widget-container"
          sx={getCustomStyle('chatWindowContainer', props.chatInterface)}
        >
          <Box className="chat-window"
            sx={getCustomStyle('chatWindow', props.chatInterface)}
          >
            {/* @ts-ignore */}
            <Stack
              spacing={
                getCustomStyle('agentMessage', props.chatInterface)?.spacing +
                'px'
              }
              className="chat-history"
              pb={
                getCustomStyle('agentMessage', props.chatInterface)?.spacing +
                'px'
              }
              ref={chatScroll}
            >
              {messages.map((message, ind) => (
              <Box
                className={`${message.sender === 'user' ? 'client' : 'bot'
                  } chat-bubble`}
                key={'mes_id_' + ind}
              >
                <Stack sx={{ width: '100%' }} alignItems={
                  message.sender === 'user'
                    ? 'end'
                    : 'start'
                } >
                  <Stack sx={{ width: '100%' }} alignItems={'center'} justifyContent={
                    message.sender === 'user'
                      ? 'end'
                      : 'start'
                  } direction={'row'} spacing={0.5}>
                    {message.sender === 'user' && props.error && ind === errorMessageIndex &&
                      <ErrorOutlineIcon style={{ color: '#ff0000' }} />
                    }
                    <Box
                      className="message"
                      style={
                        message.sender === 'user'
                          ? getCustomStyle('userMessage', props.chatInterface)
                          : getCustomStyle('agentMessage', props.chatInterface)
                      }
                    >
                      {message.text}

                    </Box>
                  </Stack>
                  <Box className="message-date-time" 
                  fontSize={`${props.chatInterface?.agentMessage?.timeFont?.size}px`}
                   mt={1} color={props.error && message.sender === 'user' && ind === errorMessageIndex ? rgbToHex(props.chatInterface?.errorMessageTextColor) :  rgbToHex(props.chatInterface?.timeStampTextColor)}>
                    {props.error && message.sender === 'user' && ind === errorMessageIndex  ? (
                      <span>{props.error}</span>
                    ) : (
                      (() => {
                        const messageDate = moment(message?.timetamp);
                        const currentDate = moment();

                        if (messageDate.isSame(currentDate, 'day')) {
                          return messageDate.format('h:mm A');
                        } else if (messageDate.isSame(currentDate.subtract(1, 'day'), 'day')) {
                          return `Yesterday, ${messageDate.format('h:mm A')}`;
                        } else {
                          return messageDate.format('Do MMM h:mm A');
                        }
                      })()
                    )}
                  </Box>

                </Stack>

              </Box>
            ))}
              {/* message loader */}

              {props.loading ? <Box className={'bot chat-bubble'} >
                <Box
                  className="message"
                  style={getCustomStyle('agentMessage', props.chatInterface)}
                >
                  <Box className="typing" >
                    <Box className="typing__dot" bgcolor={rgbToHex(props.chatInterface?.botMessageTextColor)}></Box>
                    <Box className="typing__dot" bgcolor={rgbToHex(props.chatInterface?.botMessageTextColor)}></Box>
                    <Box className="typing__dot" bgcolor={rgbToHex(props.chatInterface?.botMessageTextColor)}></Box>
                  </Box>
                </Box>

              </Box> : <></>}
            </Stack>
          </Box>

          <Stack
            className="chat-input"
            sx={getCustomStyle('chatWindow', props.chatInterface)}
            spacing={1}
            alignItems={'end'}
          >

            <FormControl
              component={'form'}
              onSubmit={(e) => {
                e.preventDefault();
                handleUserMessage();
              }}
              fullWidth
            >
              <InputBase
                id="standard-adornment-password"
                type="text"
                placeholder="Type a message..."
                value={newMessage}
                autoComplete="off"
                onChange={(e) => setNewMessage(e.target.value)}
                style={getCustomStyle('chatInput', props.chatInterface)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        color: rgbToHex(
                          props.chatInterface?.chatInputTextColor
                        ),
                        opacity: props.disableSendButton ? "0.4" : "1"
                      }}
                      disabled={props.disableSendButton}
                      aria-label="send"
                      size="small"
                      type="submit"
                    >
                      <SendIcon className="send-icon" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box title='Start new chat'
              display={'flex'}
              alignItems={'center'}
              style={{
                color: rgbToHex(props.chatInterface?.chatInputTextColor),
                fontSize: '12px',
                cursor: 'pointer',
              }}
              onClick={() => { props.onRefresh && props.onRefresh(); }}
            >
              <ExitToAppIcon style={{ fontSize: '16px' }} /> <Box ml={0.5}>Leave the chat</Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    );
  };
  const getCustomStyle = (
    type: string,
    chatInterface: ChatInterfaceType | undefined,
  ): any => {
    let style = {};
    switch (type) {
      case 'agentMessage':
        if (chatInterface?.agentMessage) {
          const { borderRadius, padding, font, spacing } =
            chatInterface?.agentMessage;
          style = {
            backgroundColor: rgbToHex(props.chatInterface?.botMessageColor),
            color: rgbToHex(props.chatInterface?.botMessageTextColor),
            borderRadius: `${borderRadius?.topLeft}px ${borderRadius?.topRight}px ${borderRadius?.bottomRight}px ${borderRadius.bottomLeft}px`,
            padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            fontSize: `${font?.size}px`,
            spacing: spacing,
          };
          return style;
        }

        break;

      case 'userMessage':
        if (chatInterface?.userMessage) {
          const { borderRadius, spacing } = chatInterface?.userMessage;
          //@ts-ignore
          const { padding } = chatInterface?.agentMessage;
          style = {
            backgroundColor: rgbToHex(props.chatInterface?.userMessageColor),
            color: rgbToHex(props.chatInterface?.userMessageTextColor),
            borderRadius: `${borderRadius?.topLeft}px ${borderRadius?.topRight}px ${borderRadius?.bottomRight}px ${borderRadius.bottomLeft}px`,
            padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            fontSize: `${chatInterface.agentMessage?.font?.size}px`,
            spacing: spacing,
          };

          return style;
        }

        break;

      case 'chatWindowContainer':
        if (chatInterface?.chatWindow) {
          const { borderRadius } = chatInterface.chatWindow;

          style = {
            borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
          };
        }
        return style;
      case 'chatWindow':
        if (chatInterface?.chatWindow) {
          const { padding } = chatInterface.chatWindow;

          style = {
            padding: `${padding?.top}px ${padding?.right}px 0px ${padding?.left}px`,
            background: rgbToHex(chatInterface?.chatWindowBgColor),
          };
        }
        return style;
      case 'chatInputContainer':
        if (chatInterface?.chatWindow) {
          const { padding, borderRadius } = chatInterface.chatWindow;
          style = {
            padding: `0px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            background: rgbToHex(chatInterface?.chatWindowBgColor),
          };
        }
        return style;
      case 'chatInput':
        if (chatInterface?.chatInput) {
          const { borderRadius, border, font, padding } =
            chatInterface.chatInput;
          style = {
            color: rgbToHex(chatInterface?.chatInputTextColor),
            border: `${border?.size}px ${border?.state} ${rgbToHex(
              border?.color,
            )}`,
            borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
            // border: '1px solid',
            padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            fontSize: `${font?.size}px`,
          };
        }
        return style;
      case 'headerFontStyle':
        style = {
          fontSize: `${chatInterface?.headerStyle?.font.size}px`,
        };
        return style;

      default:
        return style;
    }
  };

  const renderWidget = () => {
    return (
      <Box
        className={`ai-chat-widget ${props.chatInterface?.theme === 'dark' ? 'dark' : ''
          }`}
      >
        <Box
          className="chat-widget-container"
          sx={getCustomStyle('chatWindowContainer', props.chatInterface)}
        >
          {props.headerComponent || header()}
          {/* @ts-ignore */}
          {renderChatWindow()}
        </Box>
      </Box>
    );
  };
  if (props.widgetStyle === Modes.Inline) {
    return renderInlineChatWidget();
  }
  return (
    <>
      {props.for === 'preview' ? (
        <>
          <Box mb={2} className="floating-chat-window" sx={{ width: "auto" }}>{renderWidget()}</Box>

          <Stack
            direction={'row'}
            justifyContent={`${props.chatInterface?.bubbleButtonAlignment === 'left'
              ? 'start'
              : 'end'
              }`}
          >
            <Avatar className="chat-icon" style={styles.chatAvatar}>
              {props.chatInterface?.chatIcon?.keyInS3 ? (
                <img
                  alt=""
                  src={IMAGE_URL + '/' + props.chatInterface?.chatIcon?.keyInS3}
                  width="35px"
                />
              ) : (
                <ChatBubbleIcon />
              )}
            </Avatar>
          </Stack>
        </>
      ) : (
        <Box
          className={`floating-chat-widget ${props.chatInterface?.bubbleButtonAlignment === 'left' ? 'left' : ''
            }`}
        >
          <Avatar
            className="chat-icon"
            style={styles.chatAvatar}
            ref={anchorRefChatWidget}
            aria-controls={openChatWidget ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleChatWidgetToggle}
          >
            {
              // openChatWidget ? (
              //   <CloseIcon />
              // ) :
              props.chatInterface?.chatIcon?.keyInS3 ? (
                <img
                  alt=""
                  src={IMAGE_URL + '/' + props.chatInterface?.chatIcon?.keyInS3}
                  width="35px"
                />
              ) : (
                <ChatBubbleIcon />
              )
            }
          </Avatar>

          <Popper
            open={openChatWidget}
            className="chat-popup"
            style={{ zIndex: 999 }}
            // placement="top-end"
            placement={`${props.chatInterface?.bubbleButtonAlignment === 'left'
              ? 'top-start'
              : 'top-end'
              }`}
            transition
            anchorEl={anchorRefChatWidget.current}
            role={undefined}
            disablePortal={false}
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement: 'viewport',
              },
            }}
          >
            {({ TransitionProps, placement }) => (
              <Fade {...TransitionProps} timeout={250}>
                <Box
                  sx={{
                    marginBottom: '10px',
                  }}
                >
                  <ClickAwayListener onClickAway={ChatWidgetHandleClose}>
                    <Box className="floating-chat-window">{renderWidget()}</Box>
                  </ClickAwayListener>
                </Box>
              </Fade>
            )}
          </Popper>
        </Box>
      )}
    </>
  );
};
const ChatWidget = forwardRef<WidgetActions, Props>(Widget);
export default ChatWidget;
