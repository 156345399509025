import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import informationIcon from '../../../../src/assets/images/icons/informationIcon.svg';
import CustomSwitch from '../../../Components/customSwitch';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { theme } from '../../../theme/theme';
import '../../../assets/styles/bootstrap.min.css';
import {
  CustomField,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import { useLocation } from 'react-router';

// MenuProps for Select component
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

// Interface for error messages
type Errors = {
  fieldName?: string;
  emptyCheckboxArea?: string;
};

// Props for AddOrUpdateCustomField component
type AddOrUpdateCustomFieldProps = {
  handleCloseAddNewFieldModal: () => void;
  handleOpenAddNewFieldModal: () => void;
  openAddNewFieldModal: boolean;
  isBeingEdited: boolean;
  customFieldToEdit: CustomField;
};

// Default custom field value
const customFieldDefaultValue: CustomField = {
  fieldData: { fieldName: '', fieldType: 'text' },
  isMandatory: false,
};

/**
 * AddOrUpdateCustomField Component
 * @description Component for adding or updating a custom field.
 * @param {Object} props - Component props
 * @param {Function} props.handleCloseAddNewFieldModal - Function to close the Add New Field modal
 * @param {Function} props.handleOpenAddNewFieldModal - Function to open the Add New Field modal
 * @param {boolean} props.openAddNewFieldModal - Flag to control the visibility of the Add New Field modal
 * @param {boolean} props.isBeingEdited - Flag indicating whether the field is being edited
 * @param {CustomField} props.customFieldToEdit - Custom field object to be edited
 * @returns {JSX.Element} - AddOrUpdateCustomField component
 */
export default function AddOrUpdateCustomField({
  handleCloseAddNewFieldModal,
  handleOpenAddNewFieldModal,
  openAddNewFieldModal,
  isBeingEdited,
  customFieldToEdit,
}: AddOrUpdateCustomFieldProps) {
  const firstRender1 = useRef(true);
  const firstRender2 = useRef(true);
  const location = useLocation();
  const currentTab = location.pathname.split('/').at(-1);
  const dispatch = useDispatch();
  const customFieldState = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;

  const [customField, setCustomField] = useState<CustomField>(
    customFieldDefaultValue as CustomField,
  );
  const [errors, setErrors] = useState<Errors>({} as Errors);
  const [closeAfterSaving, setCloseAfterSaving] = useState<boolean>(false);

  useEffect(() => {
    if (isBeingEdited) {
      setCustomField(customFieldToEdit);
    }
  }, []);

  useEffect(() => {
    if (firstRender1.current) {
      firstRender1.current = false;
    } else {
      if (customFieldState.createOpportunityCustomFieldSuccess) {
        closeAfterSaving && handleCloseAddNewFieldModal();
        setCustomField(customFieldDefaultValue);
      }
    }
  }, [customFieldState.createOpportunityCustomFieldSuccess, closeAfterSaving]);

  useEffect(() => {
    if (firstRender2.current) {
      firstRender2.current = false;
    } else {
      if (customFieldState.updateOpportunityCustomFieldSuccess) {
        closeAfterSaving && handleCloseAddNewFieldModal();
        setCustomField(customFieldDefaultValue);
      }
    }
  }, [customFieldState.updateOpportunityCustomFieldSuccess, closeAfterSaving]);

  /**
   * Handles the change event for custom field inputs.
   * @function handleChange
   * @param {SelectChangeEvent<CustomField>} e - The event object.
   * @description This function adjusts the custom field data based on the field type selection.
   */
  const handleChange = (e: SelectChangeEvent<CustomField>) => {
    let cf = { ...customField };
    if (e.target.name === 'fieldType') {
      if (e.target.value !== 'list' && e.target.value !== 'boolean') {
        delete cf.fieldData.checkboxString;
        delete cf.fieldData.checkboxValues;
        delete cf.fieldData.dropdownString;
        delete cf.fieldData.dropdownValues;
      }

      if (e.target.value === 'list') {
        delete cf.fieldData.checkboxString;
        delete cf.fieldData.checkboxValues;
        cf.fieldData.dropdownString = '';
      }

      if (e.target.value === 'boolean') {
        delete cf.fieldData.dropdownString;
        delete cf.fieldData.dropdownValues;
        cf.fieldData.checkboxString = '';
      }
    }

    setCustomField({
      ...cf,
      fieldData: {
        ...cf?.fieldData,
        [e.target.name]: e.target.value,
      },
    } as CustomField);
  };

  /**
   * Handles the change event for the custom field switch.
   * @function handleSwitchChange
   * @param {any} e - The event object.
   * @description This function updates the custom field's mandatory status based on the switch state.
   */
  const handleSwitchChange = (e: any) => {
    setCustomField({
      ...customField,
      isMandatory: e.target.checked,
    } as CustomField);
  };

  /**
   * Creates or updates a custom field.
   * @function createOrUpdateCustomField
   * @param {boolean} closeAfterSaving - Indicates whether to close the modal after saving.
   * @description This function dispatches an action to create or update a custom field based on the input data.
   */

  const createOrUpdateCustomField = () => {
    // Trim the fieldName if it exists
    const trimmedFieldName = customField?.fieldData?.fieldName?.trim();
    let updatedCustomField = { ...customField };
  
    // Remove the dateFormat from fieldData, if present
    const { dateFormat, ...restFieldData } = customField.fieldData;
    updatedCustomField = {
      ...customField,
      fieldData: restFieldData,
    };
  
    // Set the updated custom field state
    setCustomField(updatedCustomField);
  
    // Construct the data object with trimmed fieldName
    const data = {
      bodyParams: {
        ...updatedCustomField,
        customFieldType: currentTab,
        fieldData: {
          ...updatedCustomField.fieldData,
          fieldName: trimmedFieldName, // Trimmed fieldName set under fieldData
        },
      },
      queryParams: {
        customFieldType: currentTab,
      },
    };
  
    // Dispatch the appropriate action based on isBeingEdited flag
    if (isBeingEdited) {
      dispatch(Actions.updateOpportunityCustomFieldRequest(data));
    } else {
      dispatch(Actions.createOpportunityCustomFieldRequest(data));
    }
  };
  

  /**
   * Handles the change event for list or checkbox custom fields.
   * @function handleListOrCheckbox
   * @param {React.ChangeEvent<HTMLTextAreaElement>} e - The event object.
   * @description This function updates the custom field data for list or checkbox type fields.
   */
  const handleListOrCheckbox = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (customField.fieldData.fieldType === 'boolean') {
      setCustomField({
        ...customField,
        fieldData: {
          ...customField?.fieldData,
          checkboxString: e.target.value,
          checkboxValues: e.target.value.split('\n'),
        },
      } as CustomField);
    } else if (customField.fieldData.fieldType === 'list') {
      setCustomField({
        ...customField,
        fieldData: {
          ...customField?.fieldData,
          dropdownString: e.target.value,
          dropdownValues: e.target.value.split('\n'),
        },
      } as CustomField);
    }
    else if (customField.fieldData.fieldType === 'date') {
      setCustomField({
        ...customField,
        fieldData: {
          ...customField?.fieldData,
          dateFormat: e.target.value,
        },
      } as CustomField);
    }
  };

  const handleDateFormat = (e: SelectChangeEvent<string>) => {
     if (customField.fieldData.fieldType === 'date') {
      setCustomField({
        ...customField,
        fieldData: {
          ...customField?.fieldData,
          dateFormat: e.target.value,
        },
      } as CustomField);
    }
  };

  /**
   * Validates the input data for creating or updating a custom field.
   * @function isValid
   * @returns {boolean} - Indicates whether the input data is valid or not.
   * @description This function checks if the custom field name is entered and if required data for list or checkbox fields is provided.
   */
  const isValid = () => {
    let isValid = true;
    let errorsObj: Errors = {} as Errors;
    if (!customField.fieldData.fieldName) {
      isValid = false;
      errorsObj.fieldName = 'Field name is required';
    }
    if (
      customField.fieldData.fieldType === 'list' &&
      !customField.fieldData.dropdownString
    ) {
      isValid = false;
      errorsObj.emptyCheckboxArea = 'At least one line is required';
    }
    setErrors(errorsObj);
    return isValid;
  };

  const formats = [
    'MM/DD/YYYY',
    'DD/MM/YYYY',
    'YYYY/MM/DD',
    'YYYY/DD/MM',
  ];
  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={openAddNewFieldModal}
        onClose={handleCloseAddNewFieldModal}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <Grid component="form" container className="modal-wrapper">
          <Grid item xs={12} pb={4}>
            <Box component="p" mb={1} className="subsecondary">
              {!isBeingEdited ? 'Add new field' : 'Edit Field'}
            </Box>
            <p className="small-subtitle">
              {`You can add a new custom field by adding label and its input
              type.`}
            </p>
          </Grid>
          <Grid item xs={12} pb={4}>
            <Box component="div" className="inputField" pb={4}>
              <label>Name</label>
              <input
                type="text"
                className="form-control small"
                placeholder=""
                name={'fieldName'}
                value={customField?.fieldData.fieldName}
                onChange={handleChange}
                maxLength={60}
              />
              <span className="error_mesage"> {errors?.fieldName} </span>
            </Box>
            <Box component="div" className="inputField" pb={4}>
              <label>Type</label>
              <FormControl size="small" fullWidth>
                <Select
                  type="text"
                  className="form-control select small"
                  placeholder="select"
                  style={{ padding: '4px 0px' }}
                  MenuProps={MenuProps}
                  displayEmpty
                  disabled={isBeingEdited && customField?.fieldData.fieldType !== "text"}
                  // @ts-ignore
                  value={customField?.fieldData.fieldType as string}
                  onChange={handleChange}
                  name="fieldType"
                >
                  <MenuItem value="" disabled hidden>
                    Select
                  </MenuItem>
                  <MenuItem hidden={isBeingEdited} value="text">Text</MenuItem>

                  <MenuItem hidden={isBeingEdited} value="number">Number</MenuItem>
                  <MenuItem hidden={isBeingEdited} value="date">Date</MenuItem>
                  <MenuItem hidden={isBeingEdited} value="url">URL</MenuItem>
                  <MenuItem hidden={isBeingEdited} value="email">Email</MenuItem>
                  <MenuItem hidden={isBeingEdited} value="list">List</MenuItem>
                  <MenuItem hidden={isBeingEdited} value="boolean">Boolean</MenuItem>
                  <MenuItem hidden={isBeingEdited} value="phone">Phone</MenuItem>
                  <MenuItem value="longText">Long Text</MenuItem>
                  <MenuItem hidden={isBeingEdited} value="user">User</MenuItem>
                  {(currentTab === 'accounts' ||
                    currentTab === 'opportunity') && (
                    <MenuItem hidden={isBeingEdited} value="contact">Contact</MenuItem>
                  )}
                  {currentTab === 'opportunity' && (
                    <MenuItem hidden={isBeingEdited} value="account">Account</MenuItem>
                  )}
                  {!isBeingEdited &&
                  <hr />
                  }
                  <MenuItem hidden={isBeingEdited} value="linkedIn">LinkedIn</MenuItem>
                  <MenuItem hidden={isBeingEdited} value="x">X (formerly Twitter)</MenuItem>
                  <MenuItem hidden={isBeingEdited} value="facebook">Facebook</MenuItem>
                  <MenuItem hidden={isBeingEdited} value="instagram">Instagram</MenuItem>
                  <MenuItem hidden={isBeingEdited} value="pinterest">Pinterest</MenuItem>
                  
                </Select>
              </FormControl>
            </Box>
            {customField.fieldData.fieldType === 'list' && (
              <Box>
                <Box component="div" className="inputField" pb={1}>
                  <label>
                    Option per line <span className="required">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control"
                    value={customField.fieldData.dropdownString}
                    name={customField.fieldData.fieldType}
                    onChange={handleListOrCheckbox}
                  >
                    {' '}
                  </textarea>
                  <span className="error_mesage required">
                    {' '}
                    {errors?.emptyCheckboxArea}{' '}
                  </span>
                </Box>
                <Box component="div" className="inputField" pb={1}>
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    spacing={6}
                  >
                    <label>
                      <Box component={'span'}>
                        <Box
                          component="img"
                          sx={{ width: '20px' }}
                          mr={1}
                          alt="information Icon"
                          src={informationIcon}
                        />
                      </Box>
                      Multiple Selections
                      <CustomSwitch
                        color="primary"
                        onChange={(e: any) =>
                          setCustomField({
                            ...customField,
                            fieldData: {
                              ...customField?.fieldData,
                              isMultiSelect: Boolean(e.target.checked),
                            },
                          })
                        }
                        checked={customField?.fieldData?.isMultiSelect}
                        name={'isMultiSelect'}
                        disabled={isBeingEdited}
                      />
                    </label>
                  </Stack>
                </Box>
              </Box>
            )}

            <Box component="div" className="inputField" pb={1}>
              <Stack
                direction={'row'}
                justifyContent={'flex-start'}
                spacing={6}
              >
                <label>
                  <Box component={'span'}>
                    <Box
                      component="img"
                      sx={{ width: '20px' }}
                      mr={1}
                      alt="information Icon"
                      src={informationIcon}
                    />
                  </Box>
                  Mark as Mandatory field
                  <CustomSwitch
                    color="primary"
                    onChange={handleSwitchChange}
                    checked={customField?.isMandatory}
                    name={'isMandatory'}
                  />
                </label>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="end">
              <Stack spacing={1} direction="row">
                {!isBeingEdited && (
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={() => {
                      if (isValid()) {
                        setCloseAfterSaving(false);
                        createOrUpdateCustomField();
                      }
                    }}
                  >
                    Save & Add New
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    if (isValid()) {
                      setCloseAfterSaving(true);
                      createOrUpdateCustomField();
                    }
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </ThemeProvider>
  );
}
