import React, { useRef, useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import Button from '@material-ui/core/Button';
import Grid from '@mui/material/Grid';
import '../../sass/main.scss';
import DashboardLayout from 'Layouts/DashboardLayout';
import { getIntegrationDetailsForLoggedInUser } from 'Redux/API';
import { Box, Stack } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import GoogleWorkspace from '../../../src/assets/images/icons/Google_G_Logo.svg';
import GoogleGmailIcon from '../../../src/assets/images/icons/Gmail_icon.svg';
import GoogleCalendarIcon from '../../../src/assets/images/icons/Google-Calendar.svg';
import MicrosoftCalenderIcon from '../../../src/assets/images/icons/Microsoft-Calender.svg';
import OfficeCalenderIcon from '../../../src/assets/images/icons/Office-Calender.svg';
import { googleSignInPopupForCalendarOuter, disconnectGoogleCalendar } from 'Redux/API';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import CalenderIcon from '../../../src/assets/images/icons/meetings.svg';
import MailOutlineIcon from '../../../src/assets/images/mail.svg';
import { createNotification } from 'helpers';
interface GoogleCalendarIntegration {
  _id: string;
  appData?: {
    user?: {
      email?: string;
    };
  };
}

interface IntegrationResponse {
  success: boolean;
  url?: string;
  message: any;
  integrationRes?: {
    type?: string;
    // Add other properties expected in integrations
  };
}

interface IntegrationResponseOne {
  data: {
    success: boolean;
    url: string;
    message: any;
    integrations: {
      type: string;
    };
  };
}

const Integrations = () => {
  const googlePopupRef = useRef<Window | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState('');
  const [isCalendarConnected, setIsCalendarConnected] = useState<boolean | undefined>(undefined);
  const [integrationId, setIntegrationId] = useState('');
  const [emailId, setEmailId] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);



  /**
   * @function useEffect
   * @description React Hook that runs after the component mounts.
   * Initiates the fetching of meeting integration details.
   */
  useEffect(() => {

    getIntegrationDetails();
  }, []);

  /**
   * @function meetingsIntegrationDetails
   * @description Fetches and updates meeting integration details, specifically Google Calendar integration.
   */
  const getIntegrationDetails = async () => {
    try {
      const integrationRes = await getIntegrationDetailsForLoggedInUser() as IntegrationResponseOne;
      console.log("integrationRes~~~~~", typeof (integrationRes?.data?.success));

      //  isCalIntegrationSuccess = integrationRes?.data?.success;
      if (integrationRes?.data?.success) {
        if (Object.keys(integrationRes?.data?.integrations).length) {
          if (integrationRes?.data.integrations.type === "googleCalendar") {
            const googleCalendarIntegrationObj: any = integrationRes.data.integrations;
            if (googleCalendarIntegrationObj) {
              if (Object.keys(googleCalendarIntegrationObj).length > 0) {
                setIntegrationId(googleCalendarIntegrationObj._id || ''); // Fallback to an empty string if _id is undefined
                setEmailId(googleCalendarIntegrationObj.appData?.user?.email || ''); // Fallback to an empty string if email is undefined
                setIsCalendarConnected(true);
              } else {
                setIsCalendarConnected(false);
              }
            }
          }
        }
      } else {
        setIsCalendarConnected(false);
      }
    } catch (error) {
      console.log('error');
      createNotification('error', 'Something went wrong');
    }
  };

  /**
 * @function handleClose
 * @description Closes the configuration modal and resets the selected calendar service.
 */
  const handleClose = () => {
    setOpen(false);
    setSelectedService('');
  };

  /**
 * @function handleSelectService
 * @param {string} service - Selected calendar service.
 * @description Sets the selected calendar service when configuring the calendar integration.
 */
  const handleSelectService = (service: any) => {
    setSelectedService(service);
  };

  /**
 * @function handleSignIn
 * @description Initiates the sign-in process for the selected calendar service.
 * Opens a popup window for Google Calendar sign-in and handles the response.
 */
  const handleSignIn = async () => {
    try {
      //  const popup = window.open(state.googleOAuthUrl, '_blank');
      const width = 600;
      const height = 800;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      if (selectedService == "google") {
        googlePopupRef.current = window.open(
          undefined,
          'Google Sign In',
          `width=${width},height=${height},left=${left},top=${top}`,
        );
        const data = {
          integration: {
            isActive: true,
            lngCode: 'en',
            name: 'Google Calendar',
            type: 'googleCalendar',
          },
        };

        const response = await googleSignInPopupForCalendarOuter(data) as IntegrationResponseOne;
        if (response.data.success) {
          if (response.data.url) {
            // Ensure googlePopupRef.current is not null before accessing it
            if (googlePopupRef.current) {
              googlePopupRef.current.location.href = response.data.url;
            } else {
              console.error('Popup reference is null');
            }
          }
        }

        const handleMessage = (event: MessageEvent) => {
          if (event.source === googlePopupRef.current) {
            const responseData = event.data;
            setEmailId(responseData.integrations.user.email);
            setIsCalendarConnected(true);
            createNotification('success', responseData.message);
            setIntegrationId(responseData.integrations._id);
            window.removeEventListener('message', handleMessage);
            googlePopupRef?.current?.close();
            handleClose();
            // dispatch(Actions.getAppIntegrations(eventId));
          }
        };
        //   Listen for messages from the OAuth window
        window.addEventListener('message', handleMessage);
      } else {
        alert(selectedService + " service is not available yet! Please use Google for now!");
      }

    } catch (error) {
      console.log(error);
      createNotification('error', 'Something went wrong');
      // Handle the error
    }
  };

  /**
* @function handleClickOpen
* @description Opens the configuration modal for selecting a calendar service.
*/
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
* @function handleDisconnect
* @description Disconnects the current calendar integration and updates the state.
*/
  const handleDisconnect = async () => {
    setLoader(true);
    const data = {
      integrationId: integrationId
    };
    const response = await disconnectGoogleCalendar(data) as IntegrationResponseOne;
    if (response && response.data.success) {
      setLoader(false);
      setIsCalendarConnected(false);
      setEmailId('');
      handleCloseConfirmationModal();
      createNotification('success', response.data.message);
    }
  };

  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  return (
    <>
      <DashboardLayout title="Integrations" pageTitle="Integrations">
        <ThemeProvider theme={theme}>

          <div className="dashboard-containor">
            <div className="container-with-sidebar">
              <h3 className='heading'> Account Settings </h3>

              <Tabs defaultIndex={activeTabIndex}>

                <div className="project-sidebar">
                  <TabList>
                    <Tab value='1'> <img src={CalenderIcon} /> <Link to={'/integrations'} >Integrations</Link></Tab>
                    {/* <Tab value='2'> <img src={MailOutlineIcon} style={{ width: "17px" }} /> <Link to={'/integrations'}>Inbox Integration</Link></Tab> */}
                  </TabList>
                </div>

                <div className="container-sidebar-right">
                  <TabPanel value='1'>
                     {/* Common code for calendar and gmail */}
                    <Box mt={3}>
                      <Grid container spacing={3}> 
                        {/* code for google calendar */}
                        <Grid item>
                          <Box className="integrated-apps">
                            <Stack
                              direction={'row'}
                              // justifyContent="space-between"
                              justifyContent="center"
                              alignItems={'start'}
                              mb={1.5}
                            >
                              <Box mt={1.5}>
                                <Box
                                  component={'img'}
                                  className="app-icon"
                                  alt="app icon"
                                  src={GoogleCalendarIcon}
                                  width={48}
                                />
                              </Box>
                            </Stack>
                            {isCalendarConnected == true && (<Box mb={2}>
                              <Stack
                                py={1}
                                px={1.5}
                                sx={{
                                  backgroundColor: '#f6f6fc',
                                  borderRadius: '6px',
                                }}
                                direction={'row'}
                                alignItems={'center'}
                                spacing={1}
                              >
                                <img src={GoogleWorkspace} width={24} />
                                <Box className="small-subtitle">
                                  {emailId}
                                </Box>
                              </Stack>
                            </Box>)}
                            <Box>
                              <Box mb={1.5} className="app-title">
                                Google Calendar
                              </Box>
                              <Box mb={1.5} className="app-detail">
                                Synchronize with your Google <br />
                                Calendar to create/edit/delete <br />
                                events in your calendar.
                              </Box>
                            </Box>
                            <Box mt={1.5} mb={1.5} className="app-detail">

                              {isCalendarConnected === false ? (
                                <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
                                  Connect
                                </Button>
                              ) : isCalendarConnected === true ? (
                                <Button variant="outlined" color="secondary" size="small" onClick={handleOpenConfirmationModal}>
                                  Disconnect
                                </Button>
                              ) : null}

                            </Box>
                          </Box>
                        </Grid>
                        {/* code for google emails */}
                        <Grid item>
                          <Box className="integrated-apps">
                            <Stack
                              direction={'row'}
                              // justifyContent="space-between"
                              justifyContent="center"
                              alignItems={'start'}
                              mb={1.5}
                            >
                              <Box mt={1.5}>
                                <Box
                                  component={'img'}
                                  className="app-icon"
                                  alt="app icon"
                                  src={GoogleGmailIcon}
                                  width={48}
                                  p={0.5}
                                />
                              </Box>
                            </Stack>

                            {isCalendarConnected == true && (<Box mb={2}>
                              <Stack
                                py={1}
                                px={1.5}
                                sx={{
                                  backgroundColor: '#f6f6fc',
                                  borderRadius: '6px',
                                }}
                                direction={'row'}
                                alignItems={'center'}
                                spacing={1}
                              >
                                <Box component={'img'} src={GoogleWorkspace} width={24} />
                                <Box className="small-subtitle">
                                  {emailId}
                                </Box>
                              </Stack>
                            </Box>)}
                            <Box>
                              <Box mb={1.5} className="app-title">
                                Google Emails
                              </Box>
                              <Box mb={1.5} className="app-detail">
                                Synchronize with your Google <br />
                                Inbox to send or receive <br />
                                emails in your account.
                              </Box>
                            </Box>
                            <Box mt={1.5} mb={1.5} className="app-detail">
                              {isCalendarConnected === false ? (
                                <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
                                  Connect
                                </Button>
                              ) : isCalendarConnected === true ? (
                                <Button variant="outlined" color="secondary" size="small" onClick={handleOpenConfirmationModal}>
                                  Disconnect
                                </Button>
                              ) : null}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="meeting-calender-modal" px={1.5}>
              <Box component={Grid} mt={-2} item xs={12} pb={5}>
                <Box component="p" mb={4} className="subsecondary">
                  {' '}
                  Select a Service
                </Box>

                <Grid container>
                  <Grid item md={4}>
                    <Stack
                      alignItems={'center'}
                      justifyContent={'center'}
                      className='calender-service'
                      onClick={() => handleSelectService('google')}
                    >
                      <Box
                        p={3.25}
                        mb={3}
                        className={`service-icon ${selectedService === "google" ? 'active' : ''}`}
                      >
                        <img src={GoogleWorkspace} width={42} height={42} />
                      </Box>
                      <Box className="small-subtitle primary-text">Google</Box>
                    </Stack>
                  </Grid>
                  <Grid item md={4}>
                    <Stack
                      alignItems={'center'}
                      justifyContent={'center'}
                      className='calender-service'
                      onClick={() => handleSelectService('microsoft')}
                    >
                      <Box
                        p={3.25}
                        mb={3}
                        className={`service-icon ${selectedService === "microsoft" ? 'active' : ''}`}
                      >
                        <img src={MicrosoftCalenderIcon} width={42} height={42} />
                      </Box>
                      <Box className="small-subtitle primary-text">Microsoft</Box>
                    </Stack>
                  </Grid>
                  <Grid item md={4}>
                    <Stack
                      alignItems={'center'}
                      justifyContent={'center'}
                      className='calender-service'
                      onClick={() => handleSelectService('office365')}
                    >
                      <Box
                        p={3.25}
                        mb={3}
                        className={`service-icon ${selectedService === "office365" ? 'active' : ''}`}
                      >
                        <img src={OfficeCalenderIcon} width={42} height={42} />
                      </Box>
                      <Box className="small-subtitle primary-text">Office 365</Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Grid item xs={12}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={handleSignIn}
                    >
                      Next
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      disableElevation
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          {/* confirmation modal start here!*/}
          <Modal
            open={openConfirmationModal}
            onClose={handleCloseConfirmationModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="" px={1.5}>
              <Box component={Grid} mt={-1} item xs={12} pb={3}>
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  Disconnect Confirmation!
                </Box>
                <p className="small-subtitle">
                  Are you sure you want to disconnect it?
                </p>
              </Box>
              <Grid item xs={12}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    {
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevationon
                          onClick={() => handleDisconnect()}
                        >
                          Disconnect
                        </Button>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() => handleCloseConfirmationModal()}
                        >
                          Cancel
                        </Button>
                      </>
                    }
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          {/* confirmation modal end here!*/}
        </ThemeProvider>
      </DashboardLayout>
    </>
  );
};

export default Integrations;
