import React, { useEffect, useState, useRef, SyntheticEvent, useCallback } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../theme/theme';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Fade from '@material-ui/core/Fade';
import SmartGroupIcon from '../../../../src/assets/images/icons/smart-group-icon.svg';
import GroupIcon from '../../../../src/assets/images/icons/contact-group-icon.svg';
import ContactsIcon from '../../../../src/assets/images/icons/contacts-nav-icon.svg';
import MenuItem from '@material-ui/core/MenuItem';
import GroupsTreeView from './GroupsTreeView';
import { Actions } from 'Redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { AudienceParent, AudienceState } from 'Redux/Reducers/opportunity/audience.reducer';
import AddOrEditAudience from './AddOrEditAudience';
import Contacts, { FetchContactsQueryParams } from '../Contacts/Contacts';
import Loader from 'Components/Loader/loader';
import SmartGroup from './SmartGroup';
import { IconButton, Tooltip } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CustomSwitch from 'Components/customSwitch';
import { useHistory } from 'react-router-dom';

export type FetchAudienceQueryParams = {
    pageNo: number;
    limit: number;
    sortBy: string;
    order: string;
    searchText: string;
};

export type AudienceValidationError = {
    [key: string]: boolean | string;
};

type Props = {
    handleTabChange: (
        e: SyntheticEvent<Element, Event>,
        newValue: string,
        searchText: string,
    ) => void;
};

export default function ContactGroup({ handleTabChange }: Props) {
    const {
        loading,
        includesubgroupsObject,
        audienceList
    } = useSelector((state: any) => state.OpportunityAudience) as AudienceState;
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const [openGroupAction, setOpenGroupAction] = useState<boolean>(false);
    const [openAction, setOpenAction] = useState<boolean>(false);
    const [openAddNew, setOpenAddNew] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<string>('updatedAt');
    const [order, setOrder] = useState<string>('desc');
    const [pageNo, setPageNo] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchText, setSearchText] = useState<string>('');
    const anchorRef = React.useRef(null);
    const [filterByStatus, setFilterByStatus] = useState<string[]>([]);
    const [filterByProject, setFilterByProject] = useState<string[]>([]);
    const [filterByAccount, setFilterByAccount] = useState<string[]>([]);
    const [filterBySource, setFilterBySource] = useState<string[]>([]);
    const [filterByAudience, setFilterByAudience] = useState<string[]>([]);
    const [filterByCustomField, setFilterByCustomField] = useState<string[]>([]);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [addOrEditAudience, setAddOrEditAudience] = useState<boolean>(false);
    const [editData, setEditData] = useState<AudienceParent | undefined>({} as AudienceParent);
    const [groupClickObject, setGroupClickObject] = useState<any>({ groupClick: false, isSmartGroup: false, groupId: '' });
    const [groupIdForAdd, setGroupIdForAdd] = useState<string>('');
    const [openAddSmartGroup, setOpenAddSmartGroup] = useState<boolean>(false);
    const [openGroupbar, setGroupbarOpen] = React.useState(true);
    const [showAddNew, setShowAddNew] = useState<boolean>(false);
    const [includeSubgroups, setIncludeSubgroups] = useState<boolean>(false);
    const [firstRender, setFirstRender] = useState<boolean>(true);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleDrawerOpen = () => {
        setGroupbarOpen(true);
    };

    const handleDrawerClose = () => {
        setGroupbarOpen(false);
    };

    const dispatch = useDispatch();
    const queryParams: FetchAudienceQueryParams = {
        pageNo,
        limit,
        sortBy,
        order,
        searchText,
    };

    const queryParamsContact: FetchContactsQueryParams = {
        pageNo,
        limit,
        sortBy,
        sortOrder,
        from: 'viewContact',
    };

    useEffect(() => {
        fetchAllOpportunityAudience();
    }, [
        pageNo,
        // limit,    //comment limit for temperory
        sortBy,
        order,
        searchText,
    ]);

    useEffect(() => {
        fetchAllContacts();
        ;
    }, [
        includeSubgroups
    ]);

    useEffect(() => {
        if (includesubgroupsObject && typeof includesubgroupsObject === 'object' && Object.keys(includesubgroupsObject).length > 0) {
            setIncludeSubgroups(includesubgroupsObject.includeSubgroups);
        }
    }, [audienceList]);

    useEffect(() => {
      setFirstRender(false)
    }, [])    

    useEffect(() => {
        if(!firstRender){            
            fetchAllContacts();
        }
    }, [
        pageNo,
        limit,
        sortBy,
        sortOrder,
        searchText,
        filterByAudience,
    ]);
    const bodyParamsForfiltering = {
        filterByProject,
        filterByAccount,
        filterBySource,
        filterByAudience,
        filterByCustomField,
        filterByStatus
    };

    const fetchAllContacts = () => {
        const data = {
            queryParams: queryParamsContact,
            bodyParams: { filterArray: bodyParamsForfiltering, searchText, includeSubgroups },
        };

        dispatch(Actions.fetchAllOpportunityContactsRequest(data));
    };

    const fetchAllOpportunityAudience = () => {
        const data = queryParams;
        dispatch(Actions.fetchAllOpportunityAudienceRequest(data));
    };

    const handleClose = () => {
        setOpen(false);
        setOpenAction(false);
        setOpenGroupAction(false);
        setOpenAddNew(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleOpenAllContact = () => {
        setFilterByAudience([]);
        setGroupClickObject({ groupClick: false, isSmartGroup: false, groupId: '' });
        setGroupIdForAdd('');
        history.push({
            pathname: `/opportunity/contacts`,
          });
    };

    const closeAddOrEditAudience = () => {
        setAddOrEditAudience(false);
    };

    const addOrEditAudienceModal = (editData?: AudienceParent) => {
        setEditData(editData);
        setAddOrEditAudience(true);
    };

    const handleClickOpenAddSmartGroup = () => {
        setOpenAddSmartGroup(true);
    };

    const handleCloseAddSmartGroup = () => {
        setOpenAddSmartGroup(false);
    };

    const handleGetDataFromSmartGroup = (data: any) => {
        if (!data.openAddSmartGroup) {
            fetchAllOpportunityAudience();
        }
    };

    // ---for drag sidebar---

    const sidebarRef = useRef<HTMLDivElement>(null);
    const isResizing = useRef<boolean>(false);
    const initialWidth = useRef<number>(275);
    const startX = useRef<number>(0);

    const startResizing = useCallback((mouseDownEvent: React.MouseEvent) => {
        if (sidebarRef.current) {
            isResizing.current = true;
            startX.current = mouseDownEvent.clientX;
            initialWidth.current = sidebarRef.current.offsetWidth;
            mouseDownEvent.preventDefault(); // Prevent text selection
        }
    }, []);

    const stopResizing = useCallback(() => {
        isResizing.current = false;
    }, []);

    const resize = useCallback((mouseMoveEvent: MouseEvent) => {
        if (isResizing.current && sidebarRef.current) {
            const newWidth = initialWidth.current + (mouseMoveEvent.clientX - startX.current);
            sidebarRef.current.style.width = `${newWidth}px`;
        }
    }, []);

    useEffect(() => {
        const handleMouseUp = () => stopResizing();
        const handleMouseMove = (event: MouseEvent) => {
            if (isResizing.current) {
                requestAnimationFrame(() => resize(event));
            }
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [resize, stopResizing]);

    // ---end drag sidebar

    const handleSubGroupToggle = (event: { target: { checked: any; }; }) => {
        // Toggle the value of includeSubgroups
        setIncludeSubgroups((prevIncludeSubgroups) => {
            const newIncludeSubgroups = !prevIncludeSubgroups;

            // Schedule addUpdateIncludeSubGroupContact to run after the state has been updated
            setTimeout(() => {
                addUpdateIncludeSubGroupContact(newIncludeSubgroups);
            }, 0); // 0 milliseconds delay for immediate execution in the next event loop tick

            return newIncludeSubgroups;
        });
    };

    const addUpdateIncludeSubGroupContact = (newIncludeSubgroups: boolean) => {

        let data = {
            id: includesubgroupsObject?._id,
            includeSubgroups: newIncludeSubgroups
        };
        dispatch(Actions.addUpdateIncludeSubGroupContactRequest(data));
    };


    return (
        <ThemeProvider theme={theme} >
            <Grid container className="contact-group" flexWrap={'nowrap'}>
                <Grid item>
                    <Box className="group-sidebar" px={1.5} py={0.7}
                        ref={sidebarRef}
                        style={
                            openGroupbar ?
                                { width: initialWidth.current, transition: 'width 0.05s' }
                                : { width: '56px', minWidth: 'unset' }
                        }
                    >

                        <Box display={'flex'} justifyContent={'end'} mb={1}>
                            <Tooltip placement='right-start' enterDelay={800} arrow title={openGroupbar ? "Show less information" : "Expand to see Groups"}>

                                <IconButton color='primary' size="small" onClick={openGroupbar ? handleDrawerClose : handleDrawerOpen} >
                                    {!openGroupbar ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Stack spacing={1} className={`sidebar-content ${openGroupbar && 'show'}`}>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Box className="paragraph">GROUPS</Box>
                                <Box>
                                    <Button
                                        variant="outlined"
                                        className="button-between"
                                        color="primary"
                                        startIcon={
                                            <>
                                                <AddIcon /> <Box ml={1}>New</Box>
                                            </>
                                        }
                                        endIcon={<ArrowDropDownIcon />}
                                        disableElevation
                                        ref={anchorRef}
                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggle}
                                        size='small'
                                    ></Button>
                                    <Popper
                                        open={open}
                                        style={{ zIndex: 999 }}
                                        placement="bottom-end"
                                        transition
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        disablePortal
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Fade {...TransitionProps} timeout={250}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: theme.palette.common.white,
                                                        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                                        borderRadius: '4px',
                                                        marginTop: '8px',
                                                    }}
                                                >
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList
                                                            autoFocusItem={open}
                                                            id="menu-list-grow"
                                                            onKeyDown={handleListKeyDown}
                                                        >
                                                            <MenuItem onClick={() => addOrEditAudienceModal()}>
                                                                <Stack direction={'row'} spacing={1}>
                                                                    <img src={GroupIcon} alt={'icon'} />
                                                                    <Box>New Group</Box>
                                                                </Stack>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={handleClickOpenAddSmartGroup}
                                                            >
                                                                <Stack direction={'row'} spacing={1}>
                                                                    <img
                                                                        src={SmartGroupIcon}
                                                                        alt={'icon'}
                                                                    />
                                                                    <Box>New Smart Group</Box>
                                                                </Stack>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Box>
                                            </Fade>
                                        )}
                                    </Popper>
                                </Box>
                            </Stack>
                            <Box className={!groupClickObject.groupClick ? 'custom-button active' : 'custom-button'}>
                                <Stack direction={'row'} spacing={1} onClick={handleOpenAllContact}>
                                    <img src={ContactsIcon} alt={'icon'} />
                                    <Box>All Contacts</Box>
                                </Stack>
                            </Box>
                            <Grid item>
                                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} className='inputField'>
                                    <Box component='label' sx={{ marginBottom: "0 !important" }}>Include Sub Contacts</Box>
                                    <CustomSwitch
                                        name="checked"
                                        checked={includeSubgroups}
                                        onChange={(e: { target: { checked: any; }; }) => handleSubGroupToggle(e)}
                                        color="primary"
                                    />
                                </Stack>
                            </Grid>
                                <Box className={'groups-tree'} display={openGroupbar ? 'block' : 'none'} >
                                    <GroupsTreeView
                                        setFilterBy={setFilterByAudience}
                                        setGroupIdForAdd={setGroupIdForAdd}
                                        setGroupClickObject={setGroupClickObject}
                                        groupClickObject={groupClickObject}
                                        getData={handleGetDataFromSmartGroup}
                                        setShowAddNew={setShowAddNew}
                                    />
                                </Box>
                        </Stack>

                        <Box onClick={handleDrawerOpen} className={`sidebar-collapse ${!openGroupbar && 'show'}`}>Contact Groups</Box>
                        {openGroupbar &&
                            <Box component={'div'} className="app-sidebar-resizer" onMouseDown={startResizing} />
                        }
                    </Box>
                </Grid>
                <Grid item xs overflow={'auto'} >
                    <Box className="group-content"
                    // px={1.25} py={4.5}
                    >
                        <Contacts handleTabChange={handleTabChange}
                            groupIdForAdd={groupIdForAdd}
                            groupClickObject={groupClickObject}
                            showAddNewfromGroupTree={showAddNew}
                            setShowAddNewfromGroupTree={setShowAddNew}
                        />
                    </Box>
                </Grid>
            </Grid>
            <AddOrEditAudience
                openModal={addOrEditAudience}
                closeModalFunc={closeAddOrEditAudience}
                editData={editData}
                queryParams={queryParams}
            />
            <SmartGroup
                openModal={openAddSmartGroup}
                closeModalFunc={handleCloseAddSmartGroup}
                editData={editData}
                queryParams={queryParams}
            />
            {loading && <Loader />}
        </ThemeProvider>
    );
}

