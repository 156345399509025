import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  fetchAllOpportunityAccountsRequest: ['data'],
  fetchAllOpportunityAccountsSuccess: ['accounts', 'totalCount', 'contactsObj', 'filterCount'],
  fetchAllOpportunityAccountsError: ['message'],
  fetchOpportunityAccountRequest: ['data'],
  fetchOpportunityAccountSuccess: ['account'],
  fetchOpportunityAccountError: ['message'],
  createOpportunityAccountRequest: ['data'],
  createOpportunityAccountSuccess: ['account', 'accounts', 'totalCount', 'contactsObj','filterCount'],
  createOpportunityAccountError: ['message'],
  updateOpportunityAccountRequest: ['data'],
  updateOpportunityAccountSuccess: ['account', 'accounts', 'totalCount', 'contactsObj','filterCount'],
  updateOpportunityAccountError: ['message'],
  deleteOpportunityAccountRequest: ['data'],
  deleteOpportunityAccountSuccess: ['accounts', 'totalCount', 'contactsObj'],
  deleteOpportunityAccountError: ['message'],
  setIsSavingAsDraftForAccount: ['isSavingAsDraft']
});

export const Constants = Types;

export default Creators;