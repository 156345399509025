import '../../ChatWidget/style.css';

import { Modes } from 'Pages/AIChatbot/EmbedCodePopUp';
import {
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { theme } from 'theme/theme';
import { v4 as uuidv4 } from 'uuid';

import { ChatInputProps } from './ChatInput';
import { ChatWindowActions } from './ChatWindow';
import FloatingWidget from './FloatingWidget';
import WidgetHelper from './Helper';
import InlineChatWidget, { WidgetProps } from './InlineWidget';
import WidgetPreview from './WidgetPreview';
interface Props extends WidgetProps {
  widgetStyle: string;
  for?: string;
  onChatEndConfirmClick?: Function;
  onChatEndCancelClick?: Function;
  chatInput: ChatInputProps;
}
interface FeedBack {
  _id?: string;
  type: string;
  from?: string;
  response?: any;
  comment?: string;
}
export interface Message {
  timetamp: string;
  _id?: string;
  id: string;
  text: string;
  prompt?: string;
  sender: 'user' | 'assistant';
  feedback?: FeedBack;
}


export interface WidgetActions extends ChatWindowActions {
}
const Widget: ForwardRefRenderFunction<WidgetActions, Props> = (props, ref) => {
  const chatWindowRef = useRef<ChatWindowActions>(null);

  const styles = {
    chatAvatar: {
      // backgroundColor: '#5141e7',
      backgroundColor: WidgetHelper.rgbToHex(props.chatInterface?.bubbleButtonColor),
      color: '#ffffff',
      width: theme.spacing(7.5),
      height: theme.spacing(7.5),
      fontSize: '16px',
      cursor: 'pointer',
    },
  };
  useEffect(() => {
    const style = document.createElement('style');
    //  style.type = 'text/css';
    style.innerHTML = `
    ${props.chatInterface?.customCss}  
    `;
    document.head.appendChild(style);

    // Cleanup function to remove the style tag when the component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const addMessage = (text: string, sender: 'user' | 'assistant', id: string = '') => {
    chatWindowRef.current?.addMessage(text,
      sender,
      id);
  };

  const updateMessageById = (messageId: string, fullMessage: string) => {
    chatWindowRef.current?.updateMessageById(messageId, fullMessage);
  };

  const endChat = () => {
    console.log("Ending in widget");
    chatWindowRef.current?.endChat();
  };
  const onChatEndConfirmation = () => {
    if (props.onChatEndConfirmClick) {
      props.onChatEndConfirmClick();
    }
  };
  const onUserMessage = (text: string) => {
    if (props.chatInput.onUserMessage) {
      props.chatInput.onUserMessage(text);
    }
    addMessage(text, "user", uuidv4());
  };
  useImperativeHandle(ref, () => ({
    addMessage,
    updateMessageById,
    endChat
  }));
  if (props.widgetStyle === Modes.Inline) {
    return <InlineChatWidget
      chatInterface={props.chatInterface}
      chatWindow={{ ...props.chatWindow }}
      chatWindowRef={chatWindowRef}
      chatInput={{
        ...props.chatInput, onUserMessage
      }}
      headerComponent={props.headerComponent} 
      onChatEndConfirmClick={() => onChatEndConfirmation()}
    />;
  };
  return (
    <>
      {props.for === 'preview' ? (
        <WidgetPreview
          styles={styles}
          chatInterface={props.chatInterface}
          chatWindow={props.chatWindow}
          chatWindowRef={chatWindowRef}
          chatInput={props.chatInput}
          headerComponent={props.headerComponent}
        />
      ) : (
        <FloatingWidget
          styles={styles}
          chatInterface={props.chatInterface}
          chatWindow={{ ...props.chatWindow }}
          chatWindowRef={chatWindowRef}
          chatInput={{
            ...props.chatInput, onUserMessage
          }}
          headerComponent={props.headerComponent} 
          onChatEndConfirmClick={() => onChatEndConfirmation()}
        />
      )}
    </>
  );
};
const ChatWidget = memo(forwardRef<WidgetActions, Props>(Widget));
export default ChatWidget;
