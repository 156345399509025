import { Button } from '@material-ui/core';
import { Stack, Grid, Box } from '@mui/material';
import { AccountsState } from 'Redux/Reducers/opportunity/accounts.reducer';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  CustomField,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  Contact,
  ContactCf,
  ContactState,
} from 'Redux/Reducers/opportunity/contacts.reducer';
import Loader from 'Components/Loader/loader';
import { Actions } from 'Redux/Actions';
import { getSystemLevelPermissions, rgbToHex2 } from '../../../../helpers/common';
import moment from 'moment';
import { AudienceChild } from 'Redux/Reducers/opportunity/audience.reducer';
import { 
  OpportunityStates as OpportunityDynamicStates, 
  OpportunityStates_State 
} from 'Redux/Reducers/opportunity/opportunityStates.reducer';
import { useLocation } from 'react-router';

type Props = {
  closeViewModal: () => void;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  contactToBeEdited: Contact | undefined;
  commentSectionOpened: boolean;
};

function ContactDetails({ setShowAddNew, setShowViewModal, contactToBeEdited, commentSectionOpened }: Props) {
  const { isLoading: isAccountsLoading } = useSelector(
    (state: any) => state.OpportunityAccounts,
  ) as AccountsState;

  const {opportunityStates: opportunityDynamicStates, fetchAllOpportunityStateSuccess} = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;

  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;

  const {
    isLoading: isContactsLoading,
    openedContact,
    fetchOpportunityContactSuccess,
  } = useSelector((state: any) => state.OpportunityContacts) as ContactState;
  const location = useLocation();
  const uniqueEijentId = location.pathname.split('/').at(-1); // Extract account ID from the URL
  const currentPath = location.pathname.split('/').at(2);
  const dispatch = useDispatch();
  const [allowed, setAllowed] = useState<any>({});
  useState<Contact>({} as Contact);

  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
  }, []);

  function formatDateField(listValue: any, cf: any, cid: any) {
    if (cf.fieldData.fieldType === 'date') {
      const dateValue = listValue?.customFields?.[cf._id];
      if (dateValue) {
        const customField = cid.find((format: { _id: any; }) => format._id === cf._id);
        if (customField) {
          const dateFormat = customField && customField.fieldData.dateFormat ? customField.fieldData.dateFormat : 'MM/DD/YYYY';
          return moment(dateValue).format(dateFormat);
        }
      }
    }
    return null;
  }


  const showRestOfPages = (cfs: (string | { email: string; })[]) => {
    return cfs?.slice(1, cfs.length);
  };

  const getStateColor = (contact: Contact | undefined) => {
    // Ensure opportunity and state exist and have at least one element
    if (contact?.state?.length > 0) {
      const defaultState = opportunityDynamicStates.find(
        (state) => state.name === contact?.state[0].name
      );
      // Return textColor and backgroundColor if found, else return default values
      if (defaultState) {
        return {
          textColor: defaultState.textColor ? rgbToHex2(defaultState.textColor) : "#000056", // Default text color
          backgroundColor: rgbToHex2(defaultState.backgroundColor)   || "#ffffff00", // Default background color
        };
      }
    }
  
    // If state length is 0, return no color for both
    return {
      textColor: "",
      backgroundColor: "",
    };
  };

  const customFieldValue = (
    cf: CustomField,
    contactToBeEdited: Contact | undefined
  ) => {
    const value1 = contactToBeEdited?.customFields?.[cf._id as string] as any | any[];
     if (cf.fieldData.fieldType === 'boolean') {
      const boolValue = Array.isArray(value1) ? value1[0] : value1; // Get the first element if it's an array
    return (boolValue && boolValue.toString().toUpperCase() === "TRUE") ? "TRUE" : "FALSE";
    } else if (cf.fieldData.fieldType === 'user' && value1?.length > 0) {
      return (
        <Stack direction={'row'} spacing={1}>
          <span
            className={`long-text ${cf.fieldData.fieldType === 'user' && 'link'}`}
            style={{ maxWidth: '80%' }}
            onClick={() => {
              if (cf.fieldData.fieldType === 'user' &&  value1 && value1?.length > 0) {
                window.open(`mailto:${ value1 && value1[0]?.email || value1[0]}`);
              }
            }}
          >
            {cf.fieldData.fieldType === 'user'
              ? value1[0]?.email || value1[0]
              : value1[0]}
          </span>

          {/* If there are more emails, display a popup with the rest */}
          { value1 && value1?.length > 1 && (
            <div className="detail-popup">
              <span className="count">+{ value1 && value1?.length - 1}</span>
              <div className="info">
                {showRestOfPages(value1).map((cfValue: string | { email: string; }, i: number) => {
                  const isObject = typeof cfValue === 'object' && cfValue !== null;
                  const displayValue = isObject ? (cfValue as { email: string; }).email : cfValue;

                  return (
                    <div
                      key={i}
                      className={cf.fieldData.fieldType === 'user' ? 'link' : ''}
                      style={{ zIndex: 999999 }}
                      onClick={() => {
                        if (cf.fieldData.fieldType === 'user' && isObject) {
                          window.open(`mailto:${displayValue}`);
                        }
                      }}
                    >
                      <p>{displayValue}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Stack>
      );
    } else {
      return cf.fieldData.fieldType === 'date'
        ? formatDateField(contactToBeEdited, cf, customFields)
        : contactToBeEdited?.customFields?.[cf._id as string] || '';
    }
  };

  const showRestOfGroups = (groups: AudienceChild[]) => {
    let groupsLength = groups.length;
    return groups.slice(1, groupsLength);
  };

  return (
    <>
      <Box className="Opportunity-Account">
        {/* @ts-ignore */}

        <Box className="account-details">
          <Stack mb={2} direction={'row'} justifyContent={'space-between'}>
            <Box className="subtitle">Details</Box>
            {allowed['editContacts'] && (
              <Button
                onClick={() => {
                  setShowAddNew(true);
                  setShowViewModal(false);
                }}
                variant="contained"
                color="primary"
                disableElevation
                size='small'
              >
                Edit
              </Button>
            )}
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={12}
              sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4} >
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Eijent Id:
                  </Box>
                </Grid>
                <Grid item xs={12} md>
                  <Box className="small-subtitle primary-text ">
                    {contactToBeEdited?.uniqueEijentId}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Name<span className="required">*</span>:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text">
                    {contactToBeEdited?.fullName}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">Email:</Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text link"
                    onClick={() => window.open(`mailto:${contactToBeEdited?.email}`)}>
                    {contactToBeEdited?.email}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    State:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                {contactToBeEdited?.state?.length > 0 ? (
                 <Box component="span" className="table-status "
                  sx={{
                      color: `${getStateColor(contactToBeEdited).textColor} !important`,
                      bgcolor: `${getStateColor(contactToBeEdited).backgroundColor}`,
                    }}
                    >
                      { (contactToBeEdited && contactToBeEdited.state && Array.isArray(contactToBeEdited.state))? contactToBeEdited.state[0]?.name || "" :''}
                    </Box>
                  ) : (
                      '-'
                    )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Source:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text ">
                    {/* {openedContact?.source ? camelCaseToTitleCase(openedContact?.source) : '-'} */}
                    {contactToBeEdited?.source === 'aiAgent'
                      ? 'AI Agent'
                      : contactToBeEdited?.source === 'imported'
                        ? 'Imported'
                        : 'Manually Added'}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Contact Groups:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                    
                    <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span className="long-text" style={{ maxWidth: '80%' }}>
                      {' '}
                      {contactToBeEdited?.audience &&
                        contactToBeEdited?.audience?.length > 0
                        ? contactToBeEdited?.audience[0]?.audienceName
                        : ''}
                    </span>
                    {contactToBeEdited?.audience && contactToBeEdited?.audience?.length > 1 && (
                      <div className="detail-popup">
                        <span className="count">
                          +{contactToBeEdited?.audience.length - 1}
                        </span>
                        <Box sx={{right: '0 !important'}} className="info">
                          {showRestOfGroups(contactToBeEdited?.audience).map(
                            (group: AudienceChild, i: number) => {
                              return (
                                <div key={i}>
                                  <p>{group.audienceName}</p>
                                </div>
                              );
                            },
                          )}
                        </Box>
                      </div>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            {customFields.map((cf: CustomField) => {
              return (
                <Grid item xs={12}
                  sm={cf.fieldData.fieldType === 'longText' ? 12 : commentSectionOpened ? 12 : 6}
                  lg={cf.fieldData.fieldType === 'longText' ? 12 : commentSectionOpened ? 6 : 4}
                  key={cf._id}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box className="small-subtitle bold primary-text">
                        {cf.fieldData.fieldName}:
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction={'row'} spacing={1}>
                        <Box
                          className={`small-subtitle primary-text ${['url', 'email', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin', 'phone'].includes(cf.fieldData.fieldType) ? 'link' : ''}`}
                          onClick={(e: SyntheticEvent<Element, Event>) => {
                            const value = contactToBeEdited?.customFields?.[cf._id as string] as string;

                            if (['url', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin'].includes(cf.fieldData.fieldType)) {
                              let url = value;
                              if (!/^https?:\/\//i.test(url)) {
                                url = 'https://' + url; // Prepend 'https://' if needed
                              }
                              window.open(url, "_blank");
                            } else if (cf.fieldData.fieldType === 'email') {
                              window.open(`mailto:${value}`);
                            } else if (cf.fieldData.fieldType === 'phone') {
                              window.open(`tel:${value}`);
                            }
                          }}
                        >
                          {customFieldValue(cf, contactToBeEdited)}
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

          </Grid>
        </Box>
      </Box>
      {(isCustomFieldsLoading || isAccountsLoading) && <Loader />}
    </>
  );
}

export default ContactDetails;
