import React, { useRef, useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import Button from '@material-ui/core/Button';
import '../../sass/main.scss';
import DashboardLayout from 'Layouts/DashboardLayout';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import CalenderIcon from '../../../src/assets/images/icons/meetings.svg';
import MailOutlineIcon from '../../../src/assets/images/mail.svg';
import { createNotification } from 'helpers';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableFooter, TablePagination, Checkbox, } from '@material-ui/core';
import { Box, Grid, Stack } from '@mui/material';
import MoreIcon from '../../../src/assets/images/icons/more.svg';

import SearchIcon from '../../../src/assets/images/icons/search.svg';
import InfoIcon from '../../assets/images/icons/setting-user-Info.svg';
import DeleteIcon from '../../assets/images/icons/setting-user-delete.svg';
import InviteIcon from '../../assets/images/icons/setting-user-mail.svg';
import StarIcon from '../../assets/images/icons/setting-user-star.svg';
import MailIcon from '../../assets/images/icons/email.svg';
import EditIcon from '../../assets/images/icons/edit.svg';
import SearchIconBar from '../../../src/assets/images/searchIco.png';

import accountMultipleOutline from '../../assets/images/icons/account-multiple-outline.svg';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { styled } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import RoundBadge from '../../Components/CustomBadges/RoundBadge';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@mui/material/FormControl';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Radio, RadioGroup } from '@material-ui/core';
import downArrow from '../../../src/assets/images/acor-down-arrow.svg';
import { includes } from 'lodash';
import filterIcon from '../../../src/assets/images/icons/filter_alt.svg';
import filterIconWhite from '../../../src/assets/images/icons/filter_alt_white.svg';
import ClearIcon from '@material-ui/icons/Clear';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import SampleEmail from './sampleEmail';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import DocIcon from '../../../src/assets/images/icons/Doc-icon.svg';
import AddFile from '../../../src/assets/images/icons/attach_file_add.svg';

const Emails = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [value, setValue] = useState('');
  const [cc, setCC] = useState(false);
  const [bcc, setBcc] = useState(false);

  const handleOpenCC = () => {
    setCC(true);
  };
  const handleCloseCC = () => {
    setCC(false);
  };
  const handleOpenBCC = () => {
    setBcc(true);
  };
  const handleCloseBCC = () => {
    setBcc(false);
  };

  // Define toolbar modules with alignment and custom attachment handler
  const modules: QuillToolbarModules = {
    toolbar: {
      container: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }], // Text alignment
        ['link', 'image'], // Default image handler
        ['attachment'], // Custom attachment button
        ['clean'], // Removes formatting
      ],
      handlers: {
        attachment: () => handleAttachment(),
      },
    },
  };

  // Custom handler to upload attachments
  const handleAttachment = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files?.[0];
      if (file) {
        // Handle the file upload logic (e.g., to a server or S3)
        console.log('Selected file:', file);
        // Implement further logic, such as uploading and displaying a link to the file
      }
    };
  };

  return (
    <>
      <DashboardLayout title="Emails" pageTitle="Emails">
        <ThemeProvider theme={theme}>

          <div className="dashboard-containor">
            <div className="container-with-sidebar">
              <h3 className='heading'> Emails </h3>

              <Tabs defaultIndex={activeTabIndex}>

                <div className="project-sidebar">
                  <TabList>
                    <Tab value='1'> <Box py={1} pl={'6px'}><EditOutlinedIcon /></Box> <Link to={'/emails'} >Compose</Link></Tab>
                    <Tab value='2'> <Box py={1} pl={'6px'}><InboxOutlinedIcon /></Box> <Link to={'/emails'} >Inbox</Link></Tab>
                    <Tab value='2'> <Box py={1} pl={'6px'}><DraftsOutlinedIcon /></Box> <Link to={'/emails'} >Drafts</Link></Tab>
                    <Tab value='3'> <Box py={1} pl={'6px'}><SendOutlinedIcon /></Box> <Link to={'/emails'} >Sent</Link></Tab>
                  </TabList>
                </div>

                <div className="container-sidebar-right">
                  <TabPanel value='1'>
                    <Box>
                      <Box pb={0} mt={-5} mb={2} sx={{ borderBottom: '0px solid #EDECF5', }} className="top-filter-area">
                        <Grid container spacing={1}>
                          <Grid item md>
                            {/* <TextField
                              name="Recipients"
                              placeholder={'Recipients'}
                              fullWidth
                              size="small"
                            // value={searchText}
                            // onChange={this.handleChange}
                            /> */}
                            <Box className="subtitle">
                              New Message (From: youremail@domain.com)
                            </Box>
                          </Grid>
                          <Grid item>
                            <Grid container spacing={1.25} className="team-dash-right">
                              <Grid item className="">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  startIcon={<DraftsIcon />}
                                >
                                  Draft
                                </Button>
                              </Grid>
                              <Grid item className="">
                                <Button variant='contained' color="primary" size='small' endIcon={<SendIcon />}>Send</Button>
                              </Grid>



                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="email-composer">
                        <Stack spacing={1} sx={{ height: "100%" }}>
                          <Grid container columnGap={1}>
                            <Grid item xs>
                              <TextField
                                name="Recipients"
                                placeholder={'Recipients'}
                                fullWidth
                                size="small"
                              // value={searchText}
                              // onChange={this.handleChange}
                              />
                            </Grid>
                            <Grid item>
                              {!cc &&
                                <Button onClick={handleOpenCC} style={{ minWidth: 'unset' }} variant='text' color='primary' size='small'>CC</Button>

                              }  </Grid>
                            <Grid item>
                              {!bcc &&
                                <Button onClick={handleOpenBCC} style={{ minWidth: 'unset' }} variant='text' color='primary' size='small'>BCC</Button>
                              }
                            </Grid>

                          </Grid>



                          {/* ---CC--- */}
                          {cc &&
                            <Grid container columnGap={1}>
                              <Grid item xs>
                                <TextField
                                  name="CC"
                                  placeholder={'CC'}
                                  fullWidth
                                  size="small"
                                // value={searchText}
                                // onChange={this.handleChange}
                                />
                              </Grid>
                              <Grid item>
                                <IconButton onClick={handleCloseCC} color='primary' size='small'><CloseIcon /></IconButton>
                              </Grid>
                            </Grid>
                          }


                          {/* ---BCC--- */}
                          {bcc &&
                            <Grid container columnGap={1}>
                              <Grid item xs>
                                <TextField
                                  name="BCC"
                                  placeholder={'BCC'}
                                  fullWidth
                                  size="small"
                                // value={searchText}
                                // onChange={this.handleChange}
                                />
                              </Grid>
                              <Grid item>
                                <IconButton onClick={handleCloseBCC} color='primary' size='small'><CloseIcon /></IconButton>
                              </Grid>
                            </Grid>
                          }


                          <TextField
                            name="Subject"
                            placeholder={'Subject'}
                            fullWidth
                            size="small"
                          // value={searchText}
                          // onChange={this.handleChange}
                          />

                          <ReactQuill
                            theme="snow"
                            value={value}
                            onChange={setValue}
                            modules={modules}
                          />

                          <Grid
                            container
                            spacing={1}
                            className="attachments"
                          >
                            <Grid item>
                              <Box className="attachment-file">
                                <Box display={'flex'} alignItems={'center'}>
                                  <Box className="file-icon">
                                    <img src={DocIcon} alt="file-icon" />
                                  </Box>
                                  <Box>
                                    <Box className="file-name">
                                      File Name 12jisjdc dscisdnccs cskdjcsdk
                                      csdijnb.doc
                                    </Box>
                                    <Box className="file-size">24kb</Box>
                                  </Box>
                                </Box>
                                <Box ml={1} className="options">
                                  <ClearIcon style={{ fontSize: '18px' }} />

                                </Box>
                              </Box>
                            </Grid>
                            <Grid item>
                              <Box className="attachment-file">
                                <Box display={'flex'} alignItems={'center'}>
                                  <Box className="file-icon">
                                    <img src={DocIcon} alt="file-icon" />
                                  </Box>
                                  <Box>
                                    <Box className="file-name">File Name.doc</Box>
                                    <Box className="file-size">24kb</Box>
                                  </Box>
                                </Box>
                                <Box ml={1} className="options">
                                  <ClearIcon style={{ fontSize: '18px' }} />

                                </Box>
                              </Box>
                            </Grid>
                            <Grid item>
                              <Box className="attachment-file upload" onClick={handleAttachment}>
                                <Box display={'flex'} alignItems={'center'}>
                                  <Box className="file-icon" p={1}>
                                    <img src={AddFile} alt="add-file" />
                                  </Box>
                                  <Box>
                                    <Box className="file-name secondary-text">
                                      Attach file
                                    </Box>
                                    {/* <Box className="file-size">10 Mb Max.</Box> */}
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                        </Stack>
                      </Box>
                    </Box>

                  </TabPanel>
                  <TabPanel value='2'>
                    <Box>
                      <Box pb={1} mt={-5} mb={2} sx={{ borderBottom: '1px solid #EDECF5', }} className="top-filter-area">
                        <Grid container spacing={1}>
                          <Grid item md>
                            <Grid container spacing={(1)} alignItems="center" >
                              <Grid item>
                                <Box pl={'15px'}>
                                  <Checkbox color="primary" />
                                </Box>
                              </Grid>
                              <Grid item>
                                <IconButton title='Archive' >
                                  <ArchiveOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton title='Delete' >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton title='Refresh' >
                                  <RefreshOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <Box className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                  {' '}
                                  <div className='options-button'>
                                    <IconButton title='More Options' >
                                      <MoreVertOutlinedIcon />
                                    </IconButton>
                                    {' '}
                                  </div>
                                  <div className="options-list" style={{ right: '10px' }}>
                                    <div>
                                      Option 1
                                    </div>
                                    <hr />
                                    <div>
                                      Option 2
                                    </div>
                                  </div>
                                </Box>
                              </Grid>



                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container spacing={1.25} className="team-dash-right">
                              <Grid item className="">
                                <form >
                                  <TextField
                                    name="searchText"
                                    placeholder={'Search'}
                                    className="search"
                                    size="small"
                                    // value={searchText}
                                    // onChange={this.handleChange}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img alt="" src={SearchIcon} />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <button className='d-none' type="submit">
                                    <img src={SearchIconBar} alt="" />
                                  </button>
                                </form>
                              </Grid>


                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                      <TableContainer className="list-table" style={{ maxHeight: "calc(100vh - 200px)" }}>
                        <Table className="table">
                          <TableBody>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>

                        </Table>
                      </TableContainer>

                    </Box>

                  </TabPanel>
                  <TabPanel value='3'>
                    <Box>
                      <Box pb={1} mt={-5} mb={2} sx={{ borderBottom: '1px solid #EDECF5', }} className="top-filter-area">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item md>
                            <Grid container spacing={(1)} alignItems="center" >

                              <Grid item>
                                <IconButton size='medium' title='Back' >
                                  <KeyboardBackspaceOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton size='medium' title='Archive' >
                                  <ArchiveOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton size='medium' title='Delete' >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton size='medium' title='Refresh' >
                                  <RefreshOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <Box className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                  {' '}
                                  <div className='options-button'>
                                    <IconButton size='medium' title='More Options' >
                                      <MoreVertOutlinedIcon />
                                    </IconButton>
                                    {' '}
                                  </div>
                                  <div className="options-list" style={{ right: '10px' }}>
                                    <div>
                                      Option 1
                                    </div>
                                    <hr />
                                    <div>
                                      Option 2
                                    </div>
                                  </div>
                                </Box>
                              </Grid>



                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container spacing={1} className="team-dash-right">
                              <Grid item>
                                <span>1,417 of 28,317</span>
                              </Grid>
                              <Grid item>
                                <IconButton size='small' title='Previous' >
                                  <NavigateBeforeOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton size='small' title='Next' >
                                  <NavigateNextOutlinedIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box className="email-container" >
                        <Box className="header" pb={2} mb={1} sx={{ borderBottom: '1px solid #EDECF5', }}>
                          <Box className="subject subtitle">
                            Event Reminder
                          </Box>
                          <Stack spacing={1} direction={'row'} alignItems={'center'}>
                            <PersonOutlineOutlinedIcon />
                            <Box><span className='bold'>Sender Name </span>
                              <span>&lt;</span>usersemail@domain.com<span>&gt;</span></Box>
                          </Stack>
                          <Stack spacing={1} direction={'row'} alignItems={'center'}>
                            <WatchLaterOutlinedIcon />
                            <Box>
                              Friday, October 04, 2024 9:51:34 AM
                            </Box>
                          </Stack>
                        </Box>

                        <Box className="email-body">
                          <SampleEmail />
                        </Box>

                      </Box>
                    </Box>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </ThemeProvider>
      </DashboardLayout>
    </>
  );
};

export default Emails;
