export enum EventTypes {
  CONNECT = 'CONNECT',
  DISCONNECT = 'DISCONNECT',
  MESSAGE = 'MESSAGE',
  NEW_CHAT = "NEW_CHAT",
  CHAT_END = "CHAT_END",
  BEFORE_IDLE_DISONNECT="BEFORE_IDLE_DISONNECT",
  ON_IDLE_DISCONNECT="ON_IDLE_DISCONNECT",
  PING = "PING"
}
export enum RoutKeys {
  PING = 'ping',
  CHATBOT = 'chatbot',
}
