import { DropzoneArea } from 'material-ui-dropzone';
import Box from '@mui/material/Box';
import { ContextFile } from 'Redux/Reducers/aiChatbot.reducer';
import { Chip, IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Stack } from '@mui/material';
import { getFileToDownloadAPIForChatBot, uploadAiChatbotFile } from 'Redux/API';
import { useState } from 'react';
import { createNotification } from 'helpers';
import Loader from 'Components/Loader/loader';
import GetAppIcon from '@material-ui/icons/GetApp';

type FilesProps = {
  handleAddNewContextFiles: (newFile: ContextFile) => void;
  handleRemoveContextFiles: (file: string) => void;
  contextFiles: ContextFile[];
  filePath: string;
};

const supportedFileFormats = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];

const containsEmoji = (str: string) => {
  const emojiRegex = /[\p{Emoji_Presentation}\p{Emoji}\uFE0F]/gu;
  return emojiRegex.test(str);
};

function File({
  handleAddNewContextFiles,
  handleRemoveContextFiles,
  filePath,
  contextFiles,
}: FilesProps) {
  const [isLoading, setIsLoading] = useState(false);
  const onDrop = async (files: Array<File>) => {
    const formData = new FormData();
    let fileNameAlreadyExists;
    let validFileFormat;
    let isValidFileSize;
    let fileNameContainsEmoji;

    files.forEach((file) => {
      formData.append('aiChatbotFile', file);
      formData.append('fileName', file.name);

      fileNameContainsEmoji = containsEmoji(file.name)
      fileNameAlreadyExists = contextFiles.some((el) => el.name === file.name);
      let fileType = file.type;
      let fileSize = file.size;
      isValidFileSize = fileSize <= 5242880;
      validFileFormat = supportedFileFormats.some(format => format === fileType);
    });
    if (!isValidFileSize) {
      createNotification("error", "Please upload files with a size limit of less than 5 MB");
      return;
    }
    if (!validFileFormat) {
      createNotification("error", "Please upload a valid file format");
      return;
    }
    if (fileNameAlreadyExists) {
      createNotification("error", "The uploaded file name already exists");
      return;
    }
    if (fileNameContainsEmoji) {
      createNotification("error", "File name contains emoji");
      return;
    }
    setIsLoading(true);
    const resFromUploadedFiles: any = await uploadAiChatbotFile(formData);

    const newFile: ContextFile = {
      keyInS3: resFromUploadedFiles.data.fileInfo.fileInS3,
      name: resFromUploadedFiles.data.fileInfo.name,
      uploadDate: new Date(),
      type: '',
      path: '',
      characterCount: resFromUploadedFiles.data.fileInfo.characterCount,
    };

    handleAddNewContextFiles(newFile);
    setIsLoading(false);
  };

  const removeFile = (fileName: string) => {
    handleRemoveContextFiles(fileName);
  };

  const handleOpenDownload = async (keyInS3: string, fileName: string) => {
    const params = {
      keyInS3: keyInS3,
      fileName: fileName
    };
    const response: any = await getFileToDownloadAPIForChatBot(params);
    if (response.data.success && response.data.url) {
      window.open(response.data.url, "_blank");
    } else {
      createNotification("error", "Something went wrong");
    }

  };

  return (
    <>
      <Box className="tab-content">
        <Box className="tab-title">Files</Box>
        <Box>
          <DropzoneArea
            showPreviews={false}
            showPreviewsInDropzone={false}
            // @ts-ignore
            dropzoneText={
              <>
                <span className="drop-title">
                  Drag & drop files here, or click to select files
                </span>
                <br />{' '}
                <span className="supported-files">
                  Supported File Types: .pdf, .docx, .txt
                </span>
                <br />{' '}
                <span className="supported-files">
                  Please upload files with a size limit of less than 5 MB
                </span>
              </>
            }
            dropzoneClass="dropzone"
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
            maxFiles={0}
            multiple={false}
            showAlerts={false}
            filesLimit={100}
            maxFileSize={52428800000000}
          />
          <Box className="files-instruction" mt={1}>
            If you are uploading a PDF, make sure you can select/highlight the
            text.
          </Box>
          <Box className="file-preview">
            {/* @ts-ignore */}
            <Box class="section-divider">
              <span className="attached-file">Attached files</span>
            </Box>
            <Stack direction={'column'} spacing={1}>
              {contextFiles?.map((el) => {
                return (
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} className="preview-chip" key={el.keyInS3}>
                    <p className='long-text'>{el.name}</p>
                    <Stack spacing={0.5} alignItems={'center'} direction={'row'}>
                      <IconButton
                        color='primary'
                        size='small'
                        onClick={() => handleOpenDownload(el.keyInS3, el.name)}
                      >
                        <GetAppIcon fontSize='small' />
                      </IconButton>

                      <IconButton
                        color='primary'
                        size='small'
                        onClick={() => removeFile(el.name)}
                      >
                        <DeleteOutlineIcon
                          className="delete-icon"
                          fontSize='small'
                          style={{
                            backgroundColor: '#ff0000 !important',
                          }}
                        />
                      </IconButton>
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Box>
        </Box>
      </Box>
      {isLoading && <Loader />}
    </>
  );
}

export default File;
