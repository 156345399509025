import React, { useState, ChangeEvent, useEffect, useRef, SetStateAction, Dispatch } from 'react';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { AIChatbotState, ChatBot, FAQ } from 'Redux/Reducers/aiChatbot.reducer';
import { ButtonGroup } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'theme/theme';
import ImportModal from 'Pages/Opportunity/CommonComponents/ImportModal';
import PaginationFunctional, { handleEndCountForPagination } from 'Pages/ProjectDashboard/Integration/AppIntegration/googleWorkspaceComponents/PaginationFunctional';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import { Actions } from 'Redux/Actions';

type QAProps = {
  FAQs: FAQ[];
  handleCreateQA: (qa: FAQ, page_no: number, limit: number) => void;
  handleRemoveExistingQA: (i: number, faqIdToDelete: string,  page_no: number, limit: number) => void;
  getAiAgentGeneralData: (pageNo?: number, limit?: number) => void;
  showDeleteConfirmationPopup: boolean;
  setShowDeleteConfirmationPopup: Dispatch<SetStateAction<boolean>>
  removeExistingQAFromDB: (faqId: string, page_no: number, limit: number) => Promise<void>
};

type ErrorType = {
  question?: string;
  answer?: string;
};
const QA = ({
  FAQs,
  handleCreateQA,
  handleRemoveExistingQA,
  getAiAgentGeneralData,
  setShowDeleteConfirmationPopup,
  showDeleteConfirmationPopup,
  removeExistingQAFromDB
}: QAProps) => {
  const {totalFAQs} = useSelector((store: any) => store.AIChatbot.chatBot) as ChatBot;
  const initialRender2 = useRef(true);
  const dispatch = useDispatch()
  const [addnewQuestion, setAddnewQuestion] = useState<boolean>(false);
  const [questionToEdit, setQuestionToEdit] = useState<string>('');
  const [answerToEdit, setAnswerToEdit] = useState<string>('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [errors, setErrors] = useState<ErrorType>({} as ErrorType);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  // variables for pagination started
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);
  const [startCount, setStartCount] = useState(1);
  const [endCount, setEndCount] = useState(0);
  // variables for pagination ends
  const [faqIdToDelete, setFaqIdToDelete] = useState<string>('')
  const [faqIdToEdit, setFaqIdToEdit] = useState<string>('')
  const [faqIndexToRemove, setFaqIndexToRemove] = useState<number>(null as unknown as number)
  useEffect(() => {
    if(initialRender2.current){
      initialRender2.current = false
    } else {
      getAiAgentGeneralData(page_no, limit)
    }
  }, [page_no, limit, ]);

  useEffect(() => {
      handleEndCountForPagination(totalFAQs, limit, page_no, setEndCount);
  }, [totalFAQs]);
  
  const handleCreateQuestion = (e: ChangeEvent<HTMLInputElement>) => {
    if (questionToEdit !== '') {
      setQuestionToEdit(e.target.value);
    } else {
      setQuestion(e.target.value);
    }
  };
  const handleCreateAnswer = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (answerToEdit !== '') {
      setAnswerToEdit(e.target.value);
    } else {
      setAnswer(e.target.value);
    }
  };

  const handleAddNewQuestion = () => {
    setQuestionToEdit('');
    setAnswerToEdit('');
    setQuestion('');
    setAnswer('');
    setAddnewQuestion(true);
    setErrors({})
  };

  const handleFindQAToEdit = (i: number, faqId: string) => {
    setQuestionToEdit(FAQs[i].question);
    setAnswerToEdit(FAQs[i].answer);
    setAddnewQuestion(true);
    handleRemoveExistingQA(i, '', page_no, limit);
    setFaqIdToDelete(faqId)
    setFaqIdToEdit(faqId)
  };

  const handleSaveQuestionAnswer = () => {
    if (isValid()) {
      dispatch(Actions.setLoading(true))
      if (questionToEdit !== '' || answerToEdit !== '') {
        let newQA = {
          question: questionToEdit,
          answer: answerToEdit,
          faqIdToEdit: faqIdToEdit
        };
        handleCreateQA(newQA, page_no, limit );
      } else {
        let newQA = {
          question: question,
          answer: answer,
          faqIdToEdit: faqIdToEdit
        };
        handleCreateQA(newQA, page_no, limit);
      }
      setQuestion('');
      setAnswer('');
      setFaqIdToEdit("")

      setAddnewQuestion(false);
    } else {
      dispatch(Actions.setLoading(false))
    }
  };

  const isValid = () => {
    let isValid = true;
    let errorsObj: ErrorType = {} as ErrorType;
    if (questionToEdit) {
      if (!questionToEdit) {
        isValid = false;
        errorsObj.question = 'Question cannot be empty';
      }
      if (!answerToEdit) {
        isValid = false;
        errorsObj.answer = 'Answer cannot be empty';
      }
    } else {
      if (!question) {
        isValid = false;
        errorsObj.question = 'Question cannot be empty';
      }
      if (!answer) {
        isValid = false;
        errorsObj.answer = 'Answer cannot be empty';
      }
      const questionAlreadyExist = FAQs.some(faq => faq.question.trim().toLowerCase() === question.toLowerCase().trim())
      if(questionAlreadyExist){
        isValid = false;
        errorsObj.question = 'Question already exists';
      }
    }

    setErrors(errorsObj);
    return isValid;
  };

  const handleClickOpenImportModal = (doOpenImportModal: boolean) => {
    setOpenImportModal(doOpenImportModal);
  };

  const handleOpenImportModal = (isOpen: boolean) => {
    setOpenImportModal(isOpen);
  };

  const handleShowDeleteConfirmationPopup = (index: number, id: string) => {
    setFaqIdToDelete(id)
    setFaqIndexToRemove(index)
    setShowDeleteConfirmationPopup(true)
  }
  const handleHideDeleteConfirmationPopup = () => {
    setFaqIdToDelete('')
    setShowDeleteConfirmationPopup(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <Box className="tab-content">
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            className="tab-title"
            alignItems={'center'}
          >
            <Box>{`Q&A (${totalFAQs || 0})`}</Box>
            <Button variant='outlined' color="primary" size='small' onClick={() => handleClickOpenImportModal(true)}>
              Import
            </Button>
          </Stack>
          <Box>
            {/* Saved Question */}
            {FAQs?.length
              ? FAQs?.map((faq, i) => {
                  return (
                    <Stack className="qna" spacing={1}>
                      <Box className="question">{faq.question}</Box>
                      <Box className="answer">{faq.answer}</Box>
                      <Box sx={{ marginLeft: '-8px !important' }}>
                        <ButtonGroup>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => handleFindQAToEdit(i, faq._id as string)}
                          >
                            {' '}
                            Edit
                          </Button>
                          <Button
                            variant="text"
                            color="secondary"
                            onClick={() =>{ 
                              handleShowDeleteConfirmationPopup(i, faq._id as string)
                            }}
                          >
                            {' '}
                            Remove
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Stack>
                  );
                })
              : null}

            {/* Add new question answer */}
            {addnewQuestion && (
              <Stack className="qna" spacing={1}>
                <Box component="div" className="inputField">
                  <label>
                    Question
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control small"
                    name="question"
                    placeholder=""
                    value={questionToEdit !== '' ? questionToEdit : question}
                    onChange={handleCreateQuestion}
                  />
                </Box>
                <span className="error_mesage"> {errors.question} </span>
                <Box component="div" className="inputField">
                  <label>
                    Answer
                    <span className="required">*</span>
                  </label>
                  <textarea
                    rows={5}
                    className="form-control small"
                    placeholder=""
                    name="answer"
                    value={answerToEdit !== '' ? answerToEdit : answer}
                    onChange={handleCreateAnswer}
                  />
                </Box>
                <span className="error_mesage"> {errors.answer} </span>
                <Box sx={{ marginLeft: '-8px !important' }}>
                  <ButtonGroup>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleSaveQuestionAnswer}
                    >
                      {' '}
                      Save
                    </Button>
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        setQuestion('');
                        setAnswer('');
                        setAddnewQuestion(false);
                        setQuestionToEdit('');
                        setAnswerToEdit('');
                        removeExistingQAFromDB(faqIdToDelete, page_no, limit)
                        setErrors({})
                      }}
                    >
                      {' '}
                      Remove
                    </Button>
                  </ButtonGroup>
                </Box>
              </Stack>
            )}

            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddNewQuestion}
            >
              Add New Question
            </Button>
          </Box>
        </Box>
        <PaginationFunctional
            startCount={startCount}
            setStartCount={setStartCount}
            endCount={endCount}
            limit={limit}
            setLimit={setLimit}
            page_no={page_no}
            setPage_no={setPage_no}
            totalItemsCount={totalFAQs}
            listItemsFn={()=>getAiAgentGeneralData(page_no, limit)}
          />
      </>
      <ImportModal
        open={openImportModal}
        tab={'question and answers'}
        handleOpen={handleOpenImportModal}
        fetchAllOpportunityData={getAiAgentGeneralData}
      />
      <ConfirmationAlertPopUp
        openModal={showDeleteConfirmationPopup}
        closeModalFunc={handleHideDeleteConfirmationPopup}
        title={'Delete Confirmation!'}
        text={'Are you sure you want to delete it?'}
        confirmationButtonText={'Delete'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={() => handleRemoveExistingQA(faqIndexToRemove, faqIdToDelete, page_no, limit)}
      />
    </ThemeProvider>
  );
};

export default QA;
