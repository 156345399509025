import { Box, Button } from "@material-ui/core";
import { Stack } from "@mui/material";
import { FunctionComponent } from "react";
import WidgetHelper from "./Helper";
import { ComfirmationStyle } from "Redux/Reducers/aiChatbot.reducer";

interface Props {
    comfirmationStyle: ComfirmationStyle;
    onConfirm: Function;
    onCancel: Function;
}
const ConfirmationModal: FunctionComponent<Props> = ({ comfirmationStyle, onConfirm, onCancel }) => {

    return <>{/* ---confirmation-popup--- */}
        < Box className="confirmation-popup"
            bgcolor={WidgetHelper.rgbaToHex(comfirmationStyle?.bgColor)}
        >
            <Stack alignItems={'center'} justifyContent={'center'} p={3} >
                <Box
                    textAlign={'center'}
                    fontSize={`${comfirmationStyle?.font?.size}px`}
                    color={`${WidgetHelper.rgbaToHex(comfirmationStyle?.FontColor)}`}
                    mb={2}
                >
                    {comfirmationStyle?.confirmationText}
                </Box>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Button variant='contained' color='primary' size='small' onClick={() => onConfirm()}
                        style={{
                            backgroundColor: `${WidgetHelper.rgbToHex(comfirmationStyle?.ButtonBgColor)}`,
                            color: `${WidgetHelper.rgbToHex(comfirmationStyle?.ButtonTextColor)}`,
                            fontSize: `${comfirmationStyle?.ButtonFont?.size}px`,
                            // borderTopLeftRadius: `${comfirmationStyle?.ButtonBorderRadius.topLeft}px`,
                            // borderTopRightRadius: `${comfirmationStyle?.ButtonBorderRadius.topRight}px`,
                            // borderBottomRightRadius: `${comfirmationStyle?.ButtonBorderRadius.bottomRight}px`,
                            // borderBottomLeftRadius: `${comfirmationStyle?.ButtonBorderRadius.bottomLeft}px`,
                        }}
                    >
                        {comfirmationStyle?.buttonTrue}
                    </Button>
                    <Button variant='outlined' color='primary' size='small' onClick={() => onCancel()}
                        style={{
                            borderColor: `${WidgetHelper.rgbToHex(comfirmationStyle?.ButtonBgColor)}`,
                            color: `${WidgetHelper.rgbToHex(comfirmationStyle?.ButtonBgColor)}`,
                            fontSize: `${comfirmationStyle?.ButtonFont?.size}px`,
                            // borderTopLeftRadius: `${comfirmationStyle?.ButtonBorderRadius.topLeft}px`,
                            // borderTopRightRadius: `${comfirmationStyle?.ButtonBorderRadius.topRight}px`,
                            // borderBottomRightRadius: `${comfirmationStyle?.ButtonBorderRadius.bottomRight}px`,
                            // borderBottomLeftRadius: `${comfirmationStyle?.ButtonBorderRadius.bottomLeft}px`,
                        }}
                    >
                        {comfirmationStyle?.buttonFalse}
                    </Button>
                </Stack>
            </Stack>
        </Box >
        {/* ---confirmation-popup end--- */}
    </>;
};

export default ConfirmationModal;