import Grid from '@mui/material/Grid';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import closeIcon from '../../../../src/assets/images/icons/close.svg';
import editIcon from '../../../../src/assets/images/icons/edit.svg';
import { AudienceObject, Contact } from 'Redux/Reducers/opportunity/contacts.reducer';
import { CustomField } from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { separateByComma } from 'helpers/common';
import { Account, ContactCf, Project } from 'Redux/Reducers/opportunity/accounts.reducer';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import { useState } from 'react';
import moment from 'moment';
import CustomHTMLTooltip from 'Components/CustomHTMLTooltip';
import { AudienceChild } from 'Redux/Reducers/opportunity/audience.reducer';

type Props = {
  selected: Contact | Account;
  addNew: () => void;
  clearSelectedContactAndCloseViewContactDetailBox: () => void;
  usedFor: 'account' | 'contact';
  customFields: CustomField[];
  parentItem: 'account' | 'opportunity';
};
function ViewContactDetails({
  selected,
  addNew,
  usedFor,
  clearSelectedContactAndCloseViewContactDetailBox,
  customFields,
  parentItem
}: Props) {
  const [openUnlinkConfirmationPop, setOpenUnlinkConfirmationPop] = useState<boolean>(false);
  const customFieldValue = (cf: CustomField) => {
    const customFields = selected?.customFields;
    const value1 = selected?.customFields?.[cf._id as string] as any | any[];
    if (cf.fieldData.fieldType === 'contact') {
      let selectedData = customFields?.[
        cf._id as string
      ] as ContactCf;
      let fName = selectedData?.firstName || '';
      let mName = selectedData?.middleName || '';
      let lName = selectedData?.lastName || '';

      return fName ? `${fName} ${mName} ${lName}` : '';
    } else if (cf.fieldData.fieldType === 'boolean') {
      return customFields?.[cf._id as string]?.toString() === "true" ? "True" : "False";
    } else if (cf.fieldData.fieldType === 'user' && value1?.length > 0) {
      return (
        <Stack direction={'row'} spacing={1}>
          {/* Display the first email (or value) */}
          <span
            className={`long-text ${cf.fieldData.fieldType === 'user' && 'link'}`}
            style={{ maxWidth: '80%' }}
            onClick={() => {
              if (cf.fieldData.fieldType === 'user' &&  value1 && value1?.length > 0) {
                window.open(`mailto:${ value1 && value1[0]?.email || value1[0]}`);
              }
            }}
          >
            {cf.fieldData.fieldType === 'user'
              ? value1[0]?.email || value1[0]
              : value1[0]}
          </span>

          {/* If there are more emails, display a popup with the rest */}
          { value1 && value1?.length > 1 && (
            <div className="detail-popup">
              <span className="count">+{ value1 && value1?.length - 1}</span>
              <div className="info">
                {showRestOfPages(value1).map((cfValue: string | { email: string; }, i: number) => {
                  const isObject = typeof cfValue === 'object' && cfValue !== null;
                  const displayValue = isObject ? (cfValue as { email: string; }).email : cfValue;

                  return (
                    <div
                      key={i}
                      className={cf.fieldData.fieldType === 'user' ? 'link' : ''}
                      style={{ zIndex: 999999 }}
                      onClick={() => {
                        if (cf.fieldData.fieldType === 'user' && isObject) {
                          window.open(`mailto:${displayValue}`);
                        }
                      }}
                    >
                      <p>{displayValue}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Stack>
      );
    } else {
      return cf.fieldData.fieldType === 'date'
        && customFields?.[cf._id as string]
        ? moment(customFields?.[cf._id as string] as string).format(
          'YYYY-MM-DD',
        )
        : customFields?.[cf._id as string];
    }
  };

  const showRestOfPages = (cfs: (string | { email: string; })[]) => {
    return cfs?.slice(1, cfs.length);
  };

  const showRestOfGroups = (groups: AudienceChild[]) => {
    let groupsLength = groups.length;
    return groups.slice(1, groupsLength);
  };


  const handleOpenUnlinkSelectedConfPopup = () => {
    setOpenUnlinkConfirmationPop(true);
  };

  const closeUnlinkConfirmationPop = () => {
    setOpenUnlinkConfirmationPop(false);
  };

  const showRestOfProjects = (projects: Project[]) => {
    let projectsLength = projects.length;
    return projects.slice(1, projectsLength);
  };

  const showRestOfAudience = (audience: Contact[]) => {
    let audienceLength = audience.length;
    return audience.slice(1, audienceLength);
  };
  return (
    Object.keys(selected) && Object.keys(selected).length > 0 &&
    <Box className="account-details contacts-details" mt={2}>

      <Stack
        mb={2}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box className="small-subtitle bold primary-text">
          {usedFor === 'contact'
            ? 'Contact'
            : usedFor === 'account'
              ? 'Account'
              : ''}
        </Box>
        <Stack direction={'row'} spacing={1}>
          <IconButton size="small" onClick={addNew}>
            <img src={editIcon} width="16" />
          </IconButton>
          <IconButton size="small" onClick={handleOpenUnlinkSelectedConfPopup}>
            <img src={closeIcon} width="16" />
          </IconButton>
        </Stack>
      </Stack>


      <Grid container spacing={3}>

      <Grid item xs={12} sm={6} lg={4}>
          <Grid container>
            <Grid item xs={12}>
              <Box className="small-subtitle bold primary-text">Eijent Id:</Box>
            </Grid>
            <Grid item xs={12} className="">
                <Box className="small-subtitle primary-text">
                  {usedFor === 'account'
                    ? (selected as Account)?.uniqueEijentId ?? '-'
                    : usedFor === 'contact'
                      ? (selected as Contact)?.uniqueEijentId ?? '-'
                      : '-'}
                </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Grid container>
            <Grid item xs={12}>
              <Box className="small-subtitle bold primary-text">Name:</Box>
            </Grid>
            <Grid item xs={12} className="">
              <Box className="small-subtitle primary-text ">
                {usedFor === 'account'
                  ? (selected as Account).name
                  : usedFor === 'contact'
                    ? ((selected as Contact)?.firstName
                      ? (selected as Contact)?.firstName
                      : '') +
                    ' ' +
                    ((selected as Contact)?.middleName
                      ? (selected as Contact)?.middleName
                      : '') +
                    ' ' +
                    ((selected as Contact)?.lastName
                      ? (selected as Contact)?.lastName
                      : '')
                    : '-'}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {usedFor === 'contact' && (
          <>
          <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">State:</Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className="small-subtitle primary-text">
                      {selected && selected.state && selected.state.length > 0
                        ? selected.state[0].name
                        : '-'}
                    </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">Email:</Box>
                </Grid>
                <Grid item xs={12} >

                  {(selected as Contact)?.email ? (
                    <Box className="small-subtitle primary-text link">
                      {(selected as Contact)?.email}
                    </Box>
                  ) : (
                    <Box className="small-subtitle primary-text">-</Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Contact Groups:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display={'flex'} className="detail-popup">
                    {Array.isArray((selected as Contact)?.audience) && (selected as Contact)?.audience.length > 0 ? (
                      <Box
                        sx={{ maxWidth: '140px' }}
                        className="long-text"
                      >
                        {selected.audience[0]?.audienceName}
                      </Box>
                    ) : (
                      '--'
                    )}

                    {Array.isArray((selected as Contact)?.audience) &&
                      (selected as Contact)?.audience.length > 1 && (
                        <CustomHTMLTooltip
                          placement={'bottom-end'}
                          interactive
                          arrow
                          title={
                            <Box
                              className="tooltip-popup"
                              width={200}
                            >
                              {showRestOfAudience(
                                selected.audience,
                              ).map((data, i) => {
                                return (
                                  <Box
                                    sx={{ display: 'flex' }}
                                    justifyContent={'space-between'}
                                  >
                                    <Box
                                      component={'p'}
                                      className={'long-text'}
                                      maxWidth={'170px'}
                                    >
                                      {data?.audienceName}
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>
                          }
                        >
                          <Box ml={1}>
                            <span className="count">
                              +
                              {Array.isArray((selected as Contact)?.audience)
                                ? selected.audience.length - 1
                                : 0}
                            </span>
                          </Box>
                        </CustomHTMLTooltip>
                      )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>


        )}

        {usedFor === 'account' && (
          <>
           <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">State:</Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className="small-subtitle primary-text">
                      {selected && selected.state && selected.state.length > 0
                        ? selected.state[0].name
                        : '-'}
                    </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">Source:</Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text">
                    {(selected as Account)?.source}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Projects:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span className="long-text" style={{ maxWidth: '80%' }}>
                      {' '}
                      {(selected as Account)?.projectIds &&
                        (selected as Account)?.projectIds?.length > 0
                        ? (selected as Account)?.projectIds[0]?.eventName
                        : ''}
                    </span>
                    {(selected as Account)?.projectIds &&
                      (selected as Account)?.projectIds?.length > 1 && (
                        <div className="detail-popup">
                          <span className="count">
                            +{(selected as Account)?.projectIds.length - 1}
                          </span>
                          <div className="info">
                            {showRestOfProjects(
                              (selected as Account)?.projectIds,
                            ).map((project: Project, i: number) => {
                              return (
                                <div key={i}>
                                  <p>{project.eventName}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </>
          )
        }


        {Array.isArray(customFields) &&
          customFields.length > 0 &&
          customFields.map((cf) => {
            return (
              <Grid item xs={12} sm={6} lg={4} key={cf._id}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box className="small-subtitle bold primary-text">
                      {cf.fieldData.fieldName}:
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="small-subtitle primary-text">
                      <>
                        {customFieldValue(cf)}
                      </>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}

      </Grid>


      <ConfirmationAlertPopUp
        openModal={openUnlinkConfirmationPop}
        closeModalFunc={closeUnlinkConfirmationPop}
        title={`Unlink ${usedFor}!`}
        text={`Are you sure you want to unlink the ${usedFor} from the ${parentItem}?`}
        confirmationButtonText={'Yes'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={clearSelectedContactAndCloseViewContactDetailBox}
      />

    </Box>
  );
}

export default ViewContactDetails;
