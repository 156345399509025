import React, { FunctionComponent, useEffect, useRef, useState, useCallback } from 'react';
import ChatWidget, { Message, WidgetActions } from './Widget';
import { EventTypes, RoutKeys } from 'constants/enums/EventTypes';
import ChatHelpers from './helpers';

interface Props {
  agentId?: string;
  widgetStyle: string;
}
const WidgetContainer: FunctionComponent<Props> = ({ agentId, widgetStyle }) => {
  const widgetRef = useRef<WidgetActions>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [activeChatId, setActiveChatId] = useState<string>('');
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [botStyle, setBotStyle] = useState<any>(null);
  const pingInterval = useRef<number | null>(null);
  const [disableSend, setDisableSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  // const pingToServer = useCallback((socket: WebSocket) => {
  //   if (socket.readyState === WebSocket.OPEN) {
  //     const payload = {
  //       action: RoutKeys.CHATBOT,
  //       eventType: EventTypes.PING,
  //     };
  //     socket.send(JSON.stringify(payload));
  //   } else {
  //     console.error('WebSocket not available for ping');
  //   }
  // }, []);

  const onConnect = useCallback(
    async (event: Event, socket: WebSocket, chatId: string | null) => {
      console.log('Connected ', chatId, socket);

      if (socket.readyState === WebSocket.OPEN) {
        const payload = {
          action: RoutKeys.CHATBOT,
          eventType: EventTypes.CONNECT,
          chatbotId: agentId,
          service: 'chat',
          chatId: chatId,
          message: '',
        };
        socket.send(JSON.stringify(payload));

        setIsConnected(true);
        // if (pingInterval.current !== null) {
        //   clearInterval(pingInterval.current);
        // }
        // pingInterval.current = window.setInterval(() => {
        //   pingToServer(socket);
        // }, 300000); // % minutes interval
      } else {
        console.error('WebSocket not open during connect');
      }
    },
    [agentId, activeChatId]
  );

  const updateHandler = useCallback(async (update: any) => {
    console.info("Update received ", update);
    const chat = update.connection;
    if (chat && chat.chatId) {
      console.log("Chat id to store : ", chat.chatId);
      localStorage.setItem('illumeet_chat_id', chat.chatId);
      setActiveChatId(chat.chatId);
    }
    if (update.botStyle) {
      setBotStyle(update.botStyle);
      if (chat && chat.messages?.length) {
        const sortedMessages = chat.messages
          .filter((m: any) => m._id !== undefined)
          .map((mes: any) => ({
            _id: mes._id,
            sender: mes.sender,
            text: mes.text,
            timetamp: mes.timetamp,
            index: mes.index,
          }));

        const newMessages = ChatHelpers.splitMessagesIntoParagrphs(sortedMessages);

        setMessages(newMessages);
      }
      else if (update.botStyle?.initialMessages?.[0]) {
        setMessages([
          {
            sender: 'assistant',
            text: update.botStyle?.initialMessages?.[0],
            id: '000000000',
            timetamp: new Date().toString()
          }
        ]);
      }
    }
  }, []);
  const chunks: any[] = [];
  const handleChunkedMessage = useCallback((data: any) => {
    const messageChunk = {
      chunk: data.message,
      index: data.data.index,
      id: data.data?.messageId,
    };

    console.log(data.data?.messageId, data.message);
    chunks.push(messageChunk);

    const fullMessage = chunks
      .filter(chunk => chunk.id === data.data?.messageId)
      //  .sort((a, b) => a.index - b.index)
      .map(chunk => chunk.chunk)
      .join('');


    if (fullMessage) {
      widgetRef.current?.updateMessageById(data.data?.messageId, fullMessage);
    } else {
      console.error('Incomplete message data received', data);
    }
  }, []);

  const onMessage = useCallback(
    async (event: MessageEvent, isReconnect: boolean = false) => {
      setLoading(false);
      const data = JSON.parse(event.data);
      if (!data?.data?.type) {
        setError("Sever Error");
        console.error('Invalid response type', data);
        return;
      }
      switch (data.data.type) {
        case 'error':
          setError("Sever Error");
          console.error('Message Error:', data);
          setDisableSent(false);
          setError(data.message);
          break;

        case 'update':
          if (isReconnect) {
            break;
          }
          await updateHandler(data.data.update);
          break;

        case 'ping':
          console.log(new Date().toLocaleString(), ':: Connection refreshed via ping');
          break;

        default:
          if (data.message === "||$End#||") {
            setDisableSent(false);
            break;
          }
          handleChunkedMessage(data);
          break;
      }
    },
    [handleChunkedMessage, updateHandler]
  );


  const initializeSocketService = useCallback(
    async (chatId: string | null, isReconnect: boolean = false) => {

      const connectWebSocket = (): Promise<WebSocket | null> =>
        new Promise<WebSocket | null>((resolve, reject) => {
          //  if (!agentId || reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) return reject("Max reconnect attempts reached.");

          const webSocketUrl = process.env.REACT_APP_CHATBOT_WEBSOCKET_URL || '';
          const newSocket = new WebSocket(webSocketUrl);

          newSocket.onopen = async (e) => {
            console.log('WebSocket opened:', e);
            await onConnect(e, newSocket, chatId);
            //  resolve(newSocket); // Resolve the promise when the connection is established
          };

          newSocket.onmessage = async (e: any) => {
            await onMessage(e, isReconnect);
            resolve(newSocket);
          };

          newSocket.onerror = (error) => {
            console.error('WebSocket Error:', error);
            setDisableSent(false);
            setError("Connection Error");
            reject(error); // Reject the promise on error
          };

          newSocket.onclose = (e) => {
            console.log('WebSocket closed:', e);
            setIsConnected(false);
            //    setError("Connection Lost.");
            // if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
            //   const backoffDelay = Math.min(RECONNECT_BASE_DELAY * Math.pow(2, reconnectAttempts), 30000); // Max delay: 30 seconds
            //   reconnectAttempts += 1;

            //   setTimeout(() => {
            //     console.log(`Reconnecting... attempt ${reconnectAttempts}`);
            //     connectWebSocket().catch((err) => reject(err)); // Try reconnecting, propagate error if necessary
            //   }, backoffDelay);
            // } else {
            //   console.error('Max reconnect attempts reached.');
            //   reject("Max reconnect attempts reached.");
            // }
          };

          setSocket(newSocket);
        });

      return await connectWebSocket(); // Await the connection attempt
    },
    [agentId, onMessage]
  );

  useEffect(() => {
    const chatId = localStorage.getItem('illumeet_chat_id');
    if (chatId) {
      setActiveChatId(chatId);
    }

    initializeSocketService(chatId);

    return () => {
      if (socket) {
        console.log("unloading widget");
        socket.close();
      }
      if (pingInterval.current !== null) {
        clearInterval(pingInterval.current);
        pingInterval.current = null;
      }
    };
  }, [initializeSocketService]);


  const startNewChat = useCallback(() => {
    localStorage.setItem('illumeet_chat_id', "");
    setActiveChatId("");
    if (socket) {
      socket.close();
    }
    initializeSocketService(null);
  }, [initializeSocketService]);


  const onUssrMessage = useCallback(
    async (text: string, sender: 'user' | 'assistant', id: string = '') => {
      setError("");
      console.info("chat id while sending messgae: ", activeChatId);
      let webSocket = socket;
      if (!webSocket || webSocket.readyState === webSocket.CLOSED) {
        console.info("Connection closed, reconnecting....");
        webSocket = await initializeSocketService(activeChatId, true);
        if (socket && socket.readyState === WebSocket.OPEN) {
          console.info("Connected.");
        } else {
          console.error("Reconnection failed");
        }
      }
      console.log("SOCKET= ", webSocket?.OPEN);
      if (webSocket && webSocket.readyState === WebSocket.OPEN && text.length) {
        const payload = {
          action: RoutKeys.CHATBOT,
          eventType: EventTypes.MESSAGE,
          chatbotId: agentId,
          service: 'chat',
          chatId: activeChatId,
          message: text,
        };
        webSocket.send(JSON.stringify(payload));
        setLoading(true);
        setDisableSent(true);
      } else {
        setError("Connection not open or available");
        console.error('WebSocket connection not open or available.');
      }
    },
    [agentId, activeChatId]
  );

  // const onWidgetToggle = useCallback(
  //   (isOpen: boolean) => {
  //     if (!isOpen && (!socket || socket.readyState === WebSocket.CLOSED)) {
  //       initializeSocketService(activeChatId);
  //     } else if (socket) {
  //       socket.close();
  //     }
  //   },
  //   [activeChatId, initializeSocketService]
  // );
  return (
    <div>
      {/* <button onClick={() => {
        if (socket) {
          socket.close();
        }
      }}>
        DISCONNECT
      </button> */}
      {botStyle && (
        <ChatWidget
          ref={widgetRef}
          loading={loading}
          error={error}
          widgetStyle={widgetStyle}
          disableSendButton={disableSend}
          messages={messages}
          onUssrMessage={onUssrMessage}
          onRefresh={startNewChat}
          //   onToggle={onWidgetToggle}
          chatInterface={botStyle}
        />
      )}
    </div>
  );
};

export default WidgetContainer;


