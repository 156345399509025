/**
 * Component for managing accounts.
 * @description This component displays a list of accounts with various functionalities like search, sorting, deletion, and adding new accounts.
 * It also provides options to manage custom fields associated with accounts.
 *
 * @component
 * @param {ProjectsList} projectsList - List of projects.
 * @returns {JSX.Element} - Returns the JSX for the Accounts component.
 */

import React, { SyntheticEvent, useEffect, useState, useRef, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Grid from '@mui/material/Grid';
import { theme } from '../../../theme/theme';
import MoreIcon from '../../../../src/assets/images/icons/more.svg';
import EditIcon from '../../../../src/assets/images/icons/edit.svg';
import SearchIcon from '../../../../src/assets/images/icons/search.svg';
import '../../../sass/main.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  Account,
  AccountsState,
  ContactCf,
  CustomFields,
  Project,
  Source,
} from 'Redux/Reducers/opportunity/accounts.reducer';
import { Actions } from 'Redux/Actions';
import PaginationFunctional, {
  handleEndCountForPagination,
} from 'Pages/ProjectDashboard/Integration/AppIntegration/googleWorkspaceComponents/PaginationFunctional';
import Loader from 'Components/Loader/loader';
import ManageCustomFields from '../CustomFields/ManageCustomFields';
import {
  CustomField,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { ProjectsList } from 'Pages/AIChatbot/Tabs/General/GeneralDashboard';
import { useHistory, useLocation } from 'react-router';
import { Stack, Box } from '@mui/material';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import { getSystemLevelPermissions, rgbToHex2 } from '../../../helpers/common';
import OpportunityAccountsAPI from 'Redux/API/opportunity/opportunityAccountsAPI';
import ImportModal from '../CommonComponents/ImportModal';
import AccountView from './AccountView';
import AddOrEditAccount from './AddOrEditAccount';
import { createNotification } from 'helpers';
import { formatDateField } from 'helpers/common';
import CustomFieldFilterDropDown from 'Common/CustomFieldFilterDropDown';
import FilterByDraft from '../Common/FilterByDraft';
import FilterByLastImported from '../Common/FilterByLastImported';
import CustomTooltip from 'Components/CustomHTMLTooltip';
import FilterDropDown from 'Common/FilterDropDown';
import { sourceFilterArr } from '../Opportunity/Opportunity';
import ViewLayout from '../Common/ViewLayout';
import { OpportunityAccountsContext } from 'Contexts/OpportunityAccountsProvider';
import ManageStates from '../Opportunity/States/ManageStates';
import {
  OpportunityStates as OpportunityDynamicStates,
  OpportunityStates_State
} from 'Redux/Reducers/opportunity/opportunityStates.reducer';

export type FetchAccountsQueryParams = {
  pageNo: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
  searchText: string;
  from: string;
};

type AccountsProps = {
  projectsList: ProjectsList;
  handleTabChange: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
};

export type SxProps = {
  backgroundColor: string;
  boxShadow: string;
  borderRadius: number;
  marginTop: number;
};

export default function Accounts({ handleTabChange }: AccountsProps) {
  const opportunityAccountsContext = useContext(OpportunityAccountsContext);
  if (!opportunityAccountsContext) {
    throw new Error(
      'Accounts must be used within an opportunityAccountsContextProvider',
    );
  }

  const {
    searchText,
    setSearchText,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    filterByCustomField,
    setFilterByCustomField,
    filterByStatus,
    setFilterByStatus,
    filterByLastImported,
    setFilterByLastImported,
    filterBySource,
    setFilterBySource,
  } = opportunityAccountsContext;

  const {
    opportunityStates: opportunityDynamicStates,
    fetchAllOpportunityStateSuccess,
  } = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;
  const history = useHistory();
  const [allowed, setAllowed] = useState<any>({});
  const location = useLocation<any>();
  const currentPath = location.pathname.split('/').at(2);
  const dispatch = useDispatch();
  const {
    accounts,
    totalCount,
    loading,
    createOpportunityAccountSuccess,
    deleteOpportunityAccountSuccess,
    fetchAllOpportunityAccountsSuccess,
    isLoading: isAccountsLoading,
    filterCount,
  } = useSelector((state: any) => state.OpportunityAccounts) as AccountsState;
  const accountState = useSelector(
    (state: any) => state.OpportunityAccounts,
  ) as AccountsState;

  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;
  const anchorRef = useRef(null);
  const anchorRefAction = useRef(null);
  const [openSettingsDropdown, setOpenSettingsDropdown] = useState(false);
  const [openActionDropDown, setOpenActionDropdown] = useState(false);
  const [searchTextTemp, setSearchTextTemp] = useState<string>('');
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [showManageCustomFields, setShowManageCustomFields] =
    useState<boolean>(false);
  const [accountToBeEdited, setAccountToBeEdited] = useState<
    Account | undefined
  >({} as Account);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
    const uniqueEijentId = location.pathname.split('/').at(-1);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const initialRender = useRef(true);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [showManageStates, setShowManageStates] = useState<boolean>(false);
  const [dynamicStates, setDynamicStates] = useState<OpportunityDynamicStates>([]);
  const [filterByState, setFilterByState] = useState<string[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [accountData, setAccountData] = useState<Account[]>([]);  // Account[]

  const queryParams: FetchAccountsQueryParams = {
    pageNo,
    limit,
    sortBy,
    sortOrder,
    searchText,
    from: 'viewAccount',
  };

  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
    fetchAllOpportunityStates();
    fetchAllOpportunityCustomField();
    if(currentPath !== "accountview"){
    fetchAllTheActiveUsers();
    }
  }, []);

  useEffect(() => {
    if (createOpportunityAccountSuccess) {
      setSelectedCheckboxes([]);
    }
    if (deleteOpportunityAccountSuccess) {
      setSelectedCheckboxes([]);
    }
    if (fetchAllOpportunityAccountsSuccess) {
     setAccountData(accounts);
    }
  }, [createOpportunityAccountSuccess, deleteOpportunityAccountSuccess,fetchAllOpportunityAccountsSuccess]);

  useEffect(() => {
    if (fetchAllOpportunityStateSuccess) {
      setDynamicStates(opportunityDynamicStates);
    }
  }, [fetchAllOpportunityStateSuccess]);

  useEffect(() => {
    if(currentPath === "accounts"){
    fetchAllOpportunityAccounts();
    }
  }, [pageNo, limit, sortBy, sortOrder, searchText, filterByCustomField, filterByStatus, filterByLastImported, filterBySource, filterByState,currentPath]);

  useEffect(() => {
    if (location.state) {
      if (location.state.comingFromOtherTab === true) {
        handleAddOrUpdateNewAccount(location.state.account, true);
      } else {
        setSearchText(location.state as string);
        setSearchTextTemp(location.state as string);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      handleEndCountForPagination(totalCount, limit, pageNo, setEndCount);
    }
  }, [totalCount]);

  const fetchOpportunityAccount = async () => {
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    
    try {
      setIsloading(true)
      const res = await opportunityAccountsAPI.fetchOpportunityAccount({
        uniqueEijentId: uniqueEijentId
      });
    if(res.data.account){
      setShowViewModal(true);
      setAccountToBeEdited(res.data.account);
      setIsloading(false)
    }else{
      setIsloading(false)
    }
    } catch (error) {
      console.error("Failed to fetch opportunity account:", error);
    }
  };
  
  useEffect(() => {
   
    if(currentPath === "accountview"){
      fetchOpportunityAccount()
    }
  }, [uniqueEijentId,showAddNew]);

  useEffect(() => {
    
     if (currentPath === "accountview" && accountState.updateOpportunityAccountSuccess) {
      setShowAddNew(false);
      fetchOpportunityAccount()
    }
  }, [accountState.updateOpportunityAccountSuccess]);

  /**
   * Fetches all custom fields related to the current tab type.
   * Dispatches an action to fetch custom fields based on the current tab type.
   * @function fetchAllOpportunityCustomField
   * @description Fetches all custom fields related to the current tab type.
   * @return {void}
   */
  const fetchAllOpportunityCustomField = () => {
    const currentTab = location.pathname.split('/').at(-1);
    const data = {
      customFieldType: "accounts",
      from: 'viewAccount',
    };
    dispatch(Actions.fetchAllOpportunityCustomFieldRequest(data));
  };

  const fetchAllTheActiveUsers = () => {
    try {
      dispatch(Actions.getActiveUsersForCompanyRequest({}));
    } catch (error) {
      console.log('error', error);
    }
  };

  /**
   * Fetches all opportunity accounts based on the provided query parameters.
   * Dispatches an action to fetch opportunity accounts.
   * @function fetchAllOpportunityAccounts
   * @description Fetches all opportunity accounts based on the provided query parameters.
   * @return {void}
   */
  const fetchAllOpportunityAccounts = () => {
    const bodyParamsForfiltering = {
      filterByCustomField,
      filterByStatus,
      filterByLastImported,
      filterBySource,
      filterByState
    };

    const data = {
      queryParams,
      bodyParams: bodyParamsForfiltering,
    };
    dispatch(Actions.fetchAllOpportunityAccountsRequest(data));
  };

  /**
   * Toggles the visibility of the settings dropdown.
   * Updates the state to toggle the visibility of the settings dropdown.
   * @function handleToggleSettings
   * @description Toggles the visibility of the settings dropdown.
   * @return {void}
   */
  const handleToggleSettings = () => {
    setOpenSettingsDropdown((prev) => !prev);
  };

  /**
   * Toggles the visibility of the action dropdown.
   * Updates the state to toggle the visibility of the action dropdown.
   * @function handleToggleAction
   * @description Toggles the visibility of the action dropdown.
   * @return {void}
   */
  const handleToggleAction = () => {
    setOpenActionDropdown((prevOpen) => !prevOpen);
  };

  /**
   * Closes both the settings and action dropdowns.
   * Updates the state to close both the settings and action dropdowns.
   * @function handleCloseDropdowns
   * @description Closes both the settings and action dropdowns.
   * @return {void}
   */
  const handleCloseDropdowns = () => {
    setOpenSettingsDropdown(false);
    setOpenActionDropdown(false);
  };

  /**
   * Handles the keydown event for the list.
   * Closes the settings dropdown if the Tab key is pressed.
   * @function handleListKeyDown
   * @param {React.KeyboardEvent<HTMLUListElement>} event - The keyboard event object.
   * @description Handles the keydown event for the list.
   * @return {void}
   */
  function handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
    if (event.key === 'Tab') {
      setOpenSettingsDropdown(false);
    }
  }

  /**
   * Handles the change event for the search text input.
   * Updates the search text temporary state.
   * @function handleSearchText
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event object.
   * @description Handles the change event for the search text input.
   * @return {void}
   */
  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchTextTemp(e.target.value);
  };

  /**
   * Handles the form submit event for searching.
   * Updates the search text state and resets pagination and selected checkboxes.
   * @function handleSearchTextSubmit
   * @param {React.FormEvent<HTMLFormElement>} e - The form submit event object.
   * @description Handles the form submit event for searching.
   * @return {void}
   */
  const handleSearchTextSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchText(searchTextTemp);
    setPageNo(1); // pagination
    setStartCount(1); // pagination
    setSelectedCheckboxes([]);
  };

  /**
   * Handles the checkbox selection event.
   * Updates the selected checkboxes state based on the checkbox status.
   * @function handleSelectCheckBox
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e - The mouse event object.
   * @param {string} accountId - The ID of the account.
   * @description Handles the checkbox selection event.
   * @return {void}
   */
  const handleSelectCheckBox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    accountId: string,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, accountId]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((id) => id !== accountId),
      );
    }
  };

  /**
   * Handles the select all checkboxes event.
   * Updates the selected checkboxes state to select or deselect all checkboxes.
   * @function handleSelectAllCheckboxes
   * @param {React.MouseEvent<HTMLButtonElement>} e - The mouse event object.
   * @description Handles the select all checkboxes event.
   * @return {void}
   */
  const handleSelectAllCheckboxes = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      let arr: string[] = [];
      Array.isArray(accountData) && accountData.length > 0 && accountData.forEach((account) => arr.push(account._id));
      setSelectedCheckboxes(arr);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  /**
   * Handles the delete action for selected accounts.
   * Dispatches an action to delete selected accounts.
   * @function handleDelete
   * @description Handles the delete action for selected accounts.
   * @return {void}
   */
  const handleDelete = () => {
    const data = {
      bodyParams: {
        ids: selectedCheckboxes,
      },
      queryParams: queryParams,
    };
    dispatch(Actions.deleteOpportunityAccountRequest(data));
    handleCloseDropdowns();
    closeDeleteConfirmationModel();
  };

  /**
   * Handles the sorting event for the accounts table.
   * Updates the sort criteria and resets selected checkboxes.
   * @function handleSorting
   * @param {string} sort_by - The field to sort by.
   * @description Handles the sorting event for the accounts table.
   * @return {void}
   */
  const handleSorting = (sort_by: string) => {
    setSortBy(sort_by);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSelectedCheckboxes([]);
  };

  /**
   * Handles the addition or update of a new account.
   * Sets the account to be edited if in edit mode and shows the add new modal.
   * @function handleAddOrUpdateNewAccount
   * @param {Account} account - The account data.
   * @param {boolean} isBeingEdited - Indicates if the account is being edited.
   * @description Handles the addition or update of a new account.
   * @return {void}
   */
  const handleAddOrUpdateNewAccount = (
    account?: Account,
    isBeingEdited: boolean = false,
  ) => {
    console.log("account---->",account)
    console.log("account---->isBeingEdited",isBeingEdited)
    console.log("account----> location", location)
    // if (isBeingEdited && allowed['editAccount']) {
    if (isBeingEdited) {
      setAccountToBeEdited(account);
      location.state?.comingFromOtherTab
        ? setShowAddNew(true)
        : setShowViewModal(true);
        history.push({
          pathname: `/opportunity/accountview/${account?.uniqueEijentId}`,
        });
    } else if (!isBeingEdited) {
      setAccountToBeEdited(account);
      setShowAddNew(true);
    }
  };

  /**
   * Opens the manage custom fields modal.
   * Sets the state to show the manage custom fields modal.
   * @function handleOpenManageCustomFields
   * @description Opens the manage custom fields modal.
   * @return {void}
   */
  const handleOpenManageCustomFields = () => {
    setShowManageCustomFields(true);
  };

  /**
   * Closes the manage custom fields modal.
   * Sets the state to hide the manage custom fields modal.
   * @function handleCloseManageCustomFields
   * @description Closes the manage custom fields modal.
   * @return {void}
   */
  const handleCloseManageCustomFields = () => {
    setShowManageCustomFields(false);
  };

  /**
   * Resets the add new modal for account.
   * Sets the account to be edited to an empty object.
   * @function resetAddNewModalAccount
   * @description Resets the add new modal for account.
   * @return {void}
   */
  const resetAddNewModalAccount = () => {
    setAccountToBeEdited({} as Account);
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const handleOpenDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(true);
  };

  const handleExport = async (onlyExportSelected: boolean) => {
    setIsloading(true)
    type exportData = {
      queryParams: FetchAccountsQueryParams;
      bodyParams?: {
        ids?: string[];
        timezone: string;
      };
    };
    let data: exportData = {
      queryParams,
      bodyParams: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
    if (onlyExportSelected) {
      data = {
        queryParams,
        bodyParams: {
          ids: selectedCheckboxes,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      };
    }

    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    // @ts-ignore
    const res = await opportunityAccountsAPI.export(data);
    if (res.data.csvData) {
      const url = window.URL.createObjectURL(new Blob([res?.data?.csvData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Accounts.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
    setIsloading(false)
  };

  const handleClickOpenImportModal = (doOpenImportModal: boolean) => {
    setOpenImportModal(doOpenImportModal);
  };

  const handleOpenImportModal = (isOpen: boolean) => {
    setOpenImportModal(isOpen);
  };

  const showRestOfPages = (cfs: (string | { email: string })[]) => {
    return cfs?.slice(1, cfs.length);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
    history.push({
      pathname: `/opportunity/accounts`,
       // Pass the fieldData as state
    });
  };

  const showRestOfProjects = (projects: Project[]) => {
    let projectsLength = projects.length;
    return projects.slice(1, projectsLength);
  };

  const handleOpenManageStates = () => {
    setShowManageStates(true);
  };

  const handleCloseManageStates = () => {
    setShowManageStates(false);
  };

  const fetchAllOpportunityStates = () => {
    const data = {
      opportunityStateType: "accounts"
    };
    dispatch(Actions.fetchAllOpportunityStateRequest(data));
  };

  const bodyParamsForfiltering1 = {
    filterByCustomField,
    filterByStatus,
    filterByLastImported,
    filterBySource,
    filterByState
  };

  const getStateColor = (account: { state: any; name?: string; _id?: string; customFields?: CustomFields; customFieldsWithProperValues?: CustomFields; updatedAt?: Date; projects?: Project[]; source?: Source; projectIds?: Project[]; status?: "" | "draft"; uniqueEijentId?: string; }) => {
    // Ensure opportunity and state exist and have at least one element
    if (account?.state?.length > 0) {
      const defaultState = opportunityDynamicStates.find(
        (state) => state.name === account.state[0].name
      );

      // Return textColor and backgroundColor if found, else return default values
      if (defaultState) {
        return {
          textColor: defaultState.textColor ? rgbToHex2(defaultState.textColor) : "#000056", // Default text color
          backgroundColor: defaultState.backgroundColor ? rgbToHex2(defaultState.backgroundColor)  : "#ffffff00", // Default background color
        };
      }
    }
  
    // If state length is 0, return no color for both
    return {
      textColor: "",
      backgroundColor: "",
    };
  };
  return (
    <Box className="Opportunity-Account">
      {currentPath !== "accountview" && !showViewModal && !showAddNew ? (

        <Box className='tab-container'>
          <Grid container spacing={2} mb={3} className="top-filter-area">
            <Grid item xs>
              <Grid container spacing={1}>
                <FilterDropDown
                  dropdownArray={dynamicStates}
                  filterBy={filterByState}
                  setFilterBy={setFilterByState}
                  filtering="state"
                  filterCount={filterCount}
                  totalCount={totalCount}
                />
                {/* <Grid item>
                  <FilterByDraft
                    filterByStatus={filterByStatus}
                    setFilterByStatus={setFilterByStatus}
                    filterCount={filterCount}
                  />
                </Grid> */}
                <Grid item>
                  <FilterByLastImported
                    filterByLastImported={filterByLastImported}
                    setFilterByLastImported={setFilterByLastImported}
                    filterCount={filterCount}
                  />
                </Grid>
                <FilterDropDown
                  dropdownArray={sourceFilterArr}
                  filterBy={filterBySource}
                  setFilterBy={setFilterBySource}
                  filtering="source"
                  filterCount={filterCount}
                  totalCount={totalCount}
                />
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={1.25} className="team-dash-right">
                <Grid item className="">
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <CustomFieldFilterDropDown
                      dropdownArray={customFields}
                      filterBy={filterByCustomField}
                      setFilterBy={setFilterByCustomField}
                    />
                    <form onSubmit={handleSearchTextSubmit}>
                      <TextField
                        name="searchTextTemp"
                        placeholder="Search..."
                        className="search"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="" src={SearchIcon} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleSearchText}
                        value={searchTextTemp}
                      />
                    </form>
                  </Stack>
                </Grid>
                <Grid item>
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation
                      ref={anchorRefAction}
                      aria-controls={
                        openActionDropDown ? 'menu-list-grow' : undefined
                      }
                      aria-haspopup="true"
                      onClick={handleToggleAction}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      Actions
                    </Button>
                    <Popper
                      open={openActionDropDown}
                      style={{ top: 10, zIndex: 999 }}
                      placement="bottom-start"
                      transition
                      anchorEl={anchorRefAction.current}
                      role={undefined}
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250}>
                          <Box
                            sx={
                              // @ts-ignore
                              {
                                backgroundColor: theme.palette.common.white,
                                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                borderRadius: '4px',
                                marginTop: '8px',
                              } as SxProps
                            }
                          >
                            <ClickAwayListener
                              onClickAway={handleCloseDropdowns}
                            >
                              <MenuList
                                autoFocusItem={openActionDropDown}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDown}
                              >
                                {allowed['deleteAccount'] && (
                                  <MenuItem
                                    onClick={() =>
                                      handleOpenDeleteConfirmationModel()
                                    }
                                    disabled={!selectedCheckboxes.length}
                                    style={{
                                      color: theme.palette.secondary.main,
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                )}
                                {allowed['viewAccount'] && (
                                  <>
                                    <MenuItem
                                      onClick={() => handleExport(true)}
                                      disabled={!selectedCheckboxes.length}
                                    >
                                      Export
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => handleExport(false)}
                                      disabled={selectedCheckboxes.length > 0}
                                    >
                                      Export All
                                    </MenuItem>
                                  </>
                                )} 
                              </MenuList>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                </Grid>
                <Grid item>
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation
                      ref={anchorRef}
                      aria-controls={
                        openSettingsDropdown ? 'menu-list-grow' : undefined
                      }
                      aria-haspopup="true"
                      onClick={handleToggleSettings}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      Settings
                    </Button>
                    <Popper
                      open={openSettingsDropdown}
                      style={{ top: 10, zIndex: 999 }}
                      placement="bottom-start"
                      transition
                      anchorEl={anchorRef.current}
                      role={undefined}
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250}>
                          <Box
                            sx={
                              // @ts-ignore
                              {
                                backgroundColor: theme.palette.common.white,
                                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                borderRadius: '4px',
                                marginTop: '8px',
                              } as SxProps
                            }
                          >
                            <ClickAwayListener
                              onClickAway={handleCloseDropdowns}
                            >
                              <MenuList
                                autoFocusItem={openSettingsDropdown}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDown}
                              >
                                {allowed['accountCustomFields'] && (
                                  <MenuItem
                                    onClick={handleOpenManageCustomFields}
                                  >
                                    Custom Fields
                                  </MenuItem>
                                )}
                                <MenuItem
                                  onClick={handleOpenManageStates}
                                >
                                  Custom States
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                </Grid>
                <Grid item>
                  {allowed['createAccount'] && (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => handleAddOrUpdateNewAccount()}
                    >
                      Add New
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer
            className="list-table"
            style={{ maxHeight: 'calc(100vh - 380px)' }}
          >
              <Table className="table">
                <TableHead
                  style={{
                    position: 'sticky',
                    top: '0',
                    backgroundColor: '#f6f6fc',
                    zIndex: 2,
                  }}
                >
                  <TableRow>
                    <TableCell className="checkbox sticky-cell">
                      <Checkbox
                        color="primary"
                        onClick={handleSelectAllCheckboxes}
                        checked={
                          selectedCheckboxes.length
                            ? selectedCheckboxes.length === accountData.length
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell
                      className={
                        'id sticky-cell ' +
                        (sortBy === 'uniqueEijentId'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('uniqueEijentId')}
                      style={{ left: 50 }}
                    >
                      Eijent Id
                    </TableCell>
                    <TableCell
                      className={
                        'long-text sticky-cell ' +
                        (sortBy === 'name'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('name')}
                      style={{ left: 150 }}
                    >
                      Account Name
                    </TableCell>
                    <TableCell
                      className={
                        'status ' +
                        (sortBy === 'state'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('state')}
                    >
                      State
                    </TableCell>
                    <TableCell
                      className={
                        'text ' +
                        (sortBy === 'projectId'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('project.name')}
                    >
                      Projects
                    </TableCell>
                    <TableCell
                      className={
                        'text ' +
                        (sortBy === 'source'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('source')}
                    >
                      Source
                    </TableCell>
                    {/* <TableCell
                      className={
                        'text ' +
                        (sortBy === 'status'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('status')}
                    >
                      Status
                    </TableCell> */}
                    {customFields.map((cf) => {
                      return (
                        <TableCell
                          key={cf._id}
                          className={
                            'custom-field ' +
                            (sortBy === `customFields.${cf._id}`
                              ? sortOrder === 'asc'
                                ? 'sort-asc'
                                : 'sort-des'
                              : '')
                          }
                          onClick={() =>
                            handleSorting(
                              `customFieldsWithProperValues.${cf._id}`,
                            )
                          }
                        >
                          {cf.fieldData.fieldName}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className={
                        'long-text ' +
                        (sortBy === 'updatedAt'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('updatedAt')}
                    >
                      Updated on
                    </TableCell>
                    <TableCell className="options">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(accountData) && accountData.length > 0 ? (
                    accountData.map((account) => {
                      return (
                        <TableRow key={account._id}>
                          <TableCell
                            className="checkbox sticky-cell"
                            style={{ left: 1 }}
                          >
                            <Checkbox
                              color="primary"
                              onClick={(e) =>
                                handleSelectCheckBox(e, account._id)
                              }
                              checked={selectedCheckboxes.includes(account._id)}
                            />
                          </TableCell>
                          <TableCell
                            className="id sticky-cell link"
                            onClick={() =>
                              handleAddOrUpdateNewAccount(account, true)
                            }
                            style={{ left: 50 }}
                          >
                            {' '}
                            {account?.uniqueEijentId || '-'}
                          </TableCell>
                          <TableCell
                            className="long-text sticky-cell link"
                            onClick={() =>
                              handleAddOrUpdateNewAccount(account, true)
                            }
                            style={{ left: 150 }}
                          >
                            {' '}
                            {account?.name || '-'}
                          </TableCell>
                          <TableCell className="status">
                            {account?.state?.length > 0 ? (
                              <Box
                                component={'span'}
                                className="table-status"
                                sx={{
                                  color: `${getStateColor(account).textColor} !important`,
                                  bgcolor: `${getStateColor(account).backgroundColor}`,
                                }}
                              >
                                {account?.state[0]?.name || '-'}
                              </Box>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell className="text">
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              spacing={1}
                            >
                              <span
                                className="long-text"
                                style={{ maxWidth: '80%' }}
                              >
                                {' '}
                                {account?.projectIds &&
                                  account?.projectIds?.length > 0
                                  ? account?.projectIds[0]?.eventName
                                  : '-'}
                              </span>
                              {account?.projectIds?.length > 1 && (
                                <div className="detail-popup">
                                  <span className="count">
                                    +{account?.projectIds.length - 1}
                                  </span>
                                  <div className="info">
                                    {showRestOfProjects(account?.projectIds).map(
                                      (projectIds: Project, i: number) => {
                                        return (
                                          <div key={i}>
                                            <p>{projectIds.eventName}</p>
                                          </div>
                                        );
                                      },
                                    )}
                                  </div>
                                </div>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell className="text">
                            {account?.source === 'aiAgent'
                              ? 'AI Agent'
                              : account?.source === 'imported'
                                ? 'Imported'
                                : 'Manually Added'}
                          </TableCell>
                          {/* <TableCell className="text">
                            {account?.status === 'draft' ? 'Draft' : ''}
                          </TableCell> */}
                          {customFields.map((cf: CustomField) => {
                             const value1 = account?.customFields?.[cf._id as string] as any | any[];
                            return Array.isArray(
                              account?.customFields?.[
                              cf._id as string
                              ],
                            ) ? (
                              <>
                              <TableCell key={cf._id as string} className="text">
                                  <Stack
                                    direction="row"
                                    alignItems={'center'}
                                    spacing={1}
                                  >
                                    <span
                                      className={`long-text ${cf.fieldData.fieldType === 'user' &&
                                        'link'
                                        }`}
                                      style={{ maxWidth: '80%' }}
                                      onClick={() => {
                                        if (cf.fieldData.fieldType === 'user' && value1.length > 0) {
                                          window.open(`mailto:${value1[0]}`);
                                        }
                                      }}
                                    >
                                      {cf.fieldData.fieldType === 'user'
                                        ? value1.length > 0
                                          ? value1[0].email || value1[0]  // Adjust based on actual data structure
                                          : ''
                                        : value1.length > 0
                                          ? value1[0]
                                          : ''}
                                    </span>
                                    {value1.length > 1 && (
                                      <div className="detail-popup">
                                        <span className="count">+{value1.length - 1}</span>
                                        <div className="info">
                                          {showRestOfPages(value1).map((cfValue: string | { email: string; }, i: number) => {
                                            // Determine if cfValue is a string or an object with an email
                                            const isObject = typeof cfValue === 'object' && cfValue !== null;
                                            const displayValue = isObject ? (cfValue as { email: string; }).email : cfValue;

                                            return (
                                              <div
                                                key={i}
                                                className={`${cf.fieldData.fieldType === 'user' ? 'link' : ''}`}
                                                style={{ zIndex: 999999 }}
                                                onClick={() => {
                                                  if (cf.fieldData.fieldType === 'user' && isObject) {
                                                    window.open(`mailto:${displayValue}`);
                                                  }
                                                }}
                                              >
                                                <p>{displayValue}</p>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    )}

                                  </Stack>
                              </TableCell>
                              </>
                            ) : (
                              <>
                              <TableCell
                              className={`custom-field ${
                                allowed['viewContacts'] &&
                                [
                                  'contact',
                                  'url',
                                  'email',
                                  'facebook',
                                  'instagram',
                                  'x',
                                  'pinterest',
                                  'linkedin',
                                  'phone'
                                ].includes(cf.fieldData.fieldType) &&
                                (account?.customFields?.[cf._id as string]) &&
                                'link'
                              }`}
                              key={cf._id}
                              onClick={(e) => {
                                const fieldValue = account?.customFields?.[cf._id as any] as any;
                            
                                if (fieldValue) {
                                  switch (cf.fieldData.fieldType) {
                                    case 'contact':
                                      if (allowed['viewContacts']) {
                                        if (fieldValue?.uniqueEijentId) {
                                          history.push({
                                            pathname: `/opportunity/contactdetail/${fieldValue.uniqueEijentId}`,
                                          });
                                        }
                                      } else {
                                        createNotification('error', "Sorry! You don't have permission to view this contact");
                                      }
                                      break;
                                    case 'url':
                                    case 'facebook':
                                    case 'instagram':
                                    case 'x':
                                    case 'pinterest':
                                    case 'linkedin':
                                      let url = fieldValue;
                                      if (!/^https?:\/\//i.test(url)) {
                                        url = 'https://' + url;
                                      }
                                      window.open(url);
                                      break;
                                    case 'email':
                                      window.open(`mailto:${fieldValue}`);
                                      break;
                                    case 'phone':
                                      window.open(`tel:${fieldValue}`);
                                      break;
                                    default:
                                      console.warn(`Unhandled field type: ${cf.fieldData.fieldType}`);
                                  }
                                }
                              }}
                            >
                              {cf.fieldData.fieldType === 'contact' ? (
                                (account?.customFields?.[cf._id as string]?.fullName as string) || '-'
                              ) : cf.fieldData.fieldType === 'date' && value1 ? (
                                formatDateField(account, cf, customFields)
                              ) : cf.fieldData.fieldType === 'boolean' ? (
                                (account?.customFields?.[cf._id as string]?.toString() === 'true') ? 'True' :
                                (account?.customFields?.[cf._id as string]?.toString() === 'false') ? 'False' :
                                '-'
                              ) : cf.fieldData.fieldType === 'longText' && account?.customFields?.[cf._id as string] ? (
                                <CustomTooltip
                                  interactive
                                  title={
                                    <Box className="upgrade-tooltip long-description">
                                      <p>{account?.customFields?.[cf._id as string]}</p>
                                    </Box>
                                  }
                                  placement="bottom-start"
                                  arrow={false}
                                >
                                  <span>{account?.customFields?.[cf._id as string]}</span>
                                </CustomTooltip>
                              ) : (
                                (account?.customFields?.[cf._id as string] as string) || '-'
                              )}
                            </TableCell>
                            </>
                            
                            );
                          })}

                          <TableCell className="long-text">
                            {' '}
                            {moment(account.updatedAt).format(
                              'MMM, Do YYYY, h:mm A',
                            )}
                          </TableCell>
                          <TableCell className="options" align="center">
                            {' '}
                            {allowed['editAccount'] && (
                              <div
                                onClick={() =>
                                  handleAddOrUpdateNewAccount(account, false)
                                }
                              >
                                <img alt="Edit" src={EditIcon} />{' '}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                        There are no records to display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
          </TableContainer>
          <PaginationFunctional
            startCount={accountData.length === 0 ? 0 : startCount}
            setStartCount={setStartCount}
            endCount={accountData.length === 0 ? 0 : endCount}
            limit={limit}
            setLimit={setLimit}
            page_no={pageNo}
            setPage_no={setPageNo}
            totalItemsCount={totalCount}
            listItemsFn={() => { }}
          />
        </Box>
      ) : showAddNew ? (

        <Box className='tab-container'>
          <AddOrEditAccount
            usedFor="Account"
            setShowAddNew={setShowAddNew}
            queryParams={queryParams}
            toBeEdited={accountToBeEdited as Account}
            resetAddNew={resetAddNewModalAccount}
            bodyParamsForfiltering={bodyParamsForfiltering1}
          />
        </Box>
      ) : (
        <ViewLayout
          closeViewModal={closeViewModal}
          accountToBeEdited={accountToBeEdited}
          setShowAddNew={setShowAddNew}
          setShowViewModal={setShowViewModal}
          handleTabChangeFromMainLayout={handleTabChange}
          type="account"
        />
      )}

      {showManageCustomFields && (
        <ManageCustomFields
          handleCloseManageCustomFields={handleCloseManageCustomFields}
          showManageCustomFields={showManageCustomFields}
          handleOpenManageCustomFields={handleOpenManageCustomFields}
        />
      )}

      {showManageStates && (
        <ManageStates
          handleCloseManageStates={handleCloseManageStates}
          showManageStates={showManageStates}
          from={"accounts"}
        />
      )}

      {loading && <Loader />}
      <ConfirmationAlertPopUp
        openModal={openDeleteConfirmationModel}
        closeModalFunc={closeDeleteConfirmationModel}
        title={'Delete Confirmation!'}
        text={'Are you sure you want to delete it?'}
        confirmationButtonText={'Delete'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={handleDelete}
      />
      <ImportModal
        open={openImportModal}
        tab={'accounts'}
        handleOpen={handleOpenImportModal}
        fetchAllOpportunityData={fetchAllOpportunityAccounts}
      />
      {(isloading || isCustomFieldsLoading || isAccountsLoading) && <Loader />}
    </Box>
  );
}
