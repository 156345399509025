import { FormControl, InputBase, InputAdornment, IconButton, Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { FunctionComponent, useState } from "react";
import WidgetHelper from "./Helper";
import { ChatInterfaceType } from "Redux/Reducers/aiChatbot.reducer";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SendIcon from '@material-ui/icons/Send';
export interface ChatInputProps {
    chatInterface?: ChatInterfaceType;
    onUserMessage?: Function;
    disableSendButton?: boolean;
    chatWindowRef?:any;
    onChatLeaveClick?: Function;
}
const ChatInput: FunctionComponent<ChatInputProps> = (props) => {
    const [newMessage, setNewMessage] = useState<string>('');

    return <Stack
        className="chat-input"
        sx={WidgetHelper.getCustomStyle('chatInputContainer', props.chatInterface)}
        spacing={1}
        alignItems={'end'}
    >

        <FormControl
            component={'form'}
            onSubmit={(e) => {
                e.preventDefault();
                setNewMessage("");
                if (props.onUserMessage) {
                    props.onUserMessage(newMessage);
                }           
            }}
            fullWidth
        >

            <InputBase
                id="standard-adornment-password"
                type="text"
                placeholder="Type a message..."
                value={newMessage}
                autoComplete="off"
                onChange={(e) => setNewMessage(e.target.value)}
                style={WidgetHelper.getCustomStyle('chatInput', props.chatInterface)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            style={{
                                color: WidgetHelper.rgbToHex(props.chatInterface?.chatInputTextColor),
                                opacity: props.disableSendButton ? "0.4" : "1"
                            }}
                            disabled={props.disableSendButton}
                            aria-label="send"
                            size="small"
                            type="submit"
                        >
                            <SendIcon className="send-icon" />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
        <Box title='Start new chat'
            display={'flex'}
            alignItems={'center'}
            style={{
                color: WidgetHelper.rgbToHex(props.chatInterface?.chatInputTextColor),
                fontSize: '12px',
                cursor: 'pointer',
            }}
            onClick={() => { props.onChatLeaveClick && props.onChatLeaveClick(); }}
        >
            <ExitToAppIcon style={{ fontSize: '16px' }} /> <Box ml={0.5}>Leave the chat</Box>
        </Box>
    </Stack>;
};

export default ChatInput;