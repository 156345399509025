import { Button, CircularProgress, IconButton } from '@material-ui/core';
import { Stack, Grid, Box } from '@mui/material';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  CustomField,
  CustomFields,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { useSelector } from 'react-redux';
import { rgbToHex2, separateByComma } from 'helpers/common';
import OpportunityContactsAPI from 'Redux/API/opportunity/opportunityContactsAPI';
import { Contact } from 'Redux/Reducers/opportunity/contacts.reducer';
import OpportunityAccountsCustomFieldsAPI from 'Redux/API/opportunity/opportunityCustomFieldsAPI';
import Loader from 'Components/Loader/loader';
import {
  AccountCf,
  ContactCf,
  Opportunity,
  OpportunityState,
} from 'Redux/Reducers/opportunity/opportunities.reducer';
import ContactOrAccountDetailsSideModal from '../../Accounts/ContactOrAccountDetailsSideModal';
import OpportunityAccountsAPI from 'Redux/API/opportunity/opportunityAccountsAPI';
import ChatHistory from '../ChatHistory';
import ReadMore from '../../../../assets/images/icons/informationIcon.svg';
import AIChatbotAPI from 'Redux/API/aiChatbotAPI';
import { Message } from 'Pages/AIChatbot/Tabs/ChatWidget/MessageWindow';
import { getSystemLevelPermissions } from '../../../../helpers/common';
import moment from 'moment';
import { formatDateField } from 'helpers/common';
import ChatHelpers from 'Components/ChatWidget/helpers';
import { 
  OpportunityStates as OpportunityDynamicStates, 
  OpportunityStates_State 
} from 'Redux/Reducers/opportunity/opportunityStates.reducer';


type Props = {
  closeViewModal: () => void;
  opportunityToBeEdited: Opportunity | undefined;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleTabChange: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
  commentSectionOpened: boolean;
};

function OpportunityView({
  closeViewModal,
  opportunityToBeEdited,
  setShowAddNew,
  setShowViewModal,
  handleTabChange,
  commentSectionOpened
}: Props) {
  const {
    contactsObj,
    accountsObj,
    isLoading: isOpportunitiesLoading,
  } = useSelector(
    (state: any) => state.OpportunityOpportunities,
  ) as OpportunityState;

  const {opportunityStates: opportunityDynamicStates, fetchAllOpportunityStateSuccess} = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;
  
  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;
  const [detailsForToolTip, setDetailsForToolTip] = useState<Contact>(
    {} as Contact,
  );
  const [contactsCustomFields, setContactsCustomFields] =
    useState<CustomFields>({} as CustomFields);
  const [accountsCustomFields, setAccountsCustomFields] =
    useState<CustomFields>({} as CustomFields);
  const [openSideModal, setOpenSideModal] = useState(false);
  const [customFieldsForSideModal, setCustomFieldsForSideModal] =
    useState<any>(null);
  const [modalUsedFor, setModalUsedFor] = useState<'contact' | 'account'>(
    'contact',
  );
  const [titleForSideModal, setTitleForSideModal] = useState<string>('');
  const [summary, setSummary] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [openChatHistory, setOpenChatHistory] = useState<boolean>(false);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [allowed, setAllowed] = useState<any>({});

  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
    fetchContactsCustomField();
    fetchAccountsCustomField();
    opportunityToBeEdited?.sourceRef && fetchChatHistoryAndSummary();
  }, []);

  const fetchChatHistoryAndSummary = async () => {
    try {
      setIsloading(true);
      const aIChatbotAPI = new AIChatbotAPI();
      let data = {
        chatId: opportunityToBeEdited?.sourceRef,
      };
      const res = await aIChatbotAPI.getChatSummary(data);

      if (res.data.chatSummary?.summary) {
        setIsloading(false);
        setSummary(res.data.chatSummary.summary);
      }
      if (res.data.history) {
        setIsloading(false);
        const newMessages = ChatHelpers.splitMessagesIntoParagrphs(res.data.history);

        setMessages(newMessages);
      }
    } catch (err) {
      setIsloading(false);
    }
  };

  const fetchContactsCustomField = async () => {
    try {
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'contacts',
        from: 'viewOpportunity',
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setContactsCustomFields(res.data.customFields);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAccountsCustomField = async () => {
    try {
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'accounts',
        from: 'viewOpportunity',
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setAccountsCustomFields(res.data.customFields);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContactById = async (contactId: string) => {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const res = await opportunityContactsAPI.fetchOpportunityContact({
      id: contactId,
    });
    if (res.data.contact) {
      setDetailsForToolTip(res.data.contact);
    }
  };

  const fetchAccountById = async (accountId: string) => {
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const res =
      await opportunityAccountsAPI.fetchAccountWithProperCustomFieldsValue({
        id: accountId,
      }); 
    if (res.data?.account) {
      setDetailsForToolTip(res.data.account);
    }
  };

  const handleShowSideModal = (
    viewDetailData: any,
    fieldType: string,
    title: string,
  ) => {
    if (viewDetailData._id){
    setTitleForSideModal(title);
    if (fieldType === 'contact') {
      setModalUsedFor('contact');
      openContactDetailsModal(viewDetailData._id);
    } else if (fieldType === 'account') {
      setModalUsedFor('account');
      openAccountDetailsModal(viewDetailData._id);
    }
  }
  };

  const openContactDetailsModal = (id: string) => {
    setOpenSideModal(true);
    setCustomFieldsForSideModal(contactsCustomFields);
    fetchContactById(id);
  };

  const openAccountDetailsModal = (id: string) => {
    setOpenSideModal(true);
    setCustomFieldsForSideModal(accountsCustomFields);
    fetchAccountById(id);
  };

  const handleCloseSideModal = () => {
    setOpenSideModal(false);
    setDetailsForToolTip({} as Contact);
  };

  const handleCloseChatHistory = () => {
    setOpenChatHistory(false);
  };

  const showRestOfPages = (cfs: (string | { email: string; })[]) => {
    return cfs?.slice(1, cfs.length);
  };

  const getStateColor = (opportunity: Opportunity | undefined) => {
    // Ensure opportunity and state exist and have at least one element
    if (opportunity?.state?.length > 0) {
      const defaultState = opportunityDynamicStates.find(
        (state) => state.name === opportunity.state[0].name
      );
      // Return textColor and backgroundColor if found, else return default values
      if (defaultState) {
        return {
          textColor: defaultState.textColor ? rgbToHex2(defaultState.textColor) : "#000056", // Default text color
          backgroundColor: rgbToHex2(defaultState.backgroundColor)   || "#ffffff00", // Default background color
        };
      }
    }
  
    // If state length is 0, return no color for both
    return {
      textColor: "",
      backgroundColor: "",
    };
  };

  const customFieldValue = (
    cf: CustomField,
    opportunityToBeEdited: Opportunity | undefined,
  ) => {
    const value1 = opportunityToBeEdited?.customFields?.[cf._id as string] as any | any[]; 

    if (cf.fieldData.fieldType === 'contact') {
      return opportunityToBeEdited?.customFields?.[cf._id as string]?.fullName || '-';
    } else if (cf.fieldData.fieldType === 'account') {
      return opportunityToBeEdited?.customFields?.[cf._id as string]?.name || '-';
    } else if (cf.fieldData.fieldType === 'boolean') {
      const boolValue = Array.isArray(value1) ? value1[0] : value1; // Get the first element if it's an array
      return (boolValue && boolValue.toString().toUpperCase() === "TRUE") ? "TRUE" : "FALSE";
    } else if (cf.fieldData.fieldType === 'user' && Array.isArray(value1) && value1.length > 0) {
      // Handle 'user' field type with detail-popup
      return (
        <>
          <span
            className={`long-text ${cf.fieldData.fieldType === 'user' && 'link'}`}
            style={{ maxWidth: '80%' }}
            onClick={() => {
              if (cf.fieldData.fieldType === 'user') {
                window.open(`mailto:${value1[0].email || value1[0]}`);
              }
            }}
          >
            {value1[0].email || value1[0]}
          </span>

          {value1.length > 1 && (
            <div className="detail-popup">
              <span className="count">+{value1.length - 1}</span>
              <div className="info">
                {value1.slice(1).map((cfValue: string | { email: string; }, i: number) => {
                  const displayValue = typeof cfValue === 'object' ? cfValue.email : cfValue;
                  return (
                    <div
                      key={i}
                      className={cf.fieldData.fieldType === 'user' ? 'link' : ''}
                      onClick={() => {
                        if (cf.fieldData.fieldType === 'user' && typeof cfValue === 'object') {
                          window.open(`mailto:${displayValue}`);
                        }
                      }}
                    >
                      <p>{displayValue}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      );
    } else if (Array.isArray(value1) && value1.length > 0) {
      // Handle other field types similarly with detail-popup
      return (
        <>
          <span className="long-text" style={{ maxWidth: '80%' }}>
            {value1[0]}
          </span>

          {value1.length > 1 && (
            <div className="detail-popup">
              <span className="count">+{value1.length - 1}</span>
              <div className="info">
                {value1.slice(1).map((cfValue: string, i: number) => (
                  <div key={i} style={{ zIndex: 999999 }}>
                    <p>{cfValue}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      );
    } else {
      // Handle other single-value fields like 'date'
      return cf.fieldData.fieldType === 'date'
        ? formatDateField(opportunityToBeEdited, cf, customFields)
        : (() => {
          const fieldValue = opportunityToBeEdited?.customFields?.[cf._id as string];

          // Check if the value is a string or number
          if (fieldValue === undefined || fieldValue === null) {
            return "-"; // Return blank if value is undefined or null
          } else if (typeof fieldValue !== "string" && typeof fieldValue !== "number") {
            return "Invalid Data"; // Show "Invalid Data" if it's not a string or number
          } else {
            return fieldValue; // Otherwise return the valid value
          }
        })()
    }
  };


  return (
    <>
      <Box className="Opportunity-Account">
        {/* @ts-ignore */}
        <Grid container spacing={2} mb={3} className="top-filter-area">
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton size="small" onClick={closeViewModal}>
                <KeyboardBackspaceIcon color="primary" />
              </IconButton>
              <Box className="paragraph bold">Opportunity view</Box>
            </Stack>
          </Grid>
          <Grid item>
            {/* @ts-ignore */}
            <Grid container spacing={1.25} className="team-dash-right">
              <Grid item>
                {allowed['editOpportunity'] && (
                  <Button
                    onClick={() => {
                      setShowAddNew(true);
                      setShowViewModal(false);
                    }}
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {summary && (
          <Stack spacing={1} className="account-details" mb={3} sx={{ minWidth: '600px' }}>
            <Box className="subtitle">AI Summary</Box>
            <Box className="small-subtitle">{summary}</Box>
            <Box
              className="small-subtitle link"
              onClick={() => setOpenChatHistory(true)}
            >
              View Chat History
            </Box>
          </Stack>
        )}
        <Box className="account-details" sx={{ minWidth: '600px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}
              sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4} >
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Eijent Id:
                  </Box>
                </Grid>
                <Grid item xs={12} md>
                  <Box className="small-subtitle primary-text ">
                    {opportunityToBeEdited?.uniqueEijentId}
                  </Box>
                </Grid>
              </Grid>
            </Grid>   

            <Grid item xs={12}
              sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4} >
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Opportunity Name<span className="required">*</span>:
                  </Box>
                </Grid>
                <Grid item xs={12} md>
                  <Box className="small-subtitle primary-text ">
                    {opportunityToBeEdited?.name}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4} >
              <Grid container>
                <Grid item xs={12} >
                  <Box className="small-subtitle bold primary-text">
                    Project:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span className="small-subtitle primary-text">
                    {opportunityToBeEdited?.projectIds?.length > 0 && opportunityToBeEdited?.projectIds[0]?.eventName ? opportunityToBeEdited.projectIds[0].eventName : '-'}
                    </span>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Assigned To:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span
                      className={`small-subtitle primary-text ${opportunityToBeEdited?.assignedToIds?.[0]?.email ? "link" : ""}`}
                      onClick={() => {
                        if (opportunityToBeEdited?.assignedToIds?.[0]?.email) {
                          window.open(`mailto:${opportunityToBeEdited.assignedToIds[0].email}`);
                        }
                      }}
                    >
                      {opportunityToBeEdited?.assignedToIds?.[0]?.email || ""}
                    </span>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    State:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                  {opportunityToBeEdited?.state?.length > 0 ? (
                    <Box component="span" className="table-status "
                    sx={{
                      color: `${getStateColor(opportunityToBeEdited).textColor} !important`,
                      bgcolor: `${getStateColor(opportunityToBeEdited).backgroundColor}`,
                    }}
                    >
                      { (opportunityToBeEdited && opportunityToBeEdited.state && Array.isArray(opportunityToBeEdited.state))? opportunityToBeEdited.state[0]?.name || "" :''}
                    </Box>
                     ) : (
                      '-'
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Source:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text">
                    {opportunityToBeEdited?.source === 'aiAgent'
                      ? 'AI Agent'
                      : opportunityToBeEdited?.source === 'imported'
                        ? 'Imported'
                        : 'Manually Added'}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {customFields.map((cf: CustomField) => {
              return (
                <Grid item xs={12} key={cf._id}
                  sm={
                    cf.fieldData.fieldType === 'longText' ? 12 :
                      commentSectionOpened ? 12 : 6
                  }
                  lg={
                    cf.fieldData.fieldType === 'longText' ? 12 :
                      commentSectionOpened ? 6 : 4
                  }
                >
                  <Grid container>
                    <Grid item xs={12} >
                      <Box className="small-subtitle bold primary-text">
                        {cf.fieldData.fieldName}:
                      </Box>
                    </Grid>
                    <Grid item xs={12} >
                      <Stack direction={'row'} spacing={1}>
                        <Box
                          className={`small-subtitle primary-text  ${['account', 'contact', 'url', 'email', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin', 'phone'].includes(cf.fieldData.fieldType) ? 'link' : ''
                            }
                          ${cf.fieldData.fieldType === 'longText' ? '' : ''
                            }`}
                          onClick={(e: SyntheticEvent<Element, Event>) => {  
                            if (cf.fieldData.fieldType === 'contact') {
                              //   handleTabChange(
                              //   e,
                              //   '3',
                              //   opportunityToBeEdited?.customFields?.[cf._id as string].fullName
                              // );
                              opportunityToBeEdited?.customFields?.[cf._id as string]?.fullName &&
                              // cf.fieldData.fieldName &&
                              handleShowSideModal(
                                opportunityToBeEdited?.customFields?.[
                                cf._id as string
                                ] as
                                | ContactCf
                                | AccountCf as unknown as string,
                                cf.fieldData.fieldType,
                                cf.fieldData.fieldName,
                              );
                            } else if (cf.fieldData.fieldType === 'account') {
                            //   handleTabChange(
                            //   e,
                            //   '2',
                            //   opportunityToBeEdited?.customFields?.[cf._id as string].name
                            // );
                            opportunityToBeEdited?.customFields?.[cf._id as string]?.name &&
                            handleShowSideModal(
                              opportunityToBeEdited?.customFields?.[
                              cf._id as string
                              ] as
                              | ContactCf
                              | AccountCf as unknown as string,
                              cf.fieldData.fieldType,
                              cf.fieldData.fieldName,
                            );
                          }
                            if (['url', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin'].includes(cf.fieldData.fieldType)) {
                              let url = (opportunityToBeEdited
                                ?.customFields?.[
                                cf._id as string
                              ] as string);
                              if (!/^https?:\/\//i.test(url)) {
                                // If not, prepend 'https://'
                                url = 'https://' + url;
                              }
                              window.open(url, "_blank");
                            }
                            if (cf.fieldData.fieldType === 'email') {
                              window.open(`mailto:${opportunityToBeEdited
                                ?.customFields?.[
                                cf._id as string
                              ] as string}`);
                            } else if (cf.fieldData.fieldType === 'phone') {
                              window.open(`tel:${opportunityToBeEdited
                                ?.customFields?.[
                                cf._id as string
                              ] as string}`);
                            }

                          }}
                        >
                          {/* @ts-ignore */}
                          {customFieldValue(cf, opportunityToBeEdited)}
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {openSideModal && (
            <ContactOrAccountDetailsSideModal
              open={openSideModal}
              handleClose={handleCloseSideModal}
              details={detailsForToolTip}
              customFields={customFieldsForSideModal}
              usedFor={modalUsedFor}
              title={titleForSideModal}
            />
          )}
        </Box>
      </Box>
      <ChatHistory
        open={openChatHistory}
        handleClose={handleCloseChatHistory}
        messages={messages}
      />
      {(isCustomFieldsLoading || isOpportunitiesLoading || isloading) && (
        <Loader />
      )}
    </>
  );
}

export default OpportunityView;
