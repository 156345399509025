import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import WidgetHelper from "./Helper";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ChatInterfaceType } from "Redux/Reducers/aiChatbot.reducer";
import { Message } from "./Widget";
import ChatMessage from "./ChatMessage";

export interface ChatWindowProps {
    chatInterface?: ChatInterfaceType;
    messages?: Message[];
    error?: string;
    loading?: boolean;
    onStartButtonClick?: Function;
}


export interface ChatWindowActions {
    addMessage(text: string, sender: string, id: string): void;
    updateMessageById(id: string, newText: string): void;
    endChat(): void;
}
const WidgetChatWindow: ForwardRefRenderFunction<ChatWindowActions, ChatWindowProps> = (props, ref) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [errorMessageIndex, setErrorMessageIndex] = useState<number>(0);
    const chatScroll = useRef<HTMLElement>(null);
    const [showStartAlert, setShowStartAlert] = useState<boolean>(false);
    useEffect(() => {
        if (props.messages) {
            setMessages([...props.messages]);
        }
    }, [props.messages]);

    useEffect(() => {
        const index = messages?.findLastIndex((m) => m.sender === "user");
        setErrorMessageIndex(index);
        // console.log("index---------", index);
    }, [messages, props.error]);

    const endChat = () => {
        console.log("Chat ended in Chat Window");
        setShowStartAlert(true);
    };

    const scrollToBottom = () => {
        if (chatScroll?.current) {
            chatScroll.current.scrollTo({
                top: chatScroll.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };
    const addMessage = (
        text: string,
        sender: 'user' | 'assistant',
        id: string = '',
    ) => {
        setMessages((prevMessages) => [...prevMessages, { id: id, text, sender, timetamp: new Date().toString() }]);
        setTimeout(() => {
            scrollToBottom();
        }, 200);
    };

    const updateMessageById = (id: string, newText: string) => {
        //   console.log('ALL MESSAGES', messages);
        //  console.log(' MESSAGE ID', id);
        setMessages((prevMessages) => {
            const existingMessageIndex = prevMessages.findIndex((m) => m.id === id);
            //     console.log('I N D E X', index);

            scrollToBottom();

            if (existingMessageIndex > -1) {
                //  console.log('Updating Message', prevMessages[index]);
                prevMessages[existingMessageIndex].text = newText.replace(
                    /(^")|("$)/g,
                    '',
                );
                return [...prevMessages];
            } else {
                //  console.log('Adding Message.. ');
                return [
                    ...prevMessages,
                    {
                        id: id,
                        text: newText,
                        sender: 'assistant',
                        timetamp: new Date().toString()
                    },
                ];
            }
        });
    };
    useImperativeHandle(ref, () => ({
        addMessage,
        updateMessageById,
        endChat
    }));
    return (
        <>
            <Box className="chat-window"
                sx={WidgetHelper.getCustomStyle('chatWindow', props.chatInterface)}
            >
                {/* @ts-ignore */}
                <Stack
                    spacing={
                        WidgetHelper.getCustomStyle('agentMessage', props.chatInterface)?.spacing +
                        'px'
                    }
                    className="chat-history"
                    pb={
                        WidgetHelper.getCustomStyle('agentMessage', props.chatInterface)?.spacing +
                        'px'
                    }
                    ref={chatScroll}
                >
                    {messages.map((message, ind) =>
                        <ChatMessage
                            key={"mes_" + ind}
                            message={message}
                            chatInterface={props.chatInterface}
                            error={ind === errorMessageIndex ? props.error : ""} />
                    )}

                    {/* chat end text */}
                    {showStartAlert &&
                        <Box className={'chat-end-text'} fontSize={`${props.chatInterface?.endChatSession?.font?.size}px`} textAlign={'center'} color={WidgetHelper.rgbToHex(props.chatInterface?.timeStampTextColor)}>
                            {props.chatInterface?.endChatSession?.EndChatSessionText} &nbsp;
                            <Box component={'a'}
                                href='#'
                                onClick={() => props.onStartButtonClick && props.onStartButtonClick()}
                                fontSize={`${props.chatInterface?.endChatSession?.font?.size}px`}
                                color={WidgetHelper.rgbToHex(props.chatInterface?.timeStampTextColor)}
                                // @ts-ignore
                                sx={{ textDecoration: 'underline !important' }}>
                                {props.chatInterface?.endChatSession?.StartChatUrlText}
                            </Box>
                        </Box>}

                    {/* message loader */}

                    {props.loading ? <Box className={'bot chat-bubble'} >


                        <Box
                            className="message"
                            style={WidgetHelper.getCustomStyle('agentMessage', props.chatInterface)}
                        >
                            <Box className="typing" >
                                <Box className="typing__dot" bgcolor={WidgetHelper.rgbToHex(props.chatInterface?.botMessageTextColor)}></Box>
                                <Box className="typing__dot" bgcolor={WidgetHelper.rgbToHex(props.chatInterface?.botMessageTextColor)}></Box>
                                <Box className="typing__dot" bgcolor={WidgetHelper.rgbToHex(props.chatInterface?.botMessageTextColor)}></Box>
                            </Box>
                        </Box>

                    </Box> : <></>}
                </Stack>
            </Box>

        </>
    );
};

const ChatWindow = forwardRef<ChatWindowActions, ChatWindowProps>(WidgetChatWindow);

export default ChatWindow;