abstract class ChatHelpers {

static splitMessagesIntoParagrphs(sortedMessages: any) {
    const newMessages = [];
    for (const message of sortedMessages) {
      if (message.sender === 'user') {
        newMessages.push(message);
        continue;
      }

      // Split the message into paragraphs
      const paragraphs = message.text.split(/\s*\r?\n\s*\r?\n\s*/);

      if (paragraphs.length > 1) {
        // Replace the original message text with the first paragraph
        message.text = paragraphs[0];
        message.id = '_' + Math.random().toString(36).substring(2, 15);
        newMessages.push(message);

        // Add the remaining paragraphs as new "assistant" messages
        for (let i = 1; i < paragraphs.length; i++) {
          newMessages.push({
            _id:
              i === paragraphs.length - 1
                ? message._id
                : '_' + Math.random().toString(36).substring(2, 15), // Use original message ID for the last paragraph
            sender: 'assistant',
            text: paragraphs[i],
            timetamp: message.timetamp, // Keep the same timestamp
            index: message.index + i,
          });
        }
      } else {
        // If there's only one paragraph, keep the message unchanged
        newMessages.push(message);
      }
    }
    return newMessages;
  }
}
export default ChatHelpers;
