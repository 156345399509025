import React from 'react';
// import './SampleEmail.css'; // Import CSS if needed for external styling

const SampleEmail = () => {
  return (
    <div className="container" style={containerStyle}>
      {/* Header Section */}
      <div className="header" style={headerStyle}>
        <h1 style={headerTextStyle}>Special Offer Just for You!</h1>
      </div>

      {/* Body Content */}
      <div className="body-content" style={bodyContentStyle}>
        <h2 style={subheaderTextStyle}>Exclusive Deal: [Product Name or Service]</h2>
        <p style={paragraphTextStyle}>
          We're excited to bring you an exclusive offer! For a limited time, get [mention discount or offer details, e.g., "50% off"] on our [product/service]. This is the perfect opportunity to enjoy [highlight key benefits of the product/service].
        </p>
        <p style={paragraphTextStyle}>
          Don't miss out on this limited-time promotion. Click the button below to claim your offer now!
        </p>

        {/* Call to Action Button */}
        <a href="[Insert Link to Product or Offer]" className="cta-button" style={ctaButtonStyle}>
          Shop Now
        </a>
      </div>

      {/* Footer Section */}
      <div className="footer" style={footerStyle}>
        <p style={footerTextStyle}>
          Follow us on: <a href="#" style={linkStyle}>Facebook</a> | <a href="#" style={linkStyle}>Twitter</a> | <a href="#" style={linkStyle}>Instagram</a>
        </p>
        <p style={footerTextStyle}>
          If you no longer wish to receive these emails, you may <a href="[unsubscribe link]" style={linkStyle}>unsubscribe</a> at any time.
        </p>
      </div>
    </div>
  );
};

// Inline Styles
const containerStyle = {
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  overflow: 'hidden',
};

const headerStyle = {
  backgroundColor: '#007BFF',
  padding: '20px',
  color: '#ffffff',
  textAlign: 'center',
};

const headerTextStyle = {
  margin: '0',
  fontSize: '24px',
};

const bodyContentStyle = {
  padding: '20px',
};

const subheaderTextStyle = {
  color: '#333333',
  fontSize: '22px',
};

const paragraphTextStyle = {
  color: '#666666',
  fontSize: '16px',
  lineHeight: '1.6',
};

const ctaButtonStyle = {
  display: 'inline-block',
  backgroundColor: '#28a745',
  color: '#ffffff',
  padding: '10px 20px',
  textDecoration: 'none',
  borderRadius: '4px',
  marginTop: '20px',
  fontSize: '16px',
};

const footerStyle = {
  backgroundColor: '#f4f4f4',
  padding: '15px',
  textAlign: 'center',
  fontSize: '12px',
  color: '#777777',
};

const footerTextStyle = {
  marginBottom: '10px',
};

const linkStyle = {
  color: '#007BFF',
  textDecoration: 'none',
};

export default SampleEmail;
