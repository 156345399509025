import { ChatInterfaceType, Color } from 'Redux/Reducers/aiChatbot.reducer';

abstract class WidgetHelper {
  static rgbToHex(color?: Color) {
    if (color?.r && color?.g && color?.b) {
      let { r, g, b } = color;
      // Convert each RGB component to hexadecimal
      var redHex = r.toString(16).padStart(2, '0');
      var greenHex = g.toString(16).padStart(2, '0');
      var blueHex = b.toString(16).padStart(2, '0');

      // Concatenate the hex values
      var hexColor = '#' + redHex + greenHex + blueHex;

      return hexColor.toUpperCase(); // Convert to uppercase for consistency
    }
  }
  static rgbaToHex = (color?: Color) => {
    if (color?.r && color?.g && color?.b && color?.a) {
      let { r, g, b, a } = color;
      // Convert each RGB component to hexadecimal
      var redHex = r.toString(16).padStart(2, '0');
      var greenHex = g.toString(16).padStart(2, '0');
      var blueHex = b.toString(16).padStart(2, '0');
      let alphaHex = Math.round(a * 255)
        .toString(16)
        .padStart(2, '0');
      // Concatenate the hex values
      let hexColor = `#${redHex}${greenHex}${blueHex}${alphaHex}`;
      return hexColor.toUpperCase(); // Convert to uppercase for consistency
    }
  };
  static getCustomStyle(
    type: string,
    chatInterface: ChatInterfaceType | undefined,
  ): any {
    let style = {};
    switch (type) {
      case 'agentMessage':
        if (chatInterface?.agentMessage) {
          const { borderRadius, padding, font, spacing } =
            chatInterface?.agentMessage;
          style = {
            backgroundColor: this.rgbToHex(chatInterface?.botMessageColor),
            color: this.rgbToHex(chatInterface?.botMessageTextColor),
            borderRadius: `${borderRadius?.topLeft}px ${borderRadius?.topRight}px ${borderRadius?.bottomRight}px ${borderRadius.bottomLeft}px`,
            padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            fontSize: `${font?.size}px`,
            spacing: spacing,
          };
          return style;
        }

        break;

      case 'userMessage':
        if (chatInterface?.userMessage) {
          const { borderRadius, spacing } = chatInterface?.userMessage;
          //@ts-ignore
          const { padding } = chatInterface?.agentMessage;
          style = {
            backgroundColor: this.rgbToHex(chatInterface?.userMessageColor),
            color: this.rgbToHex(chatInterface?.userMessageTextColor),
            borderRadius: `${borderRadius?.topLeft}px ${borderRadius?.topRight}px ${borderRadius?.bottomRight}px ${borderRadius.bottomLeft}px`,
            padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            fontSize: `${chatInterface.agentMessage?.font?.size}px`,
            spacing: spacing,
          };

          return style;
        }

        break;

      case 'chatWindowContainer':
        if (chatInterface?.chatWindow) {
          const { borderRadius } = chatInterface.chatWindow;

          style = {
            borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
          };
        }
        return style;
      case 'chatWindow':
        if (chatInterface?.chatWindow) {
          const { padding } = chatInterface.chatWindow;

          style = {
            padding: `${padding?.top}px ${padding?.right}px 0px ${padding?.left}px`,
            background: this.rgbToHex(chatInterface?.chatWindowBgColor),
          };
        }
        return style;
      case 'chatInputContainer':
        if (chatInterface?.chatWindow) {
          const { padding, borderRadius } = chatInterface.chatWindow;
          style = {
            padding: `0px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            background: this.rgbToHex(chatInterface?.chatWindowBgColor),
          };
        }
        return style;
      case 'chatInput':
        if (chatInterface?.chatInput) {
          const { borderRadius, border, font, padding } =
            chatInterface.chatInput;
          style = {
            color: this.rgbToHex(chatInterface?.chatInputTextColor),
            border: `${border?.size}px ${border?.state} ${this.rgbToHex(
              border?.color,
            )}`,
            borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
            // border: '1px solid',
            padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            fontSize: `${font?.size}px`,
          };
        }
        return style;
      case 'headerFontStyle':
        style = {
          fontSize: `${chatInterface?.headerStyle?.font.size}px`,
        };
        return style;

      default:
        return style;
    }
  }
}

export default WidgetHelper;
