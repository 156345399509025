import { Avatar, Box, ClickAwayListener, Fade, Popper } from "@material-ui/core";
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { FunctionComponent, useRef, useState } from "react";
import Header from "./Header";
import WidgetHelper from "./Helper";
import ChatWindow, { ChatWindowProps } from "./ChatWindow";
import ChatInput, { ChatInputProps } from "./ChatInput";
import { WidgetProps } from "./InlineWidget";
import ConfirmationModal from "./ConfirmationModal";
import { ChatInterfaceType } from "Redux/Reducers/aiChatbot.reducer";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

interface Props extends WidgetProps {
    onToggle?: Function;
    styles: any;
    chatInterface: ChatInterfaceType;
    chatWindow: ChatWindowProps;
    chatInput: ChatInputProps;
    headerComponent?: boolean;
    onChatEndConfirmClick?: Function;
    onChatEndCancelClick?: Function;
}
const FloatingWidget: FunctionComponent<Props> = (props) => {
    const anchorRefChatWidget = useRef(null);
    const [openChatWidget, setOpenChatWidget] = useState(false);
    const [showConfirmationAlert, setShowConfirmationAlert] = useState<boolean>(false);

    const handleChatWidgetToggle = () => {
        if (props.onToggle) {
            props.onToggle(openChatWidget);
        }
        setOpenChatWidget((prevOpen) => !prevOpen);
    };

    const ChatWidgetHandleClose = (event: any) => {
        setOpenChatWidget(false);
    };

    const renderWidget = () => {
        return (
            <Box
                className={`ai-chat-widget ${props.chatInterface?.theme === 'dark' ? 'dark' : ''
                    }`}
            >
                <Box
                    className="chat-widget-container"
                    sx={WidgetHelper.getCustomStyle('chatWindowContainer', props.chatInterface)}
                >
                    {props.headerComponent ? props.headerComponent : <Header chatInterface={props.chatInterface} />}

                    <ChatWindow ref={props.chatWindowRef}  {...props.chatWindow} />

                    <ChatInput {...props.chatInput} chatWindowRef={props.chatWindowRef} onChatLeaveClick={() => {
                        setShowConfirmationAlert(true);
                    }} />
                    {/* @ts-ignore */}
                    {showConfirmationAlert && props.chatInterface?.comfirmationStyle &&
                        <ConfirmationModal
                            comfirmationStyle={props.chatInterface?.comfirmationStyle}
                            onConfirm={() => {
                                setShowConfirmationAlert(false);
                                props.onChatEndConfirmClick && props.onChatEndConfirmClick();
                            }}
                            onCancel={() => {
                                setShowConfirmationAlert(false);
                                props.onChatEndCancelClick && props.onChatEndCancelClick();
                            }}
                        />}

                </Box>
            </Box>
        );
    };
    return <Box
        className={`floating-chat-widget ${props.chatInterface?.bubbleButtonAlignment === 'left' ? 'left' : ''
            }`}
    >
        <Avatar
            className="chat-icon"
            style={props.styles.chatAvatar}
            ref={anchorRefChatWidget}
            aria-controls={openChatWidget ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleChatWidgetToggle}
        >
            {
                // openChatWidget ? (
                //   <CloseIcon />
                // ) :
                props.chatInterface?.chatIcon?.keyInS3 ? (
                    <img
                        alt=""
                        src={IMAGE_URL + '/' + props.chatInterface?.chatIcon?.keyInS3}
                        width="35px"
                    />
                ) : (
                    <ChatBubbleIcon />
                )
            }
        </Avatar>

        <Popper
            open={openChatWidget}
            className="chat-popup"
            style={{ zIndex: 999 }}
            // placement="top-end"
            placement={`${props.chatInterface?.bubbleButtonAlignment === 'left'
                ? 'top-start'
                : 'top-end'
                }`}
            transition
            anchorEl={anchorRefChatWidget.current}
            role={undefined}
            disablePortal={false}
            modifiers={{
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'viewport',
                },
            }}
        >
            {({ TransitionProps, placement }) => (
                <Fade {...TransitionProps} timeout={250}>
                    <Box
                        sx={{
                            marginBottom: '10px',
                        }}
                    >
                        <ClickAwayListener onClickAway={ChatWidgetHandleClose}>
                            <Box className="floating-chat-window">{renderWidget()}</Box>
                        </ClickAwayListener>
                    </Box>
                </Fade>
            )}
        </Popper>
    </Box>;
};

export default FloatingWidget;
