import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { createNotification } from 'helpers';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import { uploadAiChatSettingsFiles } from 'Redux/API';
import { ChatBot, Color, ProfilePic, Settings, settingsInitialState } from 'Redux/Reducers/aiChatbot.reducer';

import ColorTilePicker from '../../../../../../Components/ColorPicker/ColorTilePicker';
import CustomSwitch from '../../../../../../Components/customSwitch';
import { TabType, TempratureType } from '../../SettingsDashboard';
import ChatPreview from './ChatPreview';
import { ChatInputStyle } from './Sections/ChatInputStyle';
import ChatWindowStyle from './Sections/ChatWindowStyle';
import { MessageCustomStyle, MessageRadius } from './Sections/MessageStyle';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
};

type FileForType = 'profilePic' | 'chatIcon';

type ChatInterfaceProps = {
  state: Settings;
  agentId?: string;
  handleChange: (
    tab: 'model' | 'opportunity' | 'chatInterface' | 'security',
    toChange: string,
    newVal: string | TempratureType | ProfilePic | Color,
  ) => void;
  handleSwitchChange: (option: string, value: boolean, tab: TabType) => void;
};

export default function ChatInterface({
  state,
  agentId,
  handleChange,
  handleSwitchChange,
}: ChatInterfaceProps) {
  const { chatInterface } = state;
  const dispatch = useDispatch();
  const chatBot = useSelector(
    (store: any) => store.AIChatbot.chatBot,
  ) as ChatBot;
  const chatIconRef = useRef<HTMLInputElement>(null);
  const profilePicRef = useRef<HTMLInputElement>(null);
  const [embbedCode, setEmbbedCode] = useState<string>('');
  useEffect(() => {
    // const fullHostname = window.location.host;
    // const hostnameParts = fullHostname.split('.');
    // console.log('Domain:', hostnameParts);
    // let domain = hostnameParts[0];
    // if (hostnameParts.length >= 2) {
    //   // Extract the domain by taking the last two parts of the array
    //   domain =
    //     hostnameParts[hostnameParts.length - 2] +
    //     '.' +
    //     hostnameParts[hostnameParts.length - 1];
    // }
    // const url = domain + '/service/chat-widget/agentId=' + agentId;
    const url = `${process.env.REACT_APP_URL || 'https://web.development.illumeetxp.com'
      }/widget`;
    let code = `<iframe
    style="width: 370px; height: -webkit-fill-available; position: absolute; right: 0px; bottom: 10px; border: 0;"
    type="text/html"
    srcdoc='
      <html>
        <head>
         <link rel="stylesheet" href="${url}/widget.css" />
        </head>
        <body>
          <div id="illumeet-widget"></div>
          <script>
          window.config = {
            agentId: "${agentId}"
          };
          </script>
          <script src="${url}/widget.js"></script>
        </body>
      </html>'
    width="370"
    noscroll
    allowtransparency="true"
    frameborder="0"
  ></iframe>`;

    setEmbbedCode(code);
  }, [agentId]);
  if (!chatInterface) {
    return <></>;
  }
  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>,
    fileFor: FileForType,
  ) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('aiChatbotFile', file);
      formData.append('fileName', file.name);
      formData.append('fileFor', fileFor);
      try {
        const resFromUploadedFiles: any = await uploadAiChatSettingsFiles(
          formData,
        );
        if (resFromUploadedFiles) {
          const newFile: ProfilePic = {
            keyInS3: resFromUploadedFiles.data.fileInfo.fileInS3,
            name: resFromUploadedFiles.data.fileInfo.name,
          };
          handleChange('chatInterface', fileFor, newFile);
        }
      } catch (error) {
        createNotification(
          'error',
          'Something went wrong while uploading the image',
        );
      }
    }
  };

  const uploadChatIcon = () => {
    chatIconRef.current?.click();
  };
  const uploadProfilePic = () => {
    profilePicRef.current?.click();
  };

  const onWidgetReset = () => {
    let styleSettings = chatBot.settings.chatInterface;
    const defaultInterface = settingsInitialState.chatInterface;
    if (styleSettings && defaultInterface) {
      defaultInterface.initialMessages = styleSettings.initialMessages;
      defaultInterface.autoShowInitialMessages =
        styleSettings.autoShowInitialMessages;
      defaultInterface.suggestedMessages = styleSettings.suggestedMessages;
      defaultInterface.displayName = styleSettings.displayName;
      defaultInterface.profilePic = styleSettings.profilePic;
      defaultInterface.showProfilePic = styleSettings.showProfilePic;
      defaultInterface.showChatIcon = styleSettings.showChatIcon;
    }

    dispatch(
      Actions.saveSettingsForAiChatbotRequest({
        settings: { ...chatBot.settings, chatInterface: defaultInterface },
        agentId: chatBot._id,
      }),
    );
  };
  const { chatWindow, agentMessage, userMessage, chatInput } = chatInterface;

  return (
    <Box className="tab-panel">
      <Box className="tab-content">
        <Stack
          className="tab-title"
          alignItems={'center'}
          direction={'row'}
          justifyContent={'space-between'}
        >
          <Box>Chat interface</Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onWidgetReset()}
          >
            Reset to default
          </Button>
        </Stack>
        <Grid container spacing={2.5}>
          <Grid item xs={12} lg={7} xl={7}>
            <Box>
              <Box component="div" className="inputField" mb={3}>
                <label>Initial Messages</label>
                <Stack direction={'row'} spacing={1}>
                  <input
                    type="text"
                    className="form-control small-text"
                    placeholder="Hi! What can I help you with?"
                    value={
                      chatInterface.initialMessages?.length
                        ? chatInterface.initialMessages[0]
                        : ''
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChange(
                        'chatInterface',
                        'initialMessages',
                        e.target.value,
                      )
                    }
                  />
                </Stack>
              </Box>

              <Box component="div" className="inputField" mb={3}>
                <label>Display Name</label>
                <input
                  type="text"
                  className="form-control small-text"
                  placeholder="Enter name"
                  value={chatInterface.displayName}
                  onChange={(e) =>
                    handleChange('chatInterface', 'displayName', e.target.value)
                  }
                />
              </Box>

              <Box component="div" className="inputField" mb={3}>
                <label>Display Name Size</label>
                <input
                  type="number"
                  className="form-control small-text"
                  placeholder="Enter size"
                  value={chatInterface?.headerStyle?.font?.size}
                  onChange={(e) =>
                    dispatch(
                      Actions.updateChatbot({
                        ...chatBot,
                        settings: {
                          ...chatBot.settings,
                          chatInterface: {
                            ...chatBot.settings.chatInterface,
                            headerStyle: {
                              ...chatBot.settings.chatInterface?.headerStyle,
                              font: {
                                ...chatBot.settings.chatInterface?.headerStyle
                                  ?.font,
                                size: Number(e.target.value || 0),
                              },
                            },
                          },
                        },
                      }),
                    )
                  }
                />
              </Box>

              <Box component="div" className="inputField" mb={3}>
                <label>Align Chat Bubble Button</label>

                <FormControl fullWidth size="small">
                  <Select
                    name="selectType"
                    className="form-control select small-text"
                    style={{
                      padding: '0px',
                      marginBottom: '8px',
                    }}
                    MenuProps={MenuProps}
                    value={chatInterface.bubbleButtonAlignment}
                    defaultValue={chatInterface.bubbleButtonAlignment}
                    onChange={(event: SelectChangeEvent<'right' | 'left'>) =>
                      handleChange(
                        'chatInterface',
                        'bubbleButtonAlignment',
                        event.target.value,
                      )
                    }
                  >
                    <MenuItem value="right" selected>
                      Right
                    </MenuItem>

                    <MenuItem value="left">Left</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Grid
                container
                spacing={2}
                alignItems={'start'}
                justifyContent={'space-between'}
              >
                <Grid item xs={6}>
                  <Box component="div" className="inputField" mb={3}>
                    <Stack
                      component={'label'}
                      spacing={1}
                      direction={'row'}
                      alignItems={'center'}
                      className="paragraph"
                    >
                      <Box>Header Logo</Box>
                      <Box
                        sx={{
                          marginTop: '-12px !important',
                          marginBottom: '-12px !important',
                        }}
                      >
                        <CustomSwitch
                          color={'primary'}
                          name="showProfilePic"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleSwitchChange(
                              'showProfilePic',
                              e.target.checked,
                              'chatInterface',
                            )
                          }
                          checked={chatInterface.showProfilePic}
                        />
                      </Box>
                    </Stack>

                    {/* <Grid item>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                      <Box className="paragraph">Remove</Box>

                      <CustomSwitch
                        color={'primary'}
                        name="showProfilePic"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleSwitchChange(
                            'showProfilePic',
                            e.target.checked,
                            'chatInterface',
                          )
                        }
                        checked={chatInterface.showProfilePic}
                      />

                      <Box className="paragraph">Add</Box>
                    </Stack>
                  </Grid> */}

                    {chatInterface.showProfilePic && (
                      <input
                        type="file"
                        name="profilePic"
                        onChange={(e) => handleFileChange(e, 'profilePic')}
                        hidden
                        ref={profilePicRef}
                        accept="image/jpeg, image/png, image/jpg"
                      />
                    )}

                    {chatInterface.showProfilePic && (
                      <>
                        <Box mb={2} sx={{ wordBreak: 'break-all' }}>
                          {chatInterface.profilePic.name
                            ? chatInterface.profilePic.name
                            : 'Click upload to change default logo'}
                        </Box>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={1}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={uploadProfilePic}
                          >
                            {chatInterface.profilePic.name
                              ? 'Change Logo'
                              : 'Upload Logo'}
                          </Button>
                          {chatInterface.profilePic?.keyInS3 && (
                            <Button
                              onClick={(e) =>
                                handleChange('chatInterface', 'profilePic', {
                                  name: '',
                                  keyInS3: '',
                                })
                              }
                              variant="outlined"
                              color="secondary"
                            >
                              <DeleteOutlineIcon />
                            </Button>
                          )}
                        </Stack>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box component="div" className="inputField" mb={3}>
                    <Grid
                      container
                      spacing={4}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Grid item>
                        <Box className="paragraph">
                          <label>Chat Bubble Icon</label>
                        </Box>
                      </Grid>
                    </Grid>

                    <input
                      type="file"
                      name="chatIcon"
                      onChange={(e) => handleFileChange(e, 'chatIcon')}
                      ref={chatIconRef}
                      hidden
                      accept="image/jpeg, image/png, image/jpg"
                    />

                    <>
                      <Box mb={2} sx={{ wordBreak: 'break-all' }}>
                        {chatInterface.chatIcon.name
                          ? chatInterface.chatIcon.name
                          : 'Click upload to change default Icon'}
                      </Box>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={1}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={uploadChatIcon}
                        >
                          {chatInterface.chatIcon.name
                            ? 'Change Icon'
                            : 'Upload Icon'}
                        </Button>
                        {chatInterface.chatIcon?.keyInS3 && (
                          <Button
                            onClick={(e) =>
                              handleChange('chatInterface', 'chatIcon', {
                                name: '',
                                keyInS3: '',
                              })
                            }
                            variant="outlined"
                            color="secondary"
                          >
                            <DeleteOutlineIcon />
                          </Button>
                        )}
                      </Stack>
                    </>
                  </Box>
                </Grid>
              </Grid>

              {/* <Box component="div" className="inputField" mb={3}>
                <label>Suggested Messages</label>
                <input
                  type="text"
                  className="form-control small-text"
                  placeholder="What is example.com?"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange(
                      'chatInterface',
                      'suggestedMessages',
                      e.target.value,
                    )
                  }
                  value={chatInterface.suggestedMessages}
                />
                <Box className="small-text">
                  Enter each message in a new line.
                </Box>
              </Box> */}

              {/*----- Chat theme removed as per requested ------*/}

              {/* <Box component="div" className="inputField" mb={3}>
                <label>Theme</label>

                <FormControl fullWidth size="small">
                  <Select
                    name="selectType"
                    className="form-control select small-text"
                    style={{
                      padding: '0px',
                      marginBottom: '8px',
                    }}
                    MenuProps={MenuProps}
                    value={chatInterface.theme}
                    defaultValue={chatInterface.theme}
                    onChange={(event: SelectChangeEvent<'dark' | 'light'>) =>
                      handleChange('chatInterface', 'theme', event.target.value)
                    }
                  >
                    <MenuItem value="light">Light</MenuItem>
                    <MenuItem value="dark">Dark</MenuItem>
                  </Select>
                </FormControl>
              </Box> */}

              <Box className="inputField">
                <label>Widget Color</label>
              </Box>

              <Grid container spacing={2}>
                <Box component={Grid} item xs={6} className="inputField">
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <ColorTilePicker
                      color={chatInterface.bubbleButtonColor}
                      handleChangeFromParent={(color: Color) =>
                        handleChange(
                          'chatInterface',
                          'bubbleButtonColor',
                          color,
                        )
                      }
                    />
                    <Box>Header & Chat Bubble</Box>
                  </Stack>
                </Box>
                <Box component={Grid} item xs={6}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <ColorTilePicker
                      color={chatInterface.displayNameColor}
                      handleChangeFromParent={(color: Color) =>
                        handleChange('chatInterface', 'displayNameColor', color)
                      }
                    />
                    <Box>Display Name</Box>
                  </Stack>
                </Box>
                <Box component={Grid} item xs={6}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <ColorTilePicker
                      color={chatInterface.chatWindowBgColor}
                      handleChangeFromParent={(color: Color) =>
                        handleChange(
                          'chatInterface',
                          'chatWindowBgColor',
                          color,
                        )
                      }
                    />
                    <Box>Chat Window</Box>
                  </Stack>
                </Box>

                <Box component={Grid} item xs={6}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <ColorTilePicker
                      color={chatInterface.chatInputTextColor}
                      handleChangeFromParent={(color: Color) =>
                        handleChange(
                          'chatInterface',
                          'chatInputTextColor',
                          color,
                        )
                      }
                    />
                    <Box>Chat input</Box>
                  </Stack>
                </Box>

                <Box component={Grid} item xs={6}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <ColorTilePicker
                      color={chatInterface.botMessageColor}
                      handleChangeFromParent={(color: Color) =>
                        handleChange('chatInterface', 'botMessageColor', color)
                      }
                    />
                    <Box>Agent Message</Box>
                  </Stack>
                </Box>

                <Box component={Grid} item xs={6}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <ColorTilePicker
                      color={chatInterface.botMessageTextColor}
                      handleChangeFromParent={(color: Color) =>
                        handleChange(
                          'chatInterface',
                          'botMessageTextColor',
                          color,
                        )
                      }
                    />
                    <Box>Agent Text</Box>
                  </Stack>
                </Box>

                <Box component={Grid} item xs={6}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <ColorTilePicker
                      color={chatInterface.userMessageColor}
                      handleChangeFromParent={(color: Color) =>
                        handleChange('chatInterface', 'userMessageColor', color)
                      }
                    />
                    <Box>User Message</Box>
                  </Stack>
                </Box>

                <Box component={Grid} item xs={6}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <ColorTilePicker
                      color={chatInterface.timeStampTextColor}
                      handleChangeFromParent={(color: Color) =>
                        handleChange('chatInterface', 'timeStampTextColor', color)
                      }
                    />
                    <Box>Time Stamp</Box>
                  </Stack>
                </Box>

                <Box component={Grid} item xs={6}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <ColorTilePicker
                      color={chatInterface.userMessageTextColor}
                      handleChangeFromParent={(color: Color) =>
                        handleChange(
                          'chatInterface',
                          'userMessageTextColor',
                          color,
                        )
                      }
                    />
                    <Box>User Text</Box>
                  </Stack>
                </Box>

                <Box component={Grid} item xs={6}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <ColorTilePicker
                      color={chatInterface.errorMessageTextColor}
                      handleChangeFromParent={(color: Color) =>
                        handleChange(
                          'chatInterface',
                          'errorMessageTextColor',
                          color,
                        )
                      }
                    />
                    <Box>Error Text</Box>
                  </Stack>
                </Box>
              </Grid>
              <ChatWindowStyle chatWindow={chatWindow} />
              <MessageCustomStyle message={agentMessage} />

              <Box component="div" className="inputField" mt={3}>
                <label>Agent Message Radius (px)</label>
              </Box>
              <MessageRadius
                messageType="agentMessage"
                message={agentMessage}
              />

              <Box component="div" className="inputField" mt={3}>
                <label>User Message Radius (px)</label>
              </Box>
              <MessageRadius messageType="userMessage" message={userMessage} />
              {chatInput && (
                <ChatInputStyle
                  chatInput={chatInput}
                  bubbleButtonColor={chatInterface.bubbleButtonColor}
                />
              )}
              <Box component="div" className="inputField" mt={3}>
                <label>Custom Style</label>
                <textarea rows={10}
                  className="form-control small"
                  placeholder="Enter your custom CSS Here"
                  value={chatInterface.customCss}
                  onChange={(e) =>
                    handleChange('chatInterface', 'customCss', e.target.value)
                  }
                  />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={5} xl={5}>
            <Box sx={{ position: 'sticky', top: '12px' }}>
              <ChatPreview chatInterface={chatInterface} />
              {/* {embbedCode} */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
