import { Box } from "@material-ui/core";
import { FunctionComponent, Ref, useState } from "react";
import { ChatInterfaceType } from "Redux/Reducers/aiChatbot.reducer";
import ChatInput, { ChatInputProps } from "./ChatInput";
import ChatWindow, { ChatWindowProps } from "./ChatWindow";
import WidgetHelper from "./Helper";
import Header from "./Header";
import ConfirmationModal from "./ConfirmationModal";
export interface WidgetProps {
    chatInterface: ChatInterfaceType;
    chatWindow: ChatWindowProps;
    chatInput: ChatInputProps;
    headerComponent?: boolean;
    chatWindowRef?: any;
    onChatEndConfirmClick?: Function;
    onChatEndCancelClick?: Function;
}

const InlineChatWidget: FunctionComponent<WidgetProps> = (
    props
) => {
    const [showConfirmationAlert, setShowConfirmationAlert] = useState<boolean>(false);
    return (
        <Box className="ai-chat-widget">
            <Box
                className="chat-widget-container"
                sx={WidgetHelper.getCustomStyle('chatWindowContainer', props.chatInterface)}
            >
                {props.headerComponent ? props.headerComponent : <Header chatInterface={props.chatInterface} />}

                <ChatWindow ref={props.chatWindowRef}  {...props.chatWindow} />

                <ChatInput {...props.chatInput} chatWindowRef={props.chatWindowRef} onChatLeaveClick={() => {
                    setShowConfirmationAlert(true);
                }} />
                {/* @ts-ignore */}
                {showConfirmationAlert && props.chatInterface?.comfirmationStyle &&
                    <ConfirmationModal
                        comfirmationStyle={props.chatInterface?.comfirmationStyle}
                        onConfirm={() => {
                            setShowConfirmationAlert(false);
                            props.onChatEndConfirmClick && props.onChatEndConfirmClick();
                        }}
                        onCancel={() => {
                            setShowConfirmationAlert(false);
                            props.onChatEndCancelClick && props.onChatEndCancelClick();
                        }}
                    />}
            </Box>
        </Box>
    );
};

export default InlineChatWidget;