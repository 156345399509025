import { FunctionComponent, useEffect, useState, useRef } from 'react';
import './widget.css';
import { EventTypes, RoutKeys } from 'constants/enums/EventTypes';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
} from '@material-ui/core';
import { Box, Grid, Stack } from '@mui/material';
import AIChatbotAPI from 'Redux/API/aiChatbotAPI';
import { createNotification } from 'helpers';
import SendIcon from '@material-ui/icons/Send';
import RefreshIcon from '@material-ui/icons/Refresh';
import MessageWindow, { Actions, Message } from './MessageWindow';
import { useParams } from 'react-router';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChatHelpers from 'Components/ChatWidget/helpers';

interface Props {
  agentId: string | undefined;
  enableFileAttachments?: boolean;
}

const ChatWiew: FunctionComponent<Props> = (props) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [agentId, setAgentId] = useState<string | undefined>('');
  const [activeChatId, setActiveChatId] = useState<string>('');
  const [socket, setSocket] = useState<WebSocket>();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const chatScroll = useRef<Actions>(null);

  const params: {
    id?: string;
  } = useParams();

  useEffect(() => {
    const agentId = params?.id;
    setAgentId(agentId);
    if (agentId) {
      new AIChatbotAPI()
        .getLastChat({ chatBotId: agentId })
        .then(async (res) => {
          //  console.log('L A S T CHAT', res);
          if (res?.data?.success) {
            const chat = res.data.conversation;
            if (chat) {
              setActiveChatId(chat?._id);
              const messages = chat?.messages.map((mes: any) => {
                return {
                  _id: mes._id,
                  sender: mes.sender,
                  text: mes.text,
                  prompt: mes.prompt,
                  feedback: mes.feedback,
                };
              });
              await initializeSocketService();
              const newMessages = ChatHelpers.splitMessagesIntoParagrphs(messages);
              setMessages(newMessages);
            }
          }
        });
    }
  }, []);

  const scrollToBottom = () => {
    if (chatScroll?.current) {
      chatScroll.current.scrollToBottom();
    }
  };

  let chunks: any[] = [];
  const onConnect = (c: any) => {
    console.log('Connected ', c);
    setIsConnected(true);
  };
  const onMessage = (event: any) => {
    const data = JSON.parse(event?.data);
    if (data.data.type === 'error') {
      createNotification('error', data.message);
      return;
    }
    if (data.message === "||$End#||") {
      return;
    }
    let ind = data.data.index;
    // if (ind === 0) {
    //   chunks = [];
    // }
    const mesg = { chunk: data.message, index: ind, id: data.data?.messageId };
    //  console.log('M E S G', mesg);
    chunks.push(mesg);
    //  console.log('CHUNKS', chunks);
    const sorted = chunks
      .filter((f) => f.id === data.data?.messageId)
      .sort((pi, ci) => pi.index - ci.index);
    //  console.log('SORTED', sorted);
    const message = sorted.reduce((acc, obj) => acc + obj.chunk, '');
    //  console.log('M S G ', message);
    if (message !== undefined) {
      updateMessageById(data.data?.messageId, message);

      // addResponseMessage(message, data.data?.messageId);
    } else {
      console.log('Incorrect message: ', data);
    }
  };
  const stopSocketService = async () => {
    socket?.removeEventListener('message', onMessage);
    socket?.removeEventListener('open', onConnect);
    if (socket) {
      socket.close();
      setIsConnected(false);
    }
  };
  const initializeSocketService = async () => {
    let webSocketUrl = process.env.REACT_APP_CHATBOT_WEBSOCKET_URL || '';
    webSocketUrl += '?source=chatbot';

    const socket: WebSocket = new WebSocket(webSocketUrl);
    socket.addEventListener('open', onConnect);
    socket.addEventListener('message', onMessage);
    setSocket(socket);
  };
  // const handleNewUserMessage = (newMessage: any) => {
  //   console.log(`New message incoming! ${newMessage}`);
  //   if (socket && socket.readyState === socket.OPEN) {
  //     const data = JSON.stringify({
  //       action: RoutKeys.CHATBOT,
  //       chatbotId: props.agentId,
  //       service: 'chat',
  //       chatId: activeChatId,
  //       message: newMessage,
  //     });
  //     socket.send(data);
  //   } else {
  //     console.error('WebSocket connection not open or available.');
  //     // Handle the scenario when the socket is not open
  //   }
  // };
  let retries = 3;
  async function sendMessage(
    message: any,
    agentId: string,
    activeChatId: string,
  ) {
    console.log('Connection State : ', socket?.readyState);
    if (socket && socket.readyState === socket.OPEN && message) {
      const data = JSON.stringify({
        action: RoutKeys.CHATBOT,
        chatbotId: agentId,
        eventType: EventTypes.MESSAGE,
        service: 'chat',
        chatId: activeChatId,
        message: message,
      });
      socket.send(data);
    } else {
      console.error('Reconnecting...');
      if (retries < 1 && socket?.readyState === socket?.CLOSED) {
        await initializeSocketService();
        setTimeout(() => {
          sendMessage(message, agentId, activeChatId);
          retries = retries - 1;
        }, 5000);
      }
      // Handle the scenario when the socket is not open
    }
  }
  const addMessage = (
    text: string,
    sender: 'user' | 'assistant',
    id: string = '',
  ) => {
    setMessages((prevMessages) => [...prevMessages, { id: id, text, sender }]);

    setTimeout(() => {
      scrollToBottom();
    }, 200);

    if (agentId) {
      sendMessage(text, agentId, activeChatId);
    }
  };
  const updateMessageById = (id: string, newText: string) => {
    // console.log(' MESSAGE ID', id);
    setMessages((prevMessages) => {
      const index = prevMessages.findIndex((m) => m.id === id);
      //   console.log('I N D E X', index);

      setTimeout(() => {
        scrollToBottom();
      }, 200);

      if (index > -1) {
        console.log('Updating Message', prevMessages[index]);
        prevMessages[index].text = newText;
        return [...prevMessages];
      } else {
        // console.log('Adding Message.. ');
        return [
          ...prevMessages,
          {
            id: id,
            text: newText,
            sender: 'assistant',
          },
        ];
      }
    });
  };

  const handleUserMessage = () => {
    //   console.log('LAST USER MESSAGE ID', id);
    try {
      if (
        inputFile &&
        inputFile.current != null &&
        inputFile.current.files?.length
      ) {
        const file = inputFile.current.files[0];
        if (file) {
          console.log('F I L E', file);
          const reader = new FileReader();

          reader.onload = function (event) {
            //@ts-ignore
            const base64file = event.target.result.split(',')[1];
            if (base64file) {
              const json = {
                message: newMessage,
                file: base64file,
              };
              //@ts-ignore
              inputFile.current.value = '';
              console.log('J S O N', json);
              if (agentId) {
                sendMessage(json, agentId, activeChatId);
              }
              //   setNewMessage(json);
            }
          };

          reader.readAsDataURL(file);
        } else {
          console.error('No file selected.');
        }
      }
    } catch (ex) { }
    if (newMessage.length) {
      const id = '_' + Math.random().toString(36).substr(2, 9);
      addMessage(newMessage, 'user', id);
    }

    setNewMessage('');
  };

  const newConversation = async () => {
    const resp = await new AIChatbotAPI().createNewConversation({
      chatBotId: agentId,
    });
    if (resp.data.success) {
      await stopSocketService();
      setActiveChatId(resp.data.conversation._id);
      setMessages([]);

      setIsConnected(false);
      await initializeSocketService();
    }
  };

  const inputFile = useRef<HTMLInputElement>(null);
  const attachFile = () => {
    // `current` points to the mounted file input element
    if (inputFile.current != null) {
      inputFile.current.click();
    }
  };

  return (
    <Box className="ai-chat-widget">
      <Grid container spacing={0.5}>
        <Grid item xs={12} md={12} lg={10} xl={9}>
          {!isConnected && (
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={newConversation}
            >
              Start New Conversation
            </Button>
          )}
          {isConnected && (
            <Box className="chat-widget-preview">
              <Box py={2.25} px={2}>
                <Stack
                  className="tab-title"
                  alignItems={'center'}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Box>Today</Box>
                  <Stack spacing={1} direction={'row'}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      startIcon={<RefreshIcon />}
                    >
                      Refresh
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={newConversation}
                    >
                      Start New Conversation
                    </Button>
                  </Stack>
                </Stack>
              </Box>
              <MessageWindow
                ref={chatScroll}
                messages={messages}
                chatId={activeChatId}
              />
              <Box className="chat-input">
                <FormControl
                  component={'form'}
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleUserMessage();
                  }}
                  fullWidth
                >
                  <InputBase
                    id="standard-adornment-password"
                    type="text"
                    placeholder="Type a message..."
                    value={newMessage}
                    autoComplete="off"
                    onChange={(e) => setNewMessage(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        {props.enableFileAttachments && false ? (
                          <IconButton
                            aria-label="atach-file"
                            size="medium"
                            onClick={attachFile}
                          >
                            <AttachFileIcon />
                            <input
                              type="file"
                              id="file"
                              ref={inputFile}
                              style={{ display: 'none' }}
                            />
                          </IconButton>
                        ) : null}
                        <IconButton
                          aria-label="send"
                          size="medium"
                          type="submit"
                        >
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default ChatWiew;
