import { Box } from "@material-ui/core";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Stack } from "@mui/material";
import moment from "moment";
import { FunctionComponent } from "react";
import { ChatInterfaceType } from "Redux/Reducers/aiChatbot.reducer";
import WidgetHelper from "./Helper";
import { Message } from "./Widget";

interface Props {
    message: Message;
    error?: string;
    chatInterface?: ChatInterfaceType;
}
const ChatMessage: FunctionComponent<Props> = ({ message, error, chatInterface }) => {
    return <Box
        className={`${message.sender === 'user' ? 'client' : 'bot'
            } chat-bubble`}
    >
        <Stack sx={{ width: '100%' }} alignItems={
            message.sender === 'user'
                ? 'end'
                : 'start'
        } >
            <Stack sx={{ width: '100%' }} alignItems={'center'} justifyContent={
                message.sender === 'user'
                    ? 'end'
                    : 'start'
            } direction={'row'} spacing={0.5}>
                {message.sender === 'user' && error &&
                    <ErrorOutlineIcon style={{ color: '#ff0000' }} />
                }
                <Box
                    className="message"
                    style={
                        message.sender === 'user'
                            ? WidgetHelper.getCustomStyle('userMessage', chatInterface)
                            : WidgetHelper.getCustomStyle('agentMessage', chatInterface)
                    }
                >
                    {message.text}

                </Box>
            </Stack>
            <Box className="message-date-time"
                fontSize={`${chatInterface?.agentMessage?.timeFont?.size}px`}
                mt={1}
                color={error && message.sender === 'user' ? WidgetHelper.rgbToHex(chatInterface?.errorMessageTextColor) : WidgetHelper.rgbToHex(chatInterface?.timeStampTextColor)}>
                {error && message.sender === 'user' ? (
                    <span>{error}</span>
                ) : (
                    (() => {
                        const messageDate = moment(message?.timetamp);
                        const currentDate = moment();

                        if (messageDate.isSame(currentDate, 'day')) {
                            return messageDate.format('h:mm A');
                        } else if (messageDate.isSame(currentDate.subtract(1, 'day'), 'day')) {
                            return `Yesterday, ${messageDate.format('h:mm A')}`;
                        } else {
                            return messageDate.format('Do MMM h:mm A');
                        }
                    })()
                )}
            </Box>

        </Stack>

    </Box>;
};
export default ChatMessage;